import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../config/with-apollo';
import { M_EVENT_COMMENT } from '../../graphql/events';
import { AUTH } from '../../utils/common';

const EventCommentForm = (props) => {
  const { form: { getFieldDecorator, validateFields, setFieldsValue, getFieldValue }, client, match } = props;
  const USER = AUTH();
  const ID = match.params.id;
  const [errors, setErrors] = useState({});
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields(async (error, values) => {
      if (error) {
        setErrors(error);
      }
      
      if (!error) {
        try {
          await client.mutate({
            mutation: M_EVENT_COMMENT,
            variables: {
              objects: [
                {
                  comment: values.comment,
                  user_id: USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'],
                  event_id: ID
                }
              ]
            }
          });
        } catch (e) {
          console.log(e.response);
        } finally {
          setFieldsValue({ comment: null });
          setErrors({});
        }
      }
    });
  };
  
  return <Form className={'mb-8'} onSubmit={handleSubmit}>
    <div>
      <p className={'text-red-500 text-xs'}>{errors.comment ? errors.comment.errors[0].message : ''}</p>
      {getFieldDecorator('comment', { rules: [{ required: true, message: '* Required' }] })(
        <Input.TextArea placeholder={'What are your expectations from the event ? Post your queries here for the host to address them at the event / tailor the event accordingly.\n' +
        'After the event, RSVPed members get access to resources shared by the host.'}
          rows={5}
          maxLength={500}
          style={{ borderWidth: '1px', borderColor: !!errors.comment ? '#F56565' : '#CBD5E0' }}
          className={`border h-35 p-3 w-full rounded bg-white custom-focus-hover`}
        />
      )}
      <p className={'font-light'} style={{ fontSize: '0.625rem' }}>{getFieldValue(
        'comment') ? 500 - getFieldValue(
        'comment').length : '500'} characters remaining </p>
    </div>
    <div className={'flex justify-end'}>
      <button type={'submit'}
        className={'bg-primary text-white px-4 py-2 text-sm rounded active:bg-primary focus:outline-none '}>
        Post
      </button>
    </div>
  </Form>;
};

export default compose(Form.create({ name: 'comment-form' }), withApollo, withRouter)(EventCommentForm);
