import moment from 'moment';
import React from 'react';

const UserTokenRow = ({ value, rowKey }) => {
  return (
    <tr className={rowKey % 2 ? 'bg-light-gray-header' : 'bg-white'} key={value.id}>
      <td className={'py-2 md:py-4 pl-4 pr-4 md:pr-8 md:pl-6 whitespace-no-wrap'}>
        {moment(value.created_at).format('D/MM/Y')}
      </td>
      <td className={'py-2 md:py-4 pr-8'}>
        <span>{value.transaction}</span>
      </td>
      <td className={`py-2 md:py-4 ${value.transaction_type === 'CREDIT'
        ? 'text-primary'
        : 'text-red-600'}`}>
        {value.transaction_type === 'CREDIT'
          ? `+${value.quantity}`
          : `-${value.quantity}`
        }
      </td>
      <td className={'py-2 md:py-4 text-right pr-4 md:pr-6'}>{value.by}</td>
    </tr>
  );
};

export default UserTokenRow;
