import React, { useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import amexIcon from '../../../assets/icons/amex-icon.png';
import helpMobileActive from '../../../assets/icons/help-mobile-active.png';
import mastercardIcon from '../../../assets/icons/mastercard-icon.png';
import ticket from '../../../assets/icons/tickets.png';
import visaIcon from '../../../assets/icons/visa-icon.png';
import ModalError from '../../../components/modals/modal-error';
import ModalSuccess from '../../../components/modals/modal-success';
import { PageContext } from '../../../helpers/context';
import ModalErrorDiscount from '../modal-error-discount';
import ModalSuccessDiscount from '../modal-success-discount';
import PaymentForm from './form';
import TopUpValues from './top-up-values';

const TopUpPage = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [topUp, setTopUp] = useState(TopUpValues[1]);
  const [errorDiscountVisible, setErrorDiscountVisible] = useState(false);
  const [discountVisible, setDiscountVisible] = useState(false);
  const [discountCode, setDiscountCode] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetDiscountVisible = () => {
    setDiscountVisible(!discountVisible);
  };

  const handleSetErrorDiscountVisible = () => {
    setErrorDiscountVisible(!errorDiscountVisible)
  };

  const handleSetDiscountCode = (e) => {
    setDiscountCode(e.target.value);
  };

  const redirectToPage = () => {
    const url = localStorage.getItem('@return-url');
    if (url) {
      localStorage.removeItem('@return-url');
      return props.history.replace(url);
    }

    if (props.location.state && props.location.state.return_url) {
      return props.history.replace(props.location.state.return_url);
    }

    return props.history.push('/dashboard');
  };

  const handleSelectTopUp = (value) => () => {
    setTopUp(value);
  };

  const handleGoToPage = (page) => () => {
    props.history.push(page);
    window.scrollTo(0, 0);
  };

  return (
    <PageContext.Provider value={{
      showSuccessModal, setShowSuccessModal, showErrorModal, setShowErrorModal, topUp, discountVisible,
      discountCode,
      handleSetErrorDiscountVisible,
      errorDiscountVisible,
      handleSetDiscountVisible,
      handleSetDiscountCode
    }}>
      <div className={'min-h-screen bg-light-gray pb-10'}>
        <div className={'container mx-auto px-4 md:px-16 pt-10'}>
          {!!props.location.state && <div className={'bg-light-gray-header px-4 pt-4 pb-0 mb-6 border rounded-lg'}>
            <p className={'text-lg font-grumpy text-red-500'}>Warning - Low Balance !</p>
            <p className={'text-black mb-3'}>
              {props.location.state.lackAmount ? `Choose the Recommended Tokens below to top up with enough to purchase this item` : `You have less than ${props.location.state.amount} tokens in your account.`}
            </p>
          </div>}
          {/*{user_plan !== USER_BASIC && (*/}
          {/*  <div className={'mb-10'}>*/}
          {/*    <PaymentBanner redirect={()=>{props.history.push('/upgrade-account')}}/>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className={'mb-10'}>
            <div className={'mb-5 flex flex-col items-start w-full'}>
              <div className={'w-full bg-light-gray-header px-4 py-4 mb-12 border rounded-lg flex md:flex-row flex-col justify-between'}>
                <div className={'flex flex-col md:mb-0 mb-4 w-full'}>
                  <p className={'text-lg font-grumpy text-primary'}>Top Up Token Balance</p>
                  <p className={'text-black mb-3'}>
                    Unused tokens rollover to the paid subscription on upgrade (tokens can not be converted to cash).
                  </p>
                  {/* {user_plan === USER_TRIAL && <div className="w-full flex flex-col items-center">
                    <p className="my-3">Continue to purchase tokens on your Trial Plan</p>
                    <p className="font-bold">OR</p>
                    <button onClick={handleGoToPage('/upgrade-account')}
                      className={'bg-primary text-white rounded my-3 px-3 pb-2 pt-2 font-medium flex justify-center items-center'}>
                      Upgrade
                    </button>
                  </div>} */}
                </div>
              </div>
              <div className={'flex md:flex-row flex-col w-full'}>
                {props && props.location && props.location.state && props.location.state.lackAmount && <div style={{ flex: 1 }} className={`flex rounded-lg flex-1 py-3 text-base justify-between px-4  md:mr-10 my-3 md:my-0 cursor-pointer
                  ${topUp && topUp.stripe_sku === 'lack_mode'
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                 `}
                  onClick={handleSelectTopUp({ stripe_sku: 'lack_mode', token: props.location.state.lackAmount, price: Math.round(props.location.state.lackAmount * 1.33) })}
                >
                  <div className={'items-center font-bold flex relative'}>
                    <div className="ribbon-payment font-normal">Recommended</div>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{props.location.state.lackAmount} Tokens
                  </div>
                  <div className={'flex self-center'}>${Math.round(props.location.state.lackAmount * 1.33)}</div>
                </div>}
                <div className={`flex rounded-lg flex-1 py-3 text-base justify-between px-4 ${props && props.location && props.location.state && props.location.state.lackAmount ? 'md:mx-10' : 'md:mr-10'} my-3 md:my-0 cursor-pointer
                  ${topUp && topUp.stripe_sku === TopUpValues[0].stripe_sku
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                 `}
                  onClick={handleSelectTopUp(TopUpValues[0])}
                >
                  <div className={'items-center font-bold flex'}>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{TopUpValues[0].token} Tokens
                  </div>
                  <div className={'flex self-center'}>${TopUpValues[0].price}</div>
                </div>
                <div className={`flex rounded-lg flex-1 py-3 text-base justify-between px-4 md:mx-10 my-3 md:my-0 cursor-pointer
                    ${topUp && topUp.stripe_sku === TopUpValues[1].stripe_sku
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                `}
                  onClick={handleSelectTopUp(TopUpValues[1])}
                >
                  <div className={'items-center font-bold flex relative'}>
                    <div className="ribbon-payment font-normal">Most Popular</div>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{TopUpValues[1].token} Tokens
                  </div>
                  <div className={'flex self-center'}>${TopUpValues[1].price}</div>
                </div>
                <div className={`flex rounded-lg flex-1 py-3 text-base justify-between px-4 md:ml-10 my-3 md:my-0 cursor-pointer
                  ${topUp && topUp.stripe_sku === TopUpValues[2].stripe_sku
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                 `}
                  onClick={handleSelectTopUp(TopUpValues[2])}
                >
                  <div className={'items-center font-bold flex'}>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{TopUpValues[2].token} Tokens
                  </div>
                  <div className={'flex self-center'}>${TopUpValues[2].price}</div>
                </div>
              </div>
              <div className={'flex md:flex-row flex-col w-full my-0 md:my-4'}>
                <div className={`hidden flex-1 md:ml-10 md:flex`} />
                <div style={{ flex: 2 }} className={`flex rounded-lg flex-2 py-3 text-base justify-between px-4 md:mr-10 my-3 md:my-0 cursor-pointer
                  ${topUp && topUp.stripe_sku === TopUpValues[3].stripe_sku
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                 `}
                  onClick={handleSelectTopUp(TopUpValues[3])}
                >
                  <div className={'items-center font-bold flex'}>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{TopUpValues[3].token} Tokens
                  </div>
                  <div className={'flex self-center'}>${TopUpValues[3].price}</div>
                </div>
                <div style={{ flex: 2 }} className={`flex rounded-lg flex-2 py-3 text-base justify-between px-4 md:mr-10 my-3 md:my-0 cursor-pointer
                  ${topUp && topUp.stripe_sku === TopUpValues[4].stripe_sku
                    ? 'bg-event-going text-white shadow-lg'
                    : 'bg-white text-black'}
                 `}
                  onClick={handleSelectTopUp(TopUpValues[4])}
                >
                  <div className={'items-center font-bold flex'}>
                    <img src={ticket} alt="" className={'w-auto h-6 mr-4'} />{TopUpValues[4].token} Tokens
                  </div>
                  <div className={'flex self-center'}>${TopUpValues[4].price}</div>
                </div>

                <div className={`hidden flex-1 md:ml-10 md:flex`} />
              </div>
            </div>
          </div>
          <div>
            <div className={'mb-5 flex md:flex-row flex-col md:justify-between items-start'}>
              <p className={'font-grumpy text-2xl text-black mb-2 md:mb-0'}>Payment Details</p>
              <div className={'flex flex-row md:self-center'}>
                <p className={'text-base mr-2'}>We Accept: </p>
                <div className={'flex flex-row self-center'}>
                  <img src={visaIcon} alt="" className={'mr-2 w-8 h-5'} />
                  <img src={mastercardIcon} alt="" className={'w-7 h-5 mr-2'} />
                  <img src={amexIcon} alt="" className={'w-8 h-5'} />
                </div>
              </div>
            </div>
            <div className={'md:bg-white md:rounded-lg'}>
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
                <Elements>
                  <>
                    <PaymentForm />
                    <ModalSuccess title={'Payment successful'}
                      subtitle={`${topUp.token} tokens added to your account. More tokens, more benefits!`}
                      redirect={redirectToPage}
                    />
                    <ModalError title={'Payment unsuccessful'}
                      subtitle={<div className={'flex'}>
                        <div className={'mr-1'}>Please try again or contact support {'  '}</div>
                        <img alt={''}
                          src={helpMobileActive}
                          className={'h-7 w-auto'} />
                      </div>}
                    />
                    <ModalSuccessDiscount />
                    <ModalErrorDiscount />
                  </>
                </Elements>
              </StripeProvider>
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default compose(withRouter)(TopUpPage);
