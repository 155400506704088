import { useLazyQuery } from '@apollo/react-hooks';
import { Card, Rate } from 'antd';
import 'antd/es/icon/style/css';
import 'antd/es/rate/style/css';
import 'antd/es/tooltip/style/css';
import _, { startCase } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import downloadIcon from '../../assets/icons/download-icon.png';
import ticketIcon from '../../assets/icons/tickets.png';
import userMobile from '../../assets/icons/user-mobile.png';
import withApollo from '../../config/with-apollo';
import { Q_DIGITAL_LIBRARY_GET_PDF_LINK } from '../../graphql/digital-libraries';
import { PageContext } from '../../helpers/context';
import { baseAxios } from '../../utils/api-handler';

const ExpertCard = (props) => {
  const ID = props.match.params.id;
  const expert = props.data;
  const [loadPdfLink] = useLazyQuery(Q_DIGITAL_LIBRARY_GET_PDF_LINK, {
    client: props.client,
    variables: { id: expert.id },
  });

  const [pdfLink, setPdfLink] = useState(null);

  useEffect(() => {
    if (props.hasTransaction && ID) {
      loadPdfLink();
    }
  }, [props.hasTransaction, ID]);

  const [resourceDetails, setResourceDetails] = useState({
    thumbnail: null,
    duration: '0',
  });
  const { setHeightCards } = useContext(PageContext);

  const cardRef = useRef(null);

  useEffect(() => {
    getCardSize();

    window.addEventListener('resize', getCardSize);
  }, [cardRef, props.hasTransaction]);

  const getCardSize = () => {
    if (ID) {
      setHeightCards((oldState) => ({ ...oldState, details: cardRef.current.clientHeight }));
    }
  };

  const handleImageOnLoad = (event) => {
    event.persist();
    if (ID && event && event.target && event.target.clientHeight) {
      setHeightCards((oldState) => ({
        ...oldState,
        details: oldState.details + (event.target.clientHeight || 0) - 21,
      }));
    }
  };

  useEffect(() => {
    if (expert.video_url) {
      baseAxios(
        'GET',
        `${process.env.REACT_APP_VIMEO_API_URL}/videos/${expert.video_url}`,
        {},
        {},
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          },
        },
      ).then((res) => {
        setResourceDetails({
          thumbnail: res.data.pictures.sizes[res.data.pictures.sizes.length - 1].link_with_play_button,
          duration: Math.ceil(res.data.duration / 60),
        });
      });
    }
  }, []);

  const ExpertFooter = () => {
    if (!props.hasTransaction) {
      if (ID) {
        return <></>;
      }
      return (
        <a
          onClick={() => !ID && props.history.push(`/get-advice/${expert.id}`)}
          className={
            'h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white'
          }
        >
          See Availability
        </a>
      );
    } else {
      if (!ID) {
        return (
          <a
            onClick={() => {
              !ID && props.history.push(`/digital-library/${expert.id}`);
            }}
            className={
              'h-10 w-full rounded-b-lg bg-event-going text-white text-lg flex justify-center items-center mt-6 hover:text-white'
            }
          >
            Open
          </a>
        );
      }

      return (
        <div
          className={
            'h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white'
          }
        >
          Write review
        </div>
      );
    }
  };

  const forceHalfRating = (rate) => {
    let stringRate = String(rate);
    let parseRating = stringRate.split('.');
    let result;

    if (Number(parseRating[1]) >= 5) {
      result = 5;
    } else {
      result = 0;
    }

    return Number(`${parseRating[0]}.${result}`);
  };

  const cardCover = () => {
    if (!ID) {
      if (!expert.video_url) {
        return (
          <div
            className={`w-full ${!ID && 'xl:h-52 lg:h-63.5 md:h-84 h-46'} bg-contain bg-no-repeat bg-center`}
            style={{ backgroundImage: `url(${expert.image_cover})` }}
          />
        );
      }
      return (
        <iframe
          className={`w-full xl:h-52 lg:h-63.5 md:h-84 h-46 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${expert.video_url}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    } else {
      if (!expert.video_url) {
        return (
          <div
            className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-contain bg-no-repeat bg-center`}
            style={{ backgroundImage: `url(${expert.image_cover})` }}
          />
        );
      }

      return (
        <iframe
          className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${expert.video_url}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    }
  };

  const TagList = () => {
    if (expert.expert_listing_business_goals_tags.length) {
      if (ID) {
        return expert.expert_listing_business_goals_tags.map((value, key) => {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 8px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-black flex mr-2 mb-2'}>{value.business_goals_tag.name}</span>;
        });
      }
      return _.takeRight(expert.expert_listing_business_goals_tags, 4).map((value, key) => {
        if (key >= 3) {
          return <span className={'px-1 text-xs text-black font-medium italic flex mr-2 mb-2'}>and more...</span>;
        } else {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 8px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-black flex mr-2 mb-2'}>{value.business_goals_tag.name}</span>;
        }
      });
    } else {
      return <div className={'rounded px-1 text-xs text-black bg-gray-300 flex mr-2 mb-2'}>N/A</div>;
    }
  };

  return (
    <div className={'relative'} ref={cardRef}>
      {expert.is_featured && <div className="ribbon">Featured</div>}
      <Card
        className={`bg-white h-full rounded-lg  overflow-hidden shadow-md ${props.card && props.card.className}`}
        cover={cardCover()}
      >
        <div className={'px-4'}>
          <p
            onClick={() => {
              return !ID && props.history.push(`/get-advice/${expert.slug || expert.id}`);
            }}
            className={`pt-4 pb-4 font-bold font-grumpy text-xl text-black ${!ID &&
              'cursor-pointer hover:text-primary truncate-2'}`}
          >
            {expert.name}
          </p>
          <div className={`mb-4 mt-2 flex justify-center`}>
            <div className={'pr-6 flex self-center w-16 h-14'} style={{ flex: ' 0 0 5rem' }}>
              <img src={expert.logo || userMobile} className={'w-16 h-auto rounded-full'} alt={''} />
            </div>
            <div className={'flex flex-col w-full justify-center'}>
              <p className={'text-black font-medium text-base'}>{expert.position}</p>
              <p className={'text-sm'}>{expert.company}</p>
            </div>
          </div>
          <div className={ID && 'flex flex-col'}>
            <div className={`flex mb-2 flex-col`}>
              <div>
                <p className={`font-medium text-black block pr-2 mb-2 ${ID && 'flex'}`}>Requires:</p>
              </div>
              <div className={'flex flex-col w-3/4'}>
                <div style={{ width: 'fit-content' }} className={'rounded px-1 text-xs flex text-black bg-gray-300 mb-1'}>{expert.subscription_type ? startCase(expert.subscription_type.toLowerCase()) : 'N/A'}</div>
                {expert.subscription_type === "COMMUNITY" ? 
                  <>
                    <div style={{ width: 'fit-content' }} className={'rounded px-1 text-xs flex text-black bg-gray-300 mb-1'}>Standard</div>
                    <div style={{ width: 'fit-content' }} className={'rounded px-1 text-xs flex text-black bg-gray-300 mb-1'}>Premium</div>
                  </>
                  :
                  ''
                }
                {expert.subscription_type === "STANDARD" ? 
                  <>
                    <div style={{ width: 'fit-content' }} className={'rounded px-1 text-xs flex text-black bg-gray-300 mb-1'}>Premium</div>
                  </>
                  :
                  ''
                }
                <div style={{ width: 'fit-content' }} className={'rounded px-1 text-xs flex text-black bg-gray-300 mb-1'}>Pay as You Go</div>
              </div>
            </div>
            <div className={`flex`}>
              <div className={'flex flex-col'}>
                <p className={`font-medium text-black block pr-2 mb-2 ${ID && 'flex'}`}>Expertise in:</p>
                <div className={'truncate-2'}>
                  <div className={`flex flex-row content-start items-start flex-wrap ${props.isDetails ? 'h-auto' : 'h-20'}`}>
                    <TagList />
                  </div>
                </div>
              </div>
            </div>
            <div className={`mt-4 mb-6 flex justify-between`}>
              <div className={ID && 'hidden'}>
                <div className={'w-1/8 pt-1.5'} />
                <div className={'w-full flex justify-between justify-center items-center text-black text-base'}>
                  {expert.cost ? (
                    <div className={'flex flex-row items-center'}>
                      <img src={ticketIcon} className={'h-10.5 pr-4'} alt={''} />
                      {expert.cost}
                    </div>
                  ) : (
                    <div className={'border rounded p-2'}>FREE</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col md:flex-row ${expert.expert_listing_reviews_aggregate.aggregate.avg.rating ? 'mb-6' : ID ? 'mb-6' : 'mb-13.5'
              }`}
          >
            <div className={`${ID && 'md:w-1/2 w-full'} mb-5 md:mb-0`}>
              <div className={`flex ${!expert.expert_listing_reviews_aggregate.aggregate.avg.rating && 'hidden'}`}>
                <Rate
                  allowHalf
                  value={
                    expert.expert_listing_reviews_aggregate.aggregate.avg.rating
                      ? forceHalfRating(expert.expert_listing_reviews_aggregate.aggregate.avg.rating.toFixed(1))
                      : 0
                  }
                  className={'text-primary'}
                  disabled
                />
                <p className={'flex self-center pl-4 text-base text-black font-semibold'}>
                  {expert.expert_listing_reviews_aggregate.aggregate.avg.rating
                    ? expert.expert_listing_reviews_aggregate.aggregate.avg.rating.toFixed(1)
                    : 0}
                </p>
                <p className={'hidden flex self-center pl-1 text-base text-gray-500'}>
                  ({expert.expert_listing_reviews_aggregate.aggregate.count})
                </p>
              </div>
            </div>
            <div className={!ID && 'hidden'}>
              <div className={'w-1/8'} />
              <div className={'w-full flex justify-between justify-center items-center text-black text-base'}>
                {expert.cost ? (
                  <div className={'flex flex-row items-center'}>
                    <img src={ticketIcon} className={'h-10.5 pr-4'} alt={''} />
                    {expert.cost}
                  </div>
                ) : (
                  <div className={'border rounded p-2'}>FREE</div>
                )}
              </div>
            </div>
            {ID && (
              <div className={'hidden flex md:w-1/2 pt-1 w-full items-center'}>
                <div className={'md:w-8 w-6'}>
                </div>
                <div className={'ml-2 w-full hidden'}>
                  {expert.video_url ? (
                    <div className={'flex flex-row w-full'}>
                      <div className={'flex w-full self-center mr-5'}>{`${resourceDetails.duration} min(s)`}</div>
                      {pdfLink && (
                        <div className={'flex flex-row w-full items-center'}>
                          <div className={'md:w-8 w-6 mr-2'}>
                            <img src={downloadIcon} className={'md:w-7 h-auto'} alt={''} />
                          </div>
                          {pdfLink ? (
                            <a
                              target={'_blank'}
                              download={pdfLink}
                              className={
                                'flex self-center w-full text-primary hover:text-primary focus:text-primary font-bold'
                              }
                              href={pdfLink}
                            >{`Download (${expert.pdf_size})`}</a>
                          ) : (
                            <div className={'flex w-full self-center'}>{expert.pdf_size}</div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : pdfLink ? (
                    <a
                      target={'_blank'}
                      download={pdfLink}
                      className={'flex self-center text-primary hover:text-primary focus:text-primary font-bold'}
                      href={pdfLink}
                    >{`Click here to download (${expert.pdf_size})`}</a>
                  ) : (
                    <div className={'flex self-center'}>{expert.pdf_size}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <ExpertFooter />
      </Card>
    </div>
  );
};

export default compose(
  withRouter,
  withApollo,
)(ExpertCard);
