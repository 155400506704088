import { Icon, Modal, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../config/with-apollo';
import { M_USER_PROFILE } from '../../graphql/user';
import { AppContext, PageContext } from '../../helpers/context';
import { destroy, get } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const CancelSubscription = (props) => {
  const { client } = props;
  const { showCancelSubscription, setCancelSubscription, setShowSuccessCancelSubscription } = useContext(PageContext);
  const { user_subscription_id } = useContext(AppContext);
  const USER = AUTH();
  const [submitting, setSubmitting] = useState(false);
  
  const handleSubmit = async () => {
    
    try {
      setSubmitting(true);
      await get(`/unsubscribe/${USER.id}`);
      
      await client.mutate({
        mutation: M_USER_PROFILE,
        variables: {
          update: {
            constraint: 'users_pkey',
            update_columns: ['id', 'opt_in']
          },
          object: {
            id: USER.id,
            username: '',
            name: '',
            password: '',
            opt_in: false
          }
        }
      });
      
      await destroy(`/stripe/subscription/${user_subscription_id}`);
      
      setShowSuccessCancelSubscription(true);
    } catch (e) {
      notification['error']({
        message: 'Cancel Subscription',
        description:
          'Failed to cancel subscription.',
      });
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <Modal visible={showCancelSubscription}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={'flex flex-col '}>
        <p className={'self-center text-2xl text-black text-center'}>Are you sure you want to cancel your subscription
          ?</p>
        <p className={'self-center text-lg mb-5 text-center'}>
          We value your membership and have an exciting roadmap of features to help grow your business. Are you sure you
          want to cancel the membership ?
        </p>
        <div className={'flex flex-row w-full justify-center'}>
          <button type={'submit'}
            disabled={submitting}
            onClick={() => {setCancelSubscription(!showCancelSubscription);}}
            className={'self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none '}>
            No
          </button>
          <button type={'submit'}
            disabled={submitting}
            onClick={handleSubmit}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${submitting
              ? 'bg-disabled'
              : 'bg-primary'}`}>
            {submitting && <Icon type={'loading'} className={'mr-2'}/>}
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default compose(withApollo, withRouter)(CancelSubscription);
