const TopUpValues = [
  {
    token: 15,
    price: 20,
    stripe_sku: process.env.REACT_APP_15
  },
  {
    token: 45,
    price: 60,
    stripe_sku: process.env.REACT_APP_45
  },
  {
    token: 60,
    price: 80,
    stripe_sku: process.env.REACT_APP_60
  },
  {
    token: 75,
    price: 100,
    stripe_sku: process.env.REACT_APP_75
  },
  {
    token: 372,
    price: 495,
    stripe_sku: process.env.REACT_APP_372
  }
];

export default TopUpValues;
