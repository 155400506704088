import gql from 'graphql-tag';

export const S_CONNECTIONS = gql`
  subscription connections($where: connections_bool_exp, $messagesUnseenWhere: messages_bool_exp){
    connections(where: $where){
      id
      connection_id
      created_at
      type
      name
      connections_users {
        id
        user {
          id
          username
          name
          avatar
          address
          about_me
          created_at
          subscription_type
          businesses(where: {order: {_eq: 0}}) {
            industry
            name
            about
            offering
            stage
          }
        }
      }
      messages(limit: 1, order_by: {created_at: desc}){
        id
        message
        seen
        created_at
        from
      }
      messages_unseen: messages_aggregate(where: $messagesUnseenWhere){
        aggregate{
          count
        }
      }
      user_connection{
        id
        name
        avatar
        businesses(order_by: {order:asc}){
          id
          name
        }
      }
      user{
        id
        name
        avatar
        businesses(order_by: {order:asc}){
          id
          name
        }
      }
    }
  }
`;

export const S_MESSAGE = gql`
  subscription messages($where: messages_bool_exp){
    messages(where: $where, order_by: {created_at: asc}){
      id
      message
      seen
      from
      to
      created_at
      user_from {
        avatar
        id
        name
      }
    }
  }
`;

export const M_MESSAGE = gql`
  mutation insert_message($objects: [messages_insert_input!]!){
    insert_messages(objects: $objects){
      returning{
        id
      }
    }
  }
`;

export const M_UPDATE_MESSAGE_SEEN = gql`
  mutation update_message_seend($where: messages_bool_exp!, $set: messages_set_input){
    update_messages(where: $where, _set: $set){
      returning{
        id
      }
    }
  }
`;

export const M_CONNECT_USER = gql`
  mutation connect_user($objects: [connections_insert_input!]!, $messagesUnseenWhere: messages_bool_exp){
    insert_connections(objects: $objects, on_conflict: {constraint: connections_pkey, update_columns: [id]}){
      returning{
        id
        connection_id
        created_at
        type
        name
        connections_users {
          id
          user {
            id
            username
            name
            avatar
          }
        }
        messages(limit: 1, order_by: {created_at: desc}){
          id
          message
          seen
          created_at
          from
        }
        messages_unseen: messages_aggregate(where: $messagesUnseenWhere){
          aggregate{
            count
          }
        }
        user_connection{
          id
          name
          avatar
          businesses(order_by: {order:asc}){
            id
            name
          }
        }
        user{
          id
          name
          avatar
          businesses(order_by: {order:asc}){
            id
            name
          }
        }
      }
    }
  }
`;

export const Q_GET_CONNECTED_USER = gql`
  query get_connected_user($where: connections_bool_exp!){
    connections(where: $where){
      id
      connection_id
      user_id
      messages(limit: 1, order_by: {created_at: desc}){
        id
        message
        seen
        created_at
        from
      }
    }
  }
`;

export const Q_UNREAD_MESSAGES = gql`
  query unread_messages($messagesUnseenWhere: messages_bool_exp){
    messages_aggregate(where: $messagesUnseenWhere){
      aggregate{
        count
      }
    }
  }
`;



export const Q_GET_CONNECTED_USER_BY_STATE = gql`
  query connections($where: connections_bool_exp, $messagesUnseenWhere: messages_bool_exp){
    connections(where: $where){
      id
      connection_id
      created_at
      type
      name
      connections_users {
        id
        user {
          id
          username
          name
          avatar
        }
      }
      messages(limit: 1, order_by: {created_at: desc}){
        id
        message
        seen
        created_at
        from
      }
      messages_unseen: messages_aggregate(where: $messagesUnseenWhere){
        aggregate{
          count
        }
      }
      user_connection{
        id
        name
        avatar
        businesses(order_by: {order:asc}){
          id
          name
        }
      }
      user{
        id
        name
        avatar
        businesses(order_by: {order:asc}){
          id
          name
        }
      }
    }
  }
`;

export const M_CONNECTION_USERS = gql`
  mutation MyMutation($objects: [connections_users_insert_input!]!) {
    insert_connections_users(objects: $objects) {
      affected_rows
    }
  }
`;