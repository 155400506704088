import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../helpers/context';
import verify from '../../assets/images/payment-cover.png';
import { post } from '../../utils/api-handler';
import { RESEND_VERIFICATION_URL } from '../../utils/url-handler';
import { withRouter } from 'react-router-dom';

const Verification = (props) => {
  const { user_email, setShowTitle, user_status } = useContext(AppContext);

  const resendVerification = async () => {
    try {
      const response = await post(RESEND_VERIFICATION_URL, { username: user_email })
      if (response.status === 200) {
        setShowTitle(true);
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if(user_status && user_status !== 'PENDING') {
      return props.history.push('/dashboard')
    }
  }, [user_status])

  return (
    <div className={'min-h-screen bg-white'}>
      <div className='w-full md:w-1/3 my-16 px-16 m-auto h-auto flex flex-col items-center justify-center'>
        <img className={'text-center mt-16'} src={verify} alt={''} />
        <div className='w-full my-6'>
          <p className='text-center text-2xl font-bold'>Just one more step!</p>
          <p className='text-center text-base'>Verify your email address</p>
        </div>
        <div className='w-full mt-4'>
          <p className='text-base text-center'>We already sent a verification link to your email <strong>{user_email}</strong></p>
          <div className={'md:flex flex md:flex-row items-center justify-center flex-col w-full md:w-auto md:h-auto my-4'}>
            <button onClick={resendVerification}
              className={'text-white bg-primary rounded px-2 md:py-2 md:px-8 md:mx-2 md:text-base md:w-auto w-full md:w-1/2                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   h-7 md:h-auto focus:outline-none md:mt-auto mt-1'}>
              Resend Verification
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default withRouter(Verification);