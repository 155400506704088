import React from 'react';
import logo from '../../assets/icons/mums_and_co_logo.png';

const Usage = () => {
  return (

    <div className={'min-h-screen bg-light-gray'}>
      <div className={'w-full flex justify-center mb-10 bg-primary py-5'}>
        <img src={logo} alt="" />
      </div>
      <div className={'container mx-auto pb-10 px-16 text-black text-sm privacy'}>
      <h3 className={'text-4xl font-grumpy font-bold'}><strong>Website Terms of Use</strong></h3>
		<p><strong>Effective as at 29 March 2024</strong><br />
		<br />
&ldquo;This website&rdquo; or &ldquo;our website&rdquo; means all webpages located on the&nbsp;<a href="http://www.mumsandco.com.au/">www.mumsandco.com.au</a>&nbsp;and app.mumsandco.com.au domain names and includes all materials that we make available to you on our website.<br />
<br />
This website is the website of Insurance Australia Limited ACN 000 016 722 trading as &ldquo;Mums &amp; Co&rdquo; or &ldquo;Mums &amp; Company&rdquo;, and its related entities.<br />
<br />
<strong>‍Your access, browsing and use of this website constitutes your agreement to these Terms of Use. These Website Terms of Use include terms which limit and exclude our liability for certain types of loss or damage. This means that in the event of a claim by you against us, your recourse may be limited. Your access and use of this website is governed by these Terms of Use. By accessing and using this website you acknowledge and accept that you are bound by these Terms of Use.</strong><br />
<br />
We may update these Terms of Use on this website from time to time. If we do so, we will notify you in such manner as we consider reasonably appropriate, including by making the revised version available on our website. We will clearly state the date these Terms of Use were last changed. Please review these Terms of Use periodically for changes.<br />
<br />
<strong>Intellectual Property</strong><br />
<br />
All intellectual property rights in and to our website (except for any content posted by members) belong to us and our licensors and are protected by applicable trade mark and copyright laws. Content posted by members belongs to the relevant member.<br /><br />
You may download or print sections of our website if needed for your own personal use, but otherwise must not reproduce any material from our website without our express permission or the express permission of the relevant owner.<br />
<br />
<strong>Content<br />
<br />
&zwj;</strong>We have used reasonable endeavours to ensure that the information contained on our website is accurate and up-to-date at the time of publication. However, we do not warrant the accuracy or completeness of any information contained on or linked to our website.<br />
<br />
&zwj;<em>Contributing Content<br />
<br />
&zwj;</em>If you contribute any content to our website (including advertising your business on our website), you represent and warrant that such content:</p>

<ol className='ml-5' role="list">
    <li>(1)  is not protected by intellectual property rights unless you have express consent from the owner of that content. You will be liable for any losses or damages that we suffer (whether direct or indirect) from any infringement or violation of a third party’s intellectual property rights due to your breach of this warranty;</li>
    <li>(2)  does not promote or relate to third party surveys, contests, pyramid schemes, chain letters, unsolicited emailing or spamming via our website;</li>
    <li>(3)  does not breach any applicable law, regulation or order of a judicial or regulatory body;</li>
    <li>(4)  is not defamatory, offensive or inappropriate (as determined by us in our sole discretion); or</li>
    <li>(5)  is not false or misleading.</li>
</ol>
<br/>
<p>You also agree that we may, acting reasonably, remove content that you contribute. We may do this where we consider that your content breaches these Terms of Use or your membership Terms and Conditions. However, we have no obligation to remove any content or to monitor member content.<br />
<br />
<em>Member and third-party content</em><br />
<br />
We make no representations about member content or content created or contributed by third parties, including but not limited to representations about the accuracy or reliability of that content.
<br /><br />
We do not endorse, recommend or make any representations in respect of and are not responsible for any member or third-party content. Weare not liable to you, or any business you are associated with, for any loss or damage caused by your reliance on such content.
<br /><br />
Our website may contain links to websites operated by third parties who are not associated with us. These links are provided for your information only. We have no control over those websites or the services made available through them, and accept no responsibility for them or for any loss or damage that may arise from your use of them.<br />
<br /><em>No advice</em><br />
<br />
From time to time, member benefits may include the provision of guidance to members by experts in a particular field. However, this, and any other content contributed by members or third party contributors, is not intended to constitute medical, financial, investment, legal, tax, accounting or other professional advice. We recommend that members seek advice from a professional regarding their specific circumstances.<br />
<br/><strong>Prohibited activities<br />
<br />
&zwj;</strong>You must not use our website in any way that is prohibited by these Terms of Use.<br />
<br />
In addition to any other prohibitions set out in these Terms of Use, each of the following activities are prohibited:</p>

<ol className='pl-6'>
	<li>1. use of any type of data accumulation tool, robot, or spider to compile, disseminate, extract, process, reproduce, or repackage in any form or monitor any of the web pages, content, data or information contained within or accessible through our website;</li>
	<li>2. accumulating or combining the content, data or information contained or accessible through our website with content, data or information from any third party, without our prior written permission;</li>
	<li>3. use of high-volume, automated or systematic processes to obtain data or other information from our website;</li>
	<li>4. compiling, repackaging or disseminating any information or data from our website;</li>
	<li>5. accumulating data or information from our website whether or not using any automatic or manual process;</li>
	<li>6. use of any type of device, process, routine or software either systematically or non-systematically to interfere or attempt to interfere with the functioning of our website or any transaction or process conducted on or through our website;</li>
	<li>7. use or reproduction of any information or data from this web site other than as specifically permitted by these Terms of Use;</li>
	<li>8. incorporation into or storage of any information or data from our website in any other website, electronic retrieval system, database, publication or other work in any form, other than as specifically permitted by these Terms of Use;</li>
	<li>9. combining or processing any data or information from our website with other data or information other than as specifically permitted by these Terms of Use;</li>
	<li>10. creating a link to our website from another website without our prior written permission;</li>
	<li>11. using our website other than as specifically permitted by these Terms of Use;</li>
	<li>12. any use of our website for an unlawful purpose or activity;</li>
	<li>13. either yourself or through a third party changing, modifying, reproducing, or creating any works derived from our website, or displaying in public, any part or content of our website without our prior written permission or unless you have a non-excludable legal right to do so; or</li>
	<li>14. uploading, posting, emailing or transiting material or code that contains viruses or other computer codes, files or programs designed to interrupt, limit or destroy the functionality of other computer software or hardware or to utilise other computer systems for improper purposes or without authorisations.</li>
</ol>
<br></br>
<p><strong>Managing Mums &amp; Co membership online</strong><br />
<br />
You may view and manage certain details relating to your Mums &amp; Co membership on our website. By using our website for this purpose, you warrant to us that you are authorised to manage such details.<br />
<br />
<em>Electronic transactions<br />
<br />
&zwj;</em>You may use our website to perform certain transactions online.<br />
<br />
Any transactions performed by you will not be complete until we receive and process your &quot;Confirmation&quot;. Your Confirmation means any communication issued or transmitted by you to us via our website or other electronic means which contains your acceptance in relation to our offer, or your confirmation of payment, whichever applies. We will notify you when we receive your Confirmation. Such notice allows you to verify whether your transaction has been successfully completed.<br />
<br />
Your Confirmation may not be received by us for various reasons and you acknowledge that we are not liable to you in any way for loss or damage, howsoever caused, directly or indirectly, in connection with the transmission of your Confirmation.<br />
<br />
<strong>Liability<br />
<br />
EXCEPT FOR ANY LIABILITY THAT CANNOT BE EXCLUDED BY LAW (IN WHICH CASE THAT LIABILITY IS LIMITED TO THE MINIMUM ALLOWABLE BY LAW), WE EXCLUDE LIABILITY (INCLUDING FOR NEGLIGENCE) FOR ANY LOSS OR DAMAGE SUFFERED OR INCURRED BY ANY PERSON ARISING DIRECTLY OR INDIRECTLY FROM THEIR:</strong></p>

<ol className='ml-5'>
	<li><strong>1. USE OR RELIANCE ON ANY MATERIAL (INCLUDING THIRD PARTY MATERIAL) ON THIS WEBSITE;</strong></li>
	<li><strong>2. ACCESS OR USE OF, OR INABILITY TO ACCESS ORUSE, THIS WEBSITE; OR</strong></li>
	<li><strong>3. ANY INTERFERENCE OR DAMAGE TO YOUR COMPUTER SYSTEMS WHICH MAY OCCUR IN CONNECTION WITH YOUR USE OF OUR WEBSITE OR ANY THIRD-PARTY CONTENT OR LINKED WEBSITES.</strong></li>
</ol>
<br/>
<p>Nothing in these Terms of Use restricts, excludes, modifies or purports to restrict, exclude or modify any statutory consumer rights under any applicable law, including the&nbsp;<em>Competition and Consumer Act 2010 (Cth).</em><br />
<br />
For the avoidance of doubt, this clause does not modify or otherwise affect our liability in connection with any Mums &amp; Co membership that you may have, including as administered via this website.<br />
<br />
<strong>Other important information<br />
<br />
WE DO NOT GUARANTEE THAT THIS WEBSITE WILL BE FREE FROM VIRUSES, THAT INFORMATION IS FREE FROM ERROR, OR THAT ACCESS TO THIS WEBSITE WILL FUNCTION AS INTENDED OR BE UNINTERRUPTED. AS ELECTRONIC SERVICES ARE SUBJECT TO INTERRUPTION OR BREAKDOWN, ACCESS TO THIS WEBSITE IS OFFERED ON AN AS-AVAILABLE BASIS ONLY. SCHEDULED MAINTENANCE TYPICALLY OCCURS DURING OFF PEAK-TIMES TO MINIMISE DISRUPTION TO YOU. ALL TERMS IMPLIED BY LAW, EXCEPT THOSE THAT CAN BE LAWFULLY EXCLUDED, ARE EXCLUDED.</strong><br />
<br />
The information on our website is provided for individuals who reside in Australia only, and where indicated, may be limited to specific states and territories of Australia. The law applicable in the State of New South Wales will apply to our website.<br />
<br />
Any failure by us to exercise any right, power or remedy under these Terms of Use shall not operate as a waiver of our rights.</p>

<p>&nbsp;</p>
      </div>
    </div>
  );
};

export default Usage;
