import React, { useRef, useEffect, useContext, useState } from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { AppContext, PageContext } from '../../helpers/context';
import EventBookingConfirmation from '../../components/modals/event-booking-confirmation';
import EventBookingCancel from '../../components/modals/event-booking-cancel';
import { AUTH } from '../../utils/common';
import ModalSuccess from '../../components/modals/modal-success';
import ExpertConfirmation from '../../components/modals/expert-confirmation';

const ExpertEvents = ({ history, data }) => {
  const { setHeightCards } = useContext(PageContext);
  const { user_email, user_tokens } = useContext(AppContext);
  const containerRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentResource, setCurrentResource] = useState({});
  const [currentEvent, setCurrentEvent] = useState({});
  const USER = AUTH();
  const USER_ID = USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'];
  const [showBookingSuccessModal, setShowBookingSuccessModal] = useState(false);
  const [showBookingFormModal, setShowBookingFormModal] = useState(false);
  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);


  useEffect(() => {
    getContainerSize();
    window.addEventListener('resize', getContainerSize);

    return () => {
      window.removeEventListener('resize', getContainerSize);
    };
  }, []);

  const getContainerSize = () => {
    setHeightCards((oldState) => ({
      ...oldState,
      details: containerRef.current.clientHeight + oldState.details,
    }));
  };

  const bookNow = ({ event }) => () => {
    setShowModal(!showModal);
    setRequest(false);
    setBookNow(!isBookNow);
    setCurrentEvent(event);
  };

  const handleConfirmModal = (expert) => () => {
    if (user_tokens < expert.cost) {
      localStorage.setItem('@return-url', `/get-advice/${expert.id}?modal=expert-registration`);
      return history.push('/top-up', { lackAmount: expert.cost - user_tokens, amount: expert.cost });
    }

    setShowModal(!showModal);
    setBookNow(false);
    setRequest(!isRequest);
    setCurrentResource(expert);
  };

  const checkIfAllThreeIsSoldOut = (data) => {
    const result = data.map((value) => {
      let numberOfSpotsLeft =
        value.number_of_spots -
        (value.attendees.filter((value) => value.status === 1).length -
          value.attendees.filter((value) => value.status === 2).length);
      const hasAttendedTheEvent = value.attendees.length
        ? value.attendees[0].user_id === USER_ID && value.attendees[0].status === 1
        : false;
      const noOfDaysUntilTheEvent = moment(moment(value.date).format('YYYY-MM-DD')).diff(
        moment().format('YYYY-MM-DD'),
        'd',
      );
      
      if (numberOfSpotsLeft === 0 || noOfDaysUntilTheEvent === 0) {
        if (hasAttendedTheEvent) {
          return false;
        }
        return true;
      }

      return false;
    });
    return typeof result.find((check) => !check) === 'undefined';
  };

  const EventFooter = ({ numberOfSpotsLeft, hasAttendedTheEvent, noOfDaysUntilTheEvent, event }) => {
    //moment().isAfter(new moment(`${event.date_to} ${event.time_to}`))

    //numberOfSpotsLeft === 0
    if (numberOfSpotsLeft <= 0) {
      //hasAttendedTheEvent
      if (hasAttendedTheEvent) {
        return (
          <div
            className={'py-2 w-full rounded-b-lg bg-event-going text-white text-lg flex justify-center items-center'}
          >
            Going
          </div>
        );
      }
      return (
        <div
          className={
            'py-2 w-full rounded-b-lg bg-light-gray-header text-black text-lg flex justify-center items-center'
          }
        >
          Sold Out
        </div>
      );
    }

    if (noOfDaysUntilTheEvent === 0) {
      return null;
    } else {
      return (
          <button
          onClick={bookNow({ event })}
          className={
            'py-2 focus:outline-none w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center hover:text-white'
          }
          >
            Book Now
          </button>
      );
    }    
  };
  
const formatTimeWithTimezone = (time_from, time_to) => {
    // Helper function to get the timezone abbreviation based on the offset
    const getTimezoneAbbreviation = (offset) => {
      if (offset === "+10") {
        return "AEST";
      } else if (offset === "+11") {
        return "AEDT";
      } else {
        return " ";
      }
    };
    const timezoneOffset = time_from.slice(-3);
    
    
    const timezoneAbbreviation = getTimezoneAbbreviation(timezoneOffset)

    let formattedTimeFrom = moment();
    let formattedTimeTo = moment();
    if(timezoneAbbreviation === "AEDT"){
      formattedTimeFrom = moment(time_from, 'HH:mm:ssZ').tz('Asia/Magadan').format('h:mm A');
      formattedTimeTo = moment(time_to, 'HH:mm:ssZ').tz('Asia/Magadan').format('h:mm A');
    } else {
      formattedTimeFrom = moment(time_from, 'HH:mm:ssZ').tz('Australia/Sydney').format('h:mm A');
      formattedTimeTo = moment(time_to, 'HH:mm:ssZ').tz('Australia/Sydney').format('h:mm A');
    }
  
    return `${formattedTimeFrom} - ${formattedTimeTo} ${timezoneAbbreviation}`
  };


  return (
    <PageContext.Provider
      value={{
        showModal,
        setShowModal,
        showCancelModal,
        setShowCancelModal,
        currentEvent,
        setShowSuccessModal,
        showSuccessModal,
        setShowBookingSuccessModal,
        currentResource,
        setCurrentResource,
        showBookingFormModal,
        setShowBookingFormModal,
        isBookNow,
        setBookNow,
        isRequest,
        setRequest
      }}
    >
      <div
        className={`mt-10 mb-7 grid gap-4 ${
          !checkIfAllThreeIsSoldOut(data.events) ? 'md:grid-cols-3 grid-cols-1' : 'grid-cols-1'
        }`}
        ref={containerRef}
      >
        {isBookNow === true ? (
          <>
            <EventBookingConfirmation data={currentEvent} />
            <EventBookingCancel data={currentEvent} />
            <ModalSuccess title={'Event RSVP'} subtitle={'You have successfully booked a seat in this event.'} />
          </>
        ) : null }
        {isRequest === true ? (
          <>
            <ExpertConfirmation data={currentResource} />
            <ModalSuccess 
              title={'Expert session request received'}
              subtitle={`You'll receive a message at ${user_email} from our team to set up the session.`}
            />
          </> 
        ) : null }
          {!checkIfAllThreeIsSoldOut(data.events) ? (
          data.events.map((value) => {
            let numberOfSpotsLeft =
              value.number_of_spots -
              (value.attendees.filter((value) => value.status === 1).length -
                value.attendees.filter((value) => value.status === 2).length);
            const hasAttendedTheEvent = value.attendees.length
              ? value.attendees[0].user_id === USER_ID && value.attendees[0].status === 1
              : false;
            const noOfDaysUntilTheEvent = moment(moment(value.date).format('YYYY-MM-DD')).diff(
              moment().format('YYYY-MM-DD'),
              'd',
            );
            return (
              <>
                <div className="bg-white flex-1 rounded-md shadow-md overflow-hidden">
                  <div className="p-5 text-center flex flex-col">
                    <span className="font-bold text-xl text-black">{moment(value.date).format('ll')}</span>
                    <span className="font-bold text-base">
                     
                      {formatTimeWithTimezone(value.time_from, value.time_to)}
                    </span>
                  </div>
                  <EventFooter
                    event={value}
                    numberOfSpotsLeft={numberOfSpotsLeft}
                    hasAttendedTheEvent={hasAttendedTheEvent}
                    noOfDaysUntilTheEvent={noOfDaysUntilTheEvent}
                  />
                </div>
              </>
            );
          })
        ) : null }
      </div>
      <div className="bg-white flex-1 rounded-md shadow-md overflow-hidden w-full">
        <div className="p-5 text-center">
          <p className='font-bold text-black'> No slots available that work for you? </p>
          <p> Hit the button below  and we will arrange a time and date that works for both you and our Expert. </p>
        </div>
        <button
          onClick={handleConfirmModal(data)}
          className="focus:outline-none w-full bg-primary text-white text-center py-2 text-lg"
        >
          Request an Expert session
        </button>
      </div>
    </PageContext.Provider>
  );
};

export default withRouter(ExpertEvents);
