import { useSubscription } from '@apollo/react-hooks';
import { Spin } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import ExpertCard from '../../components/cards/expert-card';
import withApollo from '../../config/with-apollo';
import { S_EXPERTS } from '../../graphql/experts';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import EventCard from '../../components/cards/event-card';
import _, { isEmpty } from 'lodash';

const ExpertsSubscription = (props) => {
  const USER = AUTH();
  const { filter, setFilterTags, setIntroText } = useContext(PageContext);

  const keywordFilter = useMemo(() => {
    const queryFilter = {}
    if (filter.keyword && filter.keyword.trim()) {
      queryFilter._or = [
        { name: { _ilike: `%${filter.keyword.trim()}%` } },
        { company: { _ilike: `%${filter.keyword.trim()}%` } },
        { expert_listing_business_goals_tags: { business_goals_tag: { name: { _ilike: `%${filter.keyword.trim()}%` } } } }
      ];
    }
    return queryFilter;
  }, [filter.keyword]);

  const tagsFilter = useMemo(() => {
    const queryFilter = {};
    if (filter.is_related) queryFilter.business_goals_tag_id = { _in: filter.tags };
    if (filter.pillar !== 'All') queryFilter.pillars_id = { _eq: filter.pillar };
    if (filter.category) queryFilter.categories_id = { _eq: filter.category };
    return !isEmpty(queryFilter) ? queryFilter : undefined;
  }, [filter]);

  const { loading, data } = useSubscription(S_EXPERTS, {
    client: props.client,
    variables: {
      where: {
        ...keywordFilter,
        expert_listing_business_goals_tags: tagsFilter,
        is_active: { _eq: true },
        // expert_listing_sessions: filter.myResources ? { user_id: { _eq: filter.myResources } } : undefined,
        expert_listing_tags: filter.resource_tag_idx.length ? { tag: { _in: filter.resource_tag_idx } } : undefined,
        expert_listing_to_categories: filter.resource_category_id
          ? { expert_listing_category_id: { _eq: filter.resource_category_id } }
          : undefined,
      },
    },
  });

  useEffect(() => {
    if (loading) return setIntroText(0)

    if (((data || {}).expert_listings || []).length) {
      setIntroText(1);
    } else {
      setIntroText(2);
    }

    if (!loading && data) {
      let tags = data.expert_listings.map(({ expert_listing_tags }) => expert_listing_tags);
      setFilterTags(_.flatten(tags));
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className={'w-full flex justify-center items-center h-full flex-col'}>
        <Spin size={'large'} />
        <p>Getting experts</p>
      </div>
    );
  }

  if (filter.myResources && data) {
    const events = data.expert_listings
      .filter(
        (expert) =>
          expert.events.filter((event) => !!event.attendees.find((attendee) => attendee.user_id === USER.id)).length,
      )
      .map(({ events }) => {
        return events.filter((event) => !!event.attendees.find((attendee) => attendee.user_id === USER.id));
      });

    return (
      !loading &&
      _.sortBy(_.flatten(events), ['date_from', 'date_to'])
        .map((event) => {
          return (
            <div className={`md:pr-10 pb-7 sm:w-full md:w-full lg:w-1/2 xl:w-4/12 h-full`}>
              <EventCard data={event} hasTransaction={false} noStatus={true} />
            </div>
          );
        })
        .reverse()
    );
  }

  let expertListings = []
  if (!loading && data) {
    expertListings = data.expert_listings;
  }
  return (
    !loading &&
    expertListings.map((value) => {
      return (
        <div className={`md:pr-10 pb-7 sm:w-full md:w-full lg:w-1/2 xl:w-4/12 h-full`}>
          <ExpertCard data={value} hasTransaction={false} />
        </div>
      );
    })
  );
};

export default compose(withApollo)(ExpertsSubscription);
