export default (user, loading) => {
  let completion = 0;

  if (user['avatar']) {
    completion += 15;
  }
  if (user['address']) {
    completion += 10;
  }
  if (user['number']) {
    completion += 15;
  }

  if (user['tags']) {
    completion += 15;
  }

  if (!loading && !!user.businesses.length) {
    if (user.businesses[0]['offering']) {
      completion += 5;
    }
    if (user.businesses[0]['stage']) {
      completion += 10;
    }
    if (user.businesses[0]['type']) {
      completion += 10;
    }
    if (user.businesses[0]['url']) {
      completion += 5;
    }
    if (user.businesses[0]['name']) {
      completion += 5;
    }
    if (user.businesses[0]['industry']) {
      completion += 10;
    }
  }

  return completion;
};
