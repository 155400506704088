import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

let apolloClientInstance;

const getHeaders = () => {
  const headers = {};
  const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN));
  if (token) {
    headers.authorization = `Bearer ${token.token}`;
  } else {
    window.location = '/';
  }
  return headers;
};

const apolloClient = () => {
  if (apolloClientInstance) return apolloClientInstance;
  // Create an http link:
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_HASURA_HTTP_URL}/v1/graphql`,
    fetch,
    headers: getHeaders()
  });
  
  // Create a WebSocket link:
  const wsLink = new WebSocketLink(
    new SubscriptionClient(`${process.env.REACT_APP_HASURA_WS_URL}/v1/graphql`, {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => {
        return { headers: getHeaders() };
      },
      connectionCallback: err => {
        if (err) {
          wsLink.subscriptionClient.close(false, false);
        }
      }
    })
  );
  
  // chose the link to use based on operation
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );
  
  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: true
    })
  });
  
  apolloClientInstance = client;
  
  return apolloClientInstance;
};

export default apolloClient;

