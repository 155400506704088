import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { Subscription } from 'react-apollo';
import EventCard from '../../components/cards/event-card';
import { S_EVENTS } from '../../graphql/events';
import { PageContext } from '../../helpers/context';

const EventSubscription = () => {
  const { filter, setIntroText } = useContext(PageContext);

  const keywordFilter = useMemo(() => {
    const queryFilter = {}
    if (filter.keyword && filter.keyword.trim()) {
      queryFilter._or = [
        { name: { _ilike: `%${filter.keyword.trim()}%` } },
        { event_type: { type: { _ilike: `%${filter.keyword.trim()}%` } } },
        { expert_listing: { name: { _ilike: `%${filter.keyword.trim()}%` } } },
        { event_business_goals_tags: { business_goals_tag: { name: { _ilike: `%${filter.keyword.trim()}%` } } } }
      ];
    }
    return queryFilter;
  }, [filter.keyword]);

  const tagsFilter = useMemo(() => {
    const queryFilter = {};
    if (filter.is_related) queryFilter.business_goals_tag_id = { _in: filter.tags };
    if (filter.pillar !== 'All') queryFilter.pillars_id = { _eq: filter.pillar };
    if (filter.category) queryFilter.categories_id = { _eq: filter.category };
    return !isEmpty(queryFilter) ? queryFilter : undefined;
  }, [filter]);

  return useMemo(() => {
    return (
      <Subscription
        subscription={S_EVENTS}
        variables={{
          where: {
            // event_type_id: { _eq: filter.event_type },
            event_business_goals_tags: tagsFilter,
            ...keywordFilter,
            ...filter.events,
            date_to: !filter.events ? { _gte: moment().format() } : undefined,
            event_type: { display: { _eq: 'y' } },
          },
          order: !filter.events ? { date_from: 'asc' } : { date_from: 'desc' },
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            setIntroText(0);
            return (
              <div className={'w-full flex justify-center items-center h-full flex-col'}>
                <Spin size={'large'} />
                <p>Getting events</p>
              </div>
            );
          }

          if (((data || {}).events || []).length) {
            setIntroText(1);
          } else {
            setIntroText(2);
          }

          return data.events.map((event, key) => {
            let dateTo = moment(event.date_to).format('YYYY-MM-DD');
            let timeTo = new moment(`${event.time_to}`, 'HH:mm:ss Z').format('HH:mm:ss');

            if (!moment().isAfter(moment(`${dateTo} ${timeTo}`))) {
              return (
                <div className={`md:pr-10 pb-7 sm:w-full md:w-full lg:w-1/2 xl:w-4/12 h-full`}>
                  <EventCard data={event} />
                </div>
              );
            }

            if (filter.events) {
              return (
                <div className={`md:pr-10 pb-7 sm:w-full md:w-full lg:w-1/2 xl:w-4/12 h-full`}>
                  <EventCard data={event} />
                </div>
              );
            }
          });
        }}
      </Subscription>
    );
  }, [filter]);
};

export default EventSubscription;
