import gql from 'graphql-tag';

const F_USER_DETAILS = (name, model, additionalFields = []) => {
  let gqlTag = `
        fragment ${name} on ${model}{
            user{
                id
                name
                avatar
                ${[...additionalFields]}
                 businesses(order_by: {order: asc}){
                    id
                    name
                }
            }
        }
   `;

  return gql`
    ${gqlTag}
  `;
};

const F_EVENTS = (name, model, additionalFields = []) => {
  let gqlTag = `
        fragment ${name} on ${model}{
            id
            name
            date_to
            date_from
            time_from
            time_to
            location
            cost
            number_of_spots
            location_notes
            event_type_id
            image
            online
            video_id
            subscription_type
            ${[...additionalFields]}
        }
   `;

  return gql`
    ${gqlTag}
  `;
};

export const S_EVENT_TYPES = gql`
  subscription eventType {
    event_type(order_by: { created_at: desc }, where: { _not: { type: { _eq: "1:1 Expert Sessions" } } }) {
      id
      type
    }
  }
`;

export const S_EVENTS = gql`
  ${F_EVENTS('event_fragment', 'events')}
  subscription events($where: events_bool_exp, $order: [events_order_by!]) {
    events(where: $where, order_by: $order) {
      ...event_fragment
      event_type {
        type
      }
      attendees(where: { status: { _eq: 1 } }) {
        id
        event_id
        user_id
      }
      attendees_count: attendees(where: { status: { _eq: 1 } }) {
        id
        user_id
      }
      event_business_goals_tags {
        id
        pillars_id
        categories_id
        business_goals_tag {
          name
          id
        }
        pillar {
          color
          id
          name
        }
      }
    }
  }
`;

export const S_EVENT = gql`
  ${F_USER_DETAILS('attendee_user_details', 'attendees')}
  ${F_USER_DETAILS('host_user_details', 'events')}
  ${F_EVENTS('event_fragment', 'events', ['description'])}
  subscription events($id: uuid!) {
    events(where: { id: { _eq: $id } }) {
      ...event_fragment
      ...host_user_details 
      event_type {
        type
      }
      attendees(where: { status: { _eq: 1 } }, limit: 4, order_by: { created_at: desc }) {
        id
        event_id
        user_id
        ...attendee_user_details
      }
      attendees_count: attendees(where: { status: { _eq: 1 } }, order_by: { created_at: desc }) {
        id
        user_id
      }
      attendees_aggregate(offset: 2, where: { status: { _eq: 1 } }, order_by: { created_at: desc }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const M_EVENT_COMMENT = gql`
  mutation insertComment($objects: [comments_insert_input!]!) {
    insert_comments(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const S_EVENT_COMMENTS = gql`
  ${F_USER_DETAILS('attendee_user_details', 'comments')}
  subscription eventComments($eventId: uuid!) {
    comments(where: { event_id: { _eq: $eventId } }, order_by: { created_at: desc }) {
      id
      comment
      user_id
      created_at
      ...attendee_user_details
    }
  }
`;

export const M_EVENT_ATTENDEE_GOING = gql`
  mutation eventGoing($objects: [attendees_insert_input!]!) {
    insert_attendees(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const M_EVENT_ATTENDEE_CANCEL = gql`
  mutation eventGoing($where: attendees_bool_exp!, $set: attendees_set_input) {
    update_attendees(where: $where, _set: $set) {
      returning {
        id
      }
    }
  }
`;

export const S_EVENT_ATTENDEES_LIST = gql`
  ${F_USER_DETAILS('attendee_user_details', 'attendees')}
  subscription attendeesList($eventId: uuid!) {
    attendees(where: { status: { _eq: 1 }, event_id: { _eq: $eventId } }, offset: 2, order_by: { created_at: desc }) {
      id
      ...attendee_user_details
    }
  }
`;
