import { useQuery } from '@apollo/react-hooks';
import { Input, Select } from 'antd';
import 'antd/es/icon/style/css';
import 'antd/es/input/style/css';
import 'antd/es/notification/style/css';
import 'antd/es/select/style/css';
import React, { useContext } from 'react';
import withApollo from '../../../config/with-apollo';
import { Q_INDUSTRIES } from '../../../graphql/user';
import { PageContext } from '../../../helpers/context';
import { offeringOption, stageOption, typeOption } from './data-objects';

const ProfileSettingForm = ({ client }) => {
  const { currentBusiness, business, onChangeBusiness: onChange } = useContext(PageContext);
  
  const { data, loading } = useQuery(Q_INDUSTRIES, { client });
  
  return <>
    <div className={'mb-4'}>
      <div className={'flex flex-col w-full mb-4'}>
        <div className={'w-full flex mr-8 items-center mb-4 md:mb-2'}>
          <p className={'font-medium text-black w-1/4'}>Stage</p>
          <Select onChange={onChange('stage')}
            placeholder={'Select Stage'}
            value={business[currentBusiness].stage}
            className={'md:w-4/5 w-full'}>
            {stageOption.map(
              ({ value, key, label }) =>
                <Select.Option
                  value={value}
                  key={key}
                  className={'custom-select-option'}>
                  {label}
                </Select.Option>
            )}
          </Select>
        </div>
        <div className={'w-full flex items-center'}>
          <p className={'font-medium text-black w-1/4'}>Industry</p>
          <Select onChange={onChange('industry')}
            placeholder={'Select Industry'}
            filterOption={true}
            showSearch={true}
            value={business[currentBusiness].industry}
            className={'md:w-4/5 w-full'}>
            {!loading && data.industries.map(
              ({ name }) =>
                <Select.Option
                  value={name}
                  key={name}
                  className={'custom-select-option'}>
                  {name}
                </Select.Option>
            )}
          </Select>
        </div>
      </div>
      <div className={'flex flex-col w-full mb-4'}>
        <div className={'w-full flex mr-8 items-center mb-4 md:mb-2'}>
          <p className={'font-medium text-black w-1/4'}>Type</p>
          <Select onChange={onChange('type')}
            placeholder={'Select Type'}
            value={business[currentBusiness].type}
            className={'md:w-4/5 w-full'}>
            {typeOption.map(
              ({ value, key, label }) =>
                <Select.Option
                  value={value}
                  key={key}
                  className={'custom-select-option'}>
                  {label}
                </Select.Option>
            )}
          </Select>
        </div>
        <div className={'w-full flex'}>
          <p className={'font-medium text-black w-1/4'}>Offering</p>
          <Select onChange={onChange('offering')}
            placeholder={'Select Offering'}
            value={business[currentBusiness].offering}
            className={'md:w-4/5 w-full'}>
            {offeringOption.map(
              ({ value, key, label }) =>
                <Select.Option
                  value={value}
                  key={key}
                  className={'custom-select-option'}>
                  {label}
                </Select.Option>
            )}
          </Select>
        </div>
      </div>
      <div className={'flex flex-col w-full'}>
        <div className={'w-full flex mr-8 items-center mb-4 md:mb-2'}>
          <p className={'font-medium text-black w-1/4'}>Name</p>
          <Input
            onChange={onChange('name')}
            value={business[currentBusiness].name}
            className={'md:w-4/5 w-full border rounded bg-white custom-focus-hover'}/>
        </div>
        <div className={'w-full flex'}>
          <p className={'font-medium text-black w-1/4'}>Url</p>
          <Input
            onChange={onChange('url')}
            value={business[currentBusiness].url}
            className={'md:w-4/5 w-full border rounded bg-white custom-focus-hover'}/>
        </div>
      </div>
    </div>
    <div className={'mb-4 pl-16.1 md:pl-0'}>
      <Input.TextArea placeholder={'Tell members about your business - Other members could be your customers. What should they know about your products or services? What\'s your USP ?'}
        onChange={onChange('about')}
        value={business[currentBusiness].about}
        rows={3}
        maxLength={500}
        className={`border h-35 p-3 w-full rounded bg-white custom-focus-hover`}
      />
      <p className={'font-light'} style={{ fontSize: '0.625rem' }}>{business[currentBusiness].about ? 500 -
        business[currentBusiness].about.length : '500'} characters remaining </p>
    </div>
  </>;
};

export default withApollo(ProfileSettingForm);
