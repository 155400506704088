import { Card, Icon, Tooltip } from 'antd';
import 'antd/es/tooltip/style/css';
import _, { startCase } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import 'resize-observer-polyfill';
import calendarIcon from '../../assets/icons/calendar-icon.png';
import locationIcon from '../../assets/icons/location-icon.png';
import upgradeIcon from '../../assets/icons/upgrade-icon.png';
import approveIcon from '../../assets/icons/approve-icon.png';
import ticketIcon from '../../assets/icons/ticket-icon.png';
import tooltipIcon from '../../assets/icons/tooltip-icon.png';
import withApollo from '../../config/with-apollo';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import { useQuery } from '@apollo/react-hooks';
import { Q_USER } from '../../graphql/user';

const EventCard = (props) => {
  const { client } = props;
  const { event, setShowModal, setCurrentEvent, queries, setQueries } = useContext(
    PageContext,
  );
  let eventData = props.data || event;
  const USER = AUTH();
  const USER_ID = USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'];
  const ID = props.match.params.id;
  const { data: user } = useQuery(Q_USER, { client, variables: { id: USER.id } });
  const [userData, setUserData] = useState([]);
  const hasAttendedTheEvent = (props.data || eventData).attendees_count.find((value) => value.user_id === USER_ID);
  useEffect(() => {
    if (queries && ['event-registration', 'event-booking-confirmation'].includes(queries.modal)) {
      setQueries({ modal: undefined });
    }
    
    setUserData(user.users_by_pk);
  }, [user]);

  const numberOfSpotsLeft = eventData.number_of_spots - eventData.attendees_count.length;


  // Calculate the difference between event date and current date
    const eventDate = moment(eventData.date_from, "YYYY-MM-DD");
    const currentDate = moment();
    const daysDifference = eventDate.diff(currentDate, 'days');

  const EventButton = () => {
    let dateTo = moment(eventData.date_to).format('YYYY-MM-DD');
    let timeTo = new moment(`${eventData.time_to}`, 'HH:mm:ss Z').format('HH:mm:ss');
    if (props.noStatus) {
      return <div className="mt-6" />;
    }

    if (moment().isAfter(moment(`${dateTo} ${timeTo}`))) {
      return (
        <div
          className={
            'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-light-gray-header text-black flex justify-center items-center mt-6'
          }
        >
          Expired
        </div>
      );
    }

    if (numberOfSpotsLeft <= 0) {
      if (hasAttendedTheEvent) {
        return (
          <>
            <a href={`/events/${eventData.id}`}
              className={
                'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-event-going text-white hover:text-white flex justify-center items-center mt-6'
              }
            >
              View Meeting Details
            </a>
          </>
        );
      }

      return (
        <div
          className={
            'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-light-gray-header text-black flex justify-center items-center mt-6'
          }
        >
          Sold Out
        </div>
      );
    }

    if (hasAttendedTheEvent) {
      const timeFrom = eventData.time_from.replace(/\+\d+$/, '');
      const timeTo = eventData.time_to.replace(/\+\d+$/, '');
      return (
        <>
          <a href={`/events/${eventData.id}`}
            className={'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-event-going text-white hover:text-white flex justify-center items-center mt-6'}
          >
            View Meeting Details
          </a>
        </>
      );
    } if (eventData.event_type_id === '523336ef-723a-4a02-95ea-273fcd19cb4b' || eventData.event_type_id === 'fa6b2383-eca5-497c-91b3-402834c8e1b2'){
      return <>
        <a href={eventData.location_notes}
          className={'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-primary text-white flex justify-center items-center mt-6 hover:text-white'}>
          Count me in
        </a>
        {userData.subscription_type === 'STANDARD' || userData.subscription_type === 'PREMIUM' ? null : 
          <a href={`/events/${eventData.id}`}
            className={
              'font-sans h-6 w-4/5 text-base font-medium text-primary hover:text-primary flex justify-center items-center mt-4'
            }
            >
            See more details
          </a>
        }
      </>
    } else {
      return (
        <>
          <a
            onClick={handleConfirmModal(eventData)}
            className={
              'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-primary text-white flex justify-center justify-self-center items-center mt-6 hover:text-white'
            }
          >
            Count me in
          </a>
          {userData.subscription_type === 'STANDARD' || userData.subscription_type === 'PREMIUM' ? null : 
            <a href={`/events/${eventData.id}`}
              className={
                'font-sans h-6 w-4/5 text-base font-medium text-primary hover:text-primary flex justify-center items-center mt-4'
              }
              >
              See more details
            </a>
          }
        </>
      );
    }
  };

  const handleConfirmModal = (event) => () => {
    setShowModal(state => {
      const newState = !state;
      setQueries({ modal: newState ? 'event-registration' : undefined });
      return newState;
    });
    setCurrentEvent(event);
  };

  const CardCover = () => {
    if (!ID) {
      if (!eventData.video_id) {
        return (
          <div className='relative w-full h-48 overflow-hidden'> 
            <img src={eventData.image} className='absolute inset-0 w-auto h-auto object-cover' />
          </div>
        );
      }

      return (
        <iframe
          className={`w-full xl:h-52 lg:h-63.5 md:h-84 h-46 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${eventData.video_id}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    } else {
      if (!eventData.video_id) {
        return (
          <div
            className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-contain bg-no-repeat bg-center`}
            style={{ backgroundImage: `url(${eventData.image})` }}
          />
        );
      }

      return (
        <iframe
          className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${eventData.video_id}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    }
  };

  function subscriptionType(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

  const Requires = () => {
    if(userData.subscription_type === eventData.subscription_type || userData.subscription_type === 'PREMIUM' || userData.subscription_type === 'STANDARD'){
      return (
        <>
          <div className={'text-base text-black flex mt-2'}>
            <div className={'w-1/8 pt-0.5'}>
              <img src={approveIcon} className={'w-6 h-auto'} alt={''} />
            </div>
            <div className={'w-full flex pb-0.5'}>
              <p className={'text-black font-medium text-sm font-sans'}>
                Included with your {subscriptionType(userData.subscription_type)} Subscription
              </p>
            </div>
          </div>
        </>
      )
    } if(userData.subscription_type !== eventData.subscription_type || userData.subscription_type === 'SIGN_UP'){
      return (
        <>
          <div className={'text-base text-black flex mt-2'}>
            <div className={'w-1/8 pt-0.5'}>
              <img src={eventData.cost === 0 ? ticketIcon : upgradeIcon} className={`${eventData.cost === 0 ? 'w-5' : 'w-6'} h-auto`} alt={''} />
            </div>
            <div className={'w-full flex pb-0.5'}>
              
              {eventData.cost === 0 ? 
                <>
                  <p className={`text-black font-semibold text-sm font-sans ${eventData.cost === 0 ? '' : 'mr-3'}`}>
                    Free
                  </p>
                </>
                :
                <>
                  <p className={`text-black font-medium text-sm font-sans ${eventData.cost === 0 ? '' : 'mr-3'}`}>
                    Requires Upgrade OR
                  </p>
                  <div className={'mr-1.5 pb-0.5'}>
                    <img src={ticketIcon} className={'w-5 h-auto'} alt={''} />
                  </div>
                  <p className={'text-black font-medium text-sm font-sans'}>
                    {eventData.cost}
                  </p>
                </>
              }
            </div>
          </div>
        </>
      )
    } 
  };

  const Going = () => {
    if(daysDifference > 0){
      return <p className='text-black font-normal text-sm font-sans mb-3'>You are registered for this event!</p>
    } else{
      return null;
    }
  };

  const JoinMeeting = () => {
    
  const eventDateTime = `${eventData.date_from}T${eventData.time_from}`; // Include the full datetime with offset

    return (
      <>
        <a href={`${eventData.location}`}
          className={'font-sans h-8 w-4/5 text-base font-medium rounded-lg bg-event-going text-white hover:text-white flex justify-center items-center mt-6'}
        >
          Join Meeting
        </a>
        <p className={'font-sans h-6 w-4/5 text-base font-medium text-red-800 flex justify-center items-center mt-4'}>
          {EventCountdown(eventDateTime)}
        </p>
      </>
    );
  };

  const TagList = () => {
    if (eventData.event_business_goals_tags !== undefined) {
      if (ID) {
        return eventData.event_business_goals_tags.map((value, key) => {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 3px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-black flex mr-2 mb-2 font-sans font-medium uppercase'}>{value.business_goals_tag.name}</span>;
        });
      }
      return _.takeRight(eventData.event_business_goals_tags, 4).map((value, key) => {
        if (key >= 3) {
          return <span className={'px-1 text-xs text-gray-900 font-medium italic flex mr-1 mb-2'}>and more...</span>;
        } else {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 3px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-gray-700 flex mr-1 mb-2 font-sans font-medium uppercase'}>{value.business_goals_tag.name}</span>;
        }
      });
    } else {
      return <div className={'rounded px-1 text-xs text-black bg-gray-300 flex mr-2 mb-2'}>N/A</div>;
    }
  };
  
  const formatTimeWithTimezone = (time_from, time_to) => {
    const getTimezoneAbbreviation = (offset) => {
      if (offset === "+10") {
        return "AEST";
      } else if (offset === "+11") {
        return "AEDT";
      } else {
        return " ";
      }
    };
  
    const timezoneOffset = time_from.slice(-3);
    const timezoneAbbreviation = getTimezoneAbbreviation(timezoneOffset);
  
    const formatTime = (time, offset) => {
      return moment.tz(time, 'HH:mm:ss', 'UTC').tz(`Australia/Sydney${offset}`).format('h:mm A');
    };
  
    const formattedTimeFrom = formatTime(time_from, timezoneOffset);
    const formattedTimeTo = formatTime(time_to, timezoneOffset);
  
    return `${formattedTimeFrom} - ${formattedTimeTo} ${timezoneAbbreviation}`;
  };
  
  const EventCountdown = ( eventDateTime ) => {
    const eventTime = moment(eventDateTime);
    const currentTime = moment();
    const duration = moment.duration(eventTime.diff(currentTime));
  
   if (duration.asDays() < 1) {
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());
      if(hours < 0){
        return `Event has concluded`
      } else{
        return `Starts in ${hours} hrs and ${minutes} mins`;
      }
    } else {
      return ''
    }
  }


  return (
    <>
      <Card
        className={`bg-white rounded-lg overflow-hidden shadow-md h-auto w-96 ${props.card && props.card.className}`}
      >
        <div className='flex items-center justify-center'>
          <CardCover />
        </div>
        <div className={'px-3'}>
          <p
            onClick={() => {
              !props.noStatus && props.history.push(`/events/${eventData.id}`);
            }}
            className={`pt-4 font-bold font-sans text-xl text-black cursor-pointer ${props.noStatus ? '' : 'hover:text-primary'
              }
             ${!ID && 'truncate-2 h-20'}`}
          >
            {eventData.name}
          </p>
          {hasAttendedTheEvent ? <Going /> : <Requires />}
          <div className={'text-base text-black flex mt-2'}>
            <div className={'w-1/8 pt-0.5'}>
              <img src={calendarIcon} className={'w-5 h-auto'} alt={''} />
            </div>
            <div className={'w-full flex pb-0.5'}>
              <p className={'text-black font-medium text-sm font-sans mr-2'}>
                {moment(eventData.date_from).isSame(eventData.date_to)
                  ? moment(eventData.date_to).format('ll')
                  : `${moment(eventData.date_from).format('ll')} - ${moment(eventData.date_to).format('ll')}`}
              </p>
              <p className={'text-black font-medium text-sm font-sans'}>
                {formatTimeWithTimezone(eventData.time_from, eventData.time_to)}
              </p>
            </div>
          </div>
          <div className={`flex mt-2`}>
            <div className={'flex flex-col'}>
              <div className={'truncate-2'}>
                <div className={`flex flex-row content-start items-start flex-wrap`}>
                  <TagList />
                </div>
              </div>
            </div>
          </div>
          <div className={'mt-2 text-base text-black flex h-12'}>
            <div className={'w-1/8 pt-0.5'}>
              <img src={locationIcon} className={'w-5 h-auto'} alt={''} />
            </div>
            <div className={'w-full flex justify justify-between pb-0.5'}>
            {!eventData.online && (
              <p className='w-1/2 truncate-2 overflow-hidden font-sans text-black font-medium text-sm'>
                {eventData.location}{' '}
                {!!eventData.location_notes && (
                  <Tooltip placement="topLeft" title={eventData.location_notes}>
                    <img src={tooltipIcon} className={'w-4 h-auto inline'} alt={''} />
                  </Tooltip>
                )}
              </p>
            )}
              {eventData.online && (
                <a
                  href={!!hasAttendedTheEvent && eventData.online ? eventData.location : '#'}
                  target={'__blank'}
                  className={`font-medium text-sm pb-1 ${!!hasAttendedTheEvent ? 'text-primary' : 'text-black'}`}
                >
                  {!!hasAttendedTheEvent && eventData.online ? "Online" : 'Online'}
                </a>
              )}
              {!eventData.online && ID && (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                  target="_blank"
                  className={'text-primary text-base hover:text-primary'}
                >
                  View on map
                </a>
              )}
              {(!hasAttendedTheEvent && numberOfSpotsLeft !== 0 && numberOfSpotsLeft <= eventData.number_of_spots * 0.2) ? (
                <>
                  <p className='text-primary font-sans text-sm font-medium'>
                    Only {numberOfSpotsLeft === 1 ? '1 seat' : `${numberOfSpotsLeft || 0} seats`} left!
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center mb-3'>
          {daysDifference <= 0 && hasAttendedTheEvent ? <JoinMeeting /> : <EventButton />}
        </div>
      </Card>
    </>
  );
};

export default compose(
  withRouter,
  withApollo,
)(EventCard);
