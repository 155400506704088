import React, { useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import facebook from '../../assets/icons/facebook-footer-icon.png';
import instagram from '../../assets/icons/instagram-footer-icon.png';
import linkedIn from '../../assets/icons/linkedin-footer-icon.png';
import { Icon } from 'antd';

const excludePages = [
  '/',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/auth/verify',
  '/forgot-password-message',
  '/privacy-and-security',
  '/terms-and-conditions',
  '/dashboard',
  '/get-advice',
  '/top-up',
  '/upgrade-account',
  '/help',
];

const Footer = (props) => {
  const root = document.getElementById('root');
  const body = document.getElementsByTagName('body');

  useLayoutEffect(() => {
    let elfight = document.getElementsByClassName('elfsight-app-b7761913-64ff-40f2-8fdf-0f3111dc672d');

    if (!excludePages.includes(props.location.pathname)) {
      const div = document.createElement('div');
      div.className = 'elfsight-app-b7761913-64ff-40f2-8fdf-0f3111dc672d';
      if (!elfight.length) {
        root.append(div);
      }
    }
  }, [props.location.pathname, body]);

  return (
    <div className={'w-full'}>
      {props.location.pathname === '/profile/me' && (
        <div className={'w-full break-words bg-light-gray pb-5 px-5'}>
          <div className={'container mx-auto md:pt-5 md:px-16'}>
            <Icon type={'lock'} className={'text-xl'} /> - We will only use your contact details to manage and
            communicate with you
            about your account. These fields are not visible to other members.
            <br /><br />
            <p> Please refer to our
              {' '}<a href="/privacy-and-security"
                target="_blank"
                className="text-primary underline md:mb-0 mb-2">Privacy Policy</a>{' '}
              for details on how we
              manage your personal information.</p>
          </div>
        </div>
      )}
      <div className={'bg-light-gray-header w-full py-4 pl-5 md:pl-0 flex items-center'}>
        <div className={'container mx-auto'}>
          <div className={'text-black flex md:flex-row flex-col justify-between'}>
            <a href={'/terms-and-conditions'} target={'_blank'}
              className={'hover:text-event-going md:mb-0 mb-2 terms_and_conditions_tour_class'}>Terms
              and
              conditions
            </a>
            <a href={'/privacy-and-security'} target={'_blank'}
              className={'hover:text-event-going md:mb-0 mb-2 privacy_and_security_tour_class'}>Privacy
              and Security
            </a>
            <a href={'/terms-of-use'} target={'_blank'}
              className={'hover:text-event-going md:mb-0 mb-2 privacy_and_security_tour_class'}>Website terms of Use
            </a>

            <div className={'flex flex-row items-center social_medias_tour_class'}>
              <span className={'mr-3 md:mb-0 mb-2'}>Find us on:</span>
              <a target={'_blank'} href="https://www.facebook.com/ausmumsco/">
                <img className={'h-4 w-auto mr-2'} src={facebook} alt="" />
              </a>
              <a target={'_blank'} href="https://www.instagram.com/mums.and.co/">
                <img className={'h-4 w-auto mr-2'} src={instagram} alt="" />
              </a>
              <a target={'_blank'} href="https://www.linkedin.com/company/mums-&-co/">
                <img className={'h-4 w-auto mr-2'} src={linkedIn} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);
