import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Checkbox, Input, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import withApollo from '../../config/with-apollo';
import { M_CONNECTION_USERS, M_CONNECT_USER, M_MESSAGE, Q_GET_CONNECTED_USER_BY_STATE } from '../../graphql/messages';
import { Q_LIST_MEMBERS } from '../../graphql/user';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import Select from 'react-select';
import { post } from '../../utils/api-handler';

const UserMessageModal = (props) => {
  const { client } = props
  const USER = AUTH();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { selectedUserDataMessage, setSelectedUserDataMessage, introduce } = useContext(PageContext);
  const [sendMessage] = useMutation(M_MESSAGE, { client });
  const [getConnectedUser, { data: getConnectedUserData }] = useLazyQuery(Q_GET_CONNECTED_USER_BY_STATE, { client });
  const [connectUser] = useMutation(M_CONNECT_USER, { client });
  const [createConnectionUsers] = useMutation(M_CONNECTION_USERS, { client });
  const [getAllUsers, { data: userList }] = useLazyQuery(Q_LIST_MEMBERS, { client, fetchPolicy: 'network-only' });
  const [members, setMembers] = useState([]);
  const [name, setName] = useState(null);
  const [sources, setSources] = useState([])

  useEffect(() => {
    (async () => {
      getAllUsers({
        variables: {
          users: [
            USER.id,
          ]
        }
      });

      await getConnectedUser({
        variables: {
          where: {
            user_id: { _eq: USER.id },
            connection_id: { _eq: selectedUserDataMessage.id }
          }
        }
      });
    })();
  }, [USER.id, selectedUserDataMessage.id]);

  const handleConnectUser = async () => {

    try {
      const response = await connectUser({
        variables: {
          objects: [
            {
              user_id: USER.id,
              connection_id: selectedUserDataMessage.id,
              type: introduce ? 'group' : 'single',
              name: name || null
            }
          ],
          messagesUnseenWhere: {
            seen: { _eq: false },
            from: { _neq: USER.id }
          }
        }
      });
      return response.data.insert_connections.returning[0].id;
    } catch (e) {
      console.log(e);
    }
  };

  const handleConnectionUsers = async (connection_id) => {
    const users = [{ value: USER.id }, { value: selectedUserDataMessage.id }];
    await createConnectionUsers({
      variables: {
        objects: [...users, ...members].map((data) => ({ user_id: data.value, connection_id }))
      },
    })
  }

  const handleSendMessage = useCallback(async (event) => {
    event.preventDefault();
    setLoading(true);
    let connectionId = null;

    if (!getConnectedUserData.connections.length || introduce) {
      connectionId = await handleConnectUser();
    } else {
      connectionId = getConnectedUserData.connections[0].id;
    }

    if (introduce) {
      await handleConnectionUsers(connectionId);
    }

    await sendMessage({
      variables: {
        objects: [
          {
            from: USER.id,
            to: introduce ? null : selectedUserDataMessage.id,
            connection_id: connectionId,
            message
          }
        ]
      }
    });

    try {
      if(introduce) {
        let response1 = await post('/new-message-intro', {
          connection_id: connectionId,
          from: USER.id
        });
        console.log(response1)
      } else {
        let response2 = await post('/new-message', {
          to: selectedUserDataMessage.id, 
          from: USER.id
        });
        console.log(response2);
      } 
    } catch (error) {
      console.log(error)
    }

    // await post('/newMessage', {
    //   type: process.env.REACT_APP_EVENT_KEY,
    //   transactionId: eventData.id,
    //   tokens: eventData.cost,
    //   name: eventData.name,
    //   userId: USER_ID,
    //   date,
    //   time,
    //   date_from: `${dateFrom}T${timeFrom}`,
    //   date_to: `${dateTo}T${timeTo}`,
    //   timezone: moment().format('Z'),
    //   discountCode: promoCode,
    //   is_token: isToken
    // });

    setLoading(false);
    setMessage(null);
    setSelectedUserDataMessage({});
  }, [message, USER.id, selectedUserDataMessage.id, getConnectedUserData]);

  const onSelect = (value) => {
    if(value.length <= 10) {
      setMembers(value);
    }
  }

  useEffect(() => {
    if (userList && userList.users && userList.users.length) {
      const members = userList.users.filter(user => user.id !== selectedUserDataMessage.id).map(data => ({ value: data.id, label: data.name }));
      setSources(members)
    }
  }, [userList, selectedUserDataMessage.id])

  return (
    <Modal
      title={<div className='flex items-center flex-row justify-between'>
        <span>{introduce ? 'Introduce' : 'Send message to'} {selectedUserDataMessage.name}</span>
        {/* <div className='flex items-center'>
          <span className='text-xs mr-1'>Refer a member?</span>
          <Checkbox onChange={(event) => setintroduce(event.target.checked)} />
        </div> */}
      </div>}
      visible={!!Object.keys(selectedUserDataMessage).length}
      footer={false}
      closable={false}
      bodyStyle={{ padding: '12px 0px' }}
    >
      <div className={'px-6 mb-3 flex flex-col w-full bottom-0'}>
        {introduce && (
          <>
            <div>
              <Input
                placeholder='Conversation title'
                className='w-full mb-3 text-sm font-normal'
                value={name}
                size='large'
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <Select
                defaultValue={[]}
                isMulti
                name="name"
                className='w-full mb-3 text-sm font-normal'
                classNamePrefix="select"
                options={sources}
                onChange={onSelect}
                placeholder='Introduce to'
                value={members}
              />
            </div>
          </>

        )}
        <div className={'mb-2'}>
          <Input.TextArea onChange={(e) => {
            e.persist();
            setMessage(e.target.value);
          }}
            rows={3}
            maxLength={500}
            value={message}
            className={'message-input border rounded border-light-gray-header w-full text-black font-normal font-sans p-3'}
            style={{ fontSize: '16px' }} />
        </div>
        <p className={'font-thin mb-3'} style={{ fontSize: '0.625rem' }}>{message
          ? 500 - message.length
          : '500'} characters remaining </p>
        <div className={'flex flex-row justify-between'}>
          <button disabled={loading}
            onClick={() => { setMessage(null); setSelectedUserDataMessage({}); }}
            type={'submit'}
            className={`flex self-end  rounded py-2 px-3 text-white bg-red-500`}>
            Cancel
          </button>
          <button disabled={loading || !message}
            onClick={handleSendMessage}
            type={'submit'}
            className={`flex self-end rounded py-2 px-3 text-white ${loading || !message
              ? 'bg-disabled'
              : 'bg-primary'}`}>
            Send
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default withApollo(UserMessageModal);
