import { Form, Input, Select } from 'antd';
import 'antd/es/input/style/css';
import 'antd/es/message/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/select/style/css';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import accountAndBillingIcon from '../../assets/icons/account-billing.png';
import businessBoostIcon from '../../assets/icons/business-boost.png';
import featureIcon from '../../assets/icons/features.png';
import feedbackIcon from '../../assets/icons/feedback.png';
import HelpCard from '../../components/cards/help-card';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import HelpRequestSubmittedModal from '../../components/modals/help-request-submitted';
import withApollo from '../../config/with-apollo';
import { Q_HELP } from '../../graphql/help';
import { PageContext } from '../../helpers/context';
import { baseAxios } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const HelpPage = (props) => {
  const { form: { getFieldDecorator, validateFields, getFieldValue, resetFields } } = props;
  const USER = AUTH();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (error, values) => {
      if (!error) {
        try {
          await baseAxios('POST', process.env.REACT_APP_FRESHDESK_DOMAIN, {
            name: USER.name,
            email: USER.email,
            subject: `${selectedCategory.name}-${values.subject}`,
            description: values.description,
            status: 2,
            priority: 1
          }, {}, {
            headers: {
              'Authorization': 'Basic ' + new Buffer(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`).toString('base64')
            }
          });
          resetFields(['subject', 'description']);
          setSelectedCategory(null);
          setShowModal(!showModal);
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  const handleSelectCategory = (category) => () => {
    setSelectedCategory(category);
    resetFields(['subject']);
  };

  return (
    <PageContext.Provider value={{ showModal, setShowModal }}>
      <HeaderCoverBanner1 title={'Have a Question?'} subtitle={'Let us know how we can help you'} />
      <HeaderCoverBanner1Mobile title={'Have a Question?'} subtitle={'Let us know how we can help you'} />
      <HelpRequestSubmittedModal />
      <div className={'bg-light-gray flex flex-row'}
        style={{ minHeight: 'calc(100vh - 80px)' }}
      >
        <div className={'w-full md:mt-10 container mx-auto md:px-16 pl-12  pt-9 pb-24 pr-4'}>
          <div className={'border-l-2 pl-16 pb-10 relative'} style={{ borderLeftColor: 'rgba(0,0,0,0.2)' }}>
            <div style={{
              width: '40px',
              height: '40px',
              borderRadius: '100%',
              left: '-22px',
              lineHeight: '40px',
            }}
              className="text-center bg-primary text-white text-2xl absolute">
              1
            </div>
            <div className={'w-full'}>
              <p className={'text-2xl mb-.1875 leading-normal tracking-wide font-grumpy text-black flex'}>
                Select a Category
              </p>
              <div className={'md:flex md:flex-row md:flex-wrap items-stretch'}>
                <Query query={Q_HELP}>
                  {({ loading, error, data }) => {
                    if (loading) return <></>;
                    return data.help_categories.map(category => {
                      let icon;

                      switch (category.icon) {
                        case 'account-and-billing':
                          icon = accountAndBillingIcon;
                          break;
                        case 'business-boost':
                          icon = businessBoostIcon;
                          break;
                        case 'features':
                          icon = featureIcon;
                          break;
                        case 'feedback':
                          icon = feedbackIcon;
                          break;
                        default:
                          break;
                      }

                      return <div className="pr-5 pt-5 md:w-1/4 flex-1">
                        <HelpCard onClick={handleSelectCategory(category)}
                          label={category.name}
                          description={category.description}
                          icon={icon}
                          card={{
                            style: !!selectedCategory && selectedCategory.id === category.id
                              ? 'border border-event-going h-full shadow-md'
                              : 'border h-full border-white shadow-md'
                          }}
                        />
                      </div>;
                    });
                  }}
                </Query>
              </div>
            </div>
          </div>
          <div className={'pl-16 relative'}>
            <div style={{
              width: '40px',
              height: '40px',
              borderRadius: '100%',
              left: '-19px',
              lineHeight: '40px',
              backgroundColor: !selectedCategory ? 'rgba(0,0,0,0.2)' : '#00AABB'
            }}
              className={`text-center text-white text-2xl absolute bg-primary`}>
              2
            </div>
            <div className={'w-full'}>
              <p className={'text-2xl leading-normal tracking-wide font-grumpy text-black flex'}>
                Select a reason
              </p>
              <Form onSubmit={handleSubmit} className={'mt-7'}>
                {getFieldDecorator('subject', {})(
                  <Select placeholder={'Select Reason'}
                    className={'w-full'}
                    size={'large'}
                  >
                    {!!selectedCategory && selectedCategory.help_category_reasons.map(value => {
                      return <Select.Option className={'custom-select-option'}
                        key={value.id}
                        value={value.name}>{value.name}</Select.Option>;
                    })}
                  </Select>
                )}
                {getFieldDecorator('description')(
                  <Input.TextArea placeholder={'Description'}
                    style={{ textDecoration: 'none' }}
                    rows={6}
                    maxLength={500}
                    className={'border mt-5 p-3 w-full rounded bg-white custom-focus-hover h-full'}
                  />
                )}
                <p className={'font-thin'} style={{ fontSize: '0.625rem' }}>{getFieldValue(
                  'description') ? 500 - getFieldValue(
                    'description').length : '500'} characters remaining </p>
                <div className={'flex justify-end'}>
                  <button type={'submit'}
                    className={'bg-primary text-white text-sm font-light md:text-base px-4 py-1 md:px-6 md:py-2 rounded active:bg-primary focus:outline-none '}>
                    Submit Request
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default withApollo(Form.create({ name: 'help' })(HelpPage));
