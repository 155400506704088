import { Form, Icon } from 'antd';
import 'antd/es/icon/style/css';
import 'antd/es/input/style/css';
import 'antd/es/notification/style/css';
import 'antd/es/select/style/css';
import React, { useContext } from 'react';
import { compose } from 'react-apollo';
import withApollo from '../../../config/with-apollo';
import { PageContext } from '../../../helpers/context';
import ProfileSettingForm from './profile-setting-form';
import ProfileSettingView from './profile-settings-view';

const ProfileSettingCard = (props) => {
  const { EDIT, currentBusiness, changeBusiness, submitting, submitProfile, editProfile, setBookNow, setRequest  } = useContext(
    PageContext);

  return (
    <div className={'flex flex-col w-full'}>
      <div className={'w-full'}>
        {/*<div className={'bg-light-gray-header'}>*/}
        {/*  {EDIT && (*/}
        {/*    <div className={'container mx-auto flex items-end h-10 px-6'}>*/}
        {/*      <a*/}
        {/*        onClick={changeTab(PROFILE)}*/}
        {/*        className={`rounded-t py-1 px-4 ${currentTab === PROFILE &&*/}
        {/*        'bg-white'} flex justify-center items-center text-black hover:text-black`}>*/}
        {/*        Profile*/}
        {/*      </a>*/}
        {/*      <a*/}
        {/*        onClick={changeTab(SETTINGS)}*/}
        {/*        className={`flex rounded-t text-black py-1 px-4  ${currentTab === SETTINGS &&*/}
        {/*        'bg-white'} justify-center items-center hover:text-black`}>*/}
        {/*        Setting*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        <div className={'mt-6 lg:p-6 lg:pt-14'}> 
          <div className={'flex flex-row flex-wrap pb-6'}>
            <button
              onClick={changeBusiness(0)}
              className={`${currentBusiness === 0
                ? 'bg-black text-white border border-black'
                : 'bg-white border-black border text-black'} mr-6 rounded px-4 py-1 flex items-center focus:outline-none cursor-pointer`}>
              Primary Biz
            </button>
            <button
              onClick={changeBusiness(1)}
              className={`${currentBusiness !== 0
                ? 'bg-black text-white border border-black'
                : 'bg-white border-black border text-black'} rounded px-4 py-1 flex focus:outline-none  items-center cursor-pointer`}>
              Secondary Biz
            </button>
          </div>
          {EDIT ? <ProfileSettingForm /> : <ProfileSettingView />}
        </div>
      </div>
      <div className={`p-2 mt-3 rounded-lg flex justify-end ${!EDIT && 'hidden'}`}>
        <button disabled={submitting}
          onClick={EDIT ? submitProfile : editProfile}
          className={`bg-primary py-2 px-6 rounded text-white focus:outline-none flex items-center
                       ${submitting ? 'bg-disabled' : 'bg-primary'}
                       `}>
          {submitting && EDIT && <Icon type={'loading'} className={'mr-2'} />}{EDIT ? 'Save' : 'Edit'}
        </button>
      </div>
    </div>

  );
};

export default compose(Form.create({ name: 'profile-setting' }), withApollo)(ProfileSettingCard);
