import React from 'react';
import EventCommentDetailsCard from './event-comment-details-card';
import EventCommentForm from './event-comment-form';

const EventCommentCard = () => {
  return <div className={'rounded-lg shadow-md p-4 bg-white'}>
    <p className={'text-lg text-black font-bold'}>Comments</p>
    <EventCommentForm/>
    <EventCommentDetailsCard/>
  </div>;
};

export default EventCommentCard;
