import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { Spin } from 'antd';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import Privacy from './components/footer/privacy';
import Terms from './components/footer/terms';
import TermsPE from './components/footer/terms-pe';
import Usage from './components/footer/usage';
import withApollo from './config/with-apollo';
import { M_USER_TOURS, Q_LIST_USERS, Q_NOT_INSERTED_USERS_TOURS, S_USER } from './graphql/user';
import MainLayout from './layouts/main';
import Login from './pages/authentication';
import ForgotPassword from './pages/authentication/forgot-password';
import ForgotPasswordMessage from './pages/authentication/forgot-password-message';
import Register from './pages/authentication/register';
import ResetPassword from './pages/authentication/reset-password';
import Verify from './pages/authentication/verify';
import Dashboard from './pages/dashboard';
import DigitalLibraryPage from './pages/digital-libraries';
import DigitalLibraryDetails from './pages/digital-libraries/details';
import EventPage from './pages/events';
import EventDetailsPage from './pages/events/details';
import ExpertsPage from './pages/experts';
import ExpertsDetails from './pages/experts/details';
// import GetAdvicePage from './pages/get-advice';
import HelpPage from './pages/help';
import MemberDirectory from './pages/member-directory';
import MessagesContainer from './pages/messages/messages-container';
import Payment from './pages/payment/payment';
import SubscriptionPage from './pages/payment/subscription';
import TopUpPage from './pages/payment/top-up';
import PerksPage from './pages/perks';
import PerksDetails from './pages/perks/details';
import ProfileInformation from './pages/profile/information';
import UserSubscriptionPage from './pages/profile/subscription';
import TokenHistory from './pages/profile/token-history';

import PublicEventPage from './pages/public-events';
import Verification from './pages/dashboard/verification';
import { AUTH, USER_DEACTIVE } from './utils/common';
ReactGA.initialize(process.env.REACT_APP_GA_KEY || 'G-EVBENBB3GK');
const RouterProvider = ({ client, history, children, location }) => {
  const CURRENT_URL = location.pathname.split('/')[1];
  const USER = AUTH();
  const [finalRoute, setFinalRoute] = useState(false);
  const { loading, data } = useSubscription(S_USER, { client, variables: { id: USER.id } });
  const { data: tourData } = useSubscription(Q_NOT_INSERTED_USERS_TOURS, { client });
  const [getAllUsers, { data: userIdsList }] = useLazyQuery(Q_LIST_USERS,
    { client, fetchPolicy: 'network-only' });

  useEffect(() => {
    let hasRedirected = Boolean(sessionStorage.getItem('mumsnco-init'));
    if (!loading) {
      if (data.users_by_pk.status === 'PENDING') {
        return history.push('/verification')
      }

      if (CURRENT_URL === 'upgrade-account' && !['SIGN_UP', 'FREE'].includes(data.users_by_pk.subscription_type)) {
        return history.goBack()
      }
    }

    if (!hasRedirected) {
      if (!loading) {
        sessionStorage.setItem('mumsnco-init', 'true');
        if (data.users_by_pk.status === USER_DEACTIVE) {
          return history.push('/help');
        }
        setFinalRoute(true);
      }
    } else {
      if (!loading) {
        if (data.users_by_pk.status === USER_DEACTIVE && CURRENT_URL !== 'help') {
          return history.push('/help');
        }
        setFinalRoute(true);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (tourData && tourData.tour && tourData.tour.length) {
      getAllUsers();
    }
  }, [tourData])

  useEffect(() => {
    if (userIdsList && userIdsList.users && userIdsList.users.length) {
      const insertData = userIdsList.users.map(item => ({
        user_id: item.id,
        tour_id: tourData.tour[0].id,
        has_read: false
      }));
      client.mutate({
        mutation: M_USER_TOURS,
        variables: {
          objects: insertData,
          update_tour_id: tourData.tour[0].id
        }
      });
    }
  }, [userIdsList])

  if (!loading && finalRoute) {
    return <>{children}</>;
  }

  return <div className={'w-full flex justify-center items-center h-full flex-col'}>
    <Spin size={'large'}/>
    <p>Please wait...</p>
  </div>;
};

const authMiddleware = (Page) => {
  const Wrapper = compose(
    withApollo,
    withRouter
  )(RouterProvider);
  return <Wrapper>{Page}</Wrapper>;
};

const checkAuth = (Page) => {
  if (!sessionStorage.getItem('mumsnco-returning-url')) {
    sessionStorage.setItem('mumsnco-returning-url', window.location.href);
  }
  return () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
    if (token) {
      const body = jwtDecode(JSON.parse(token).token);
      // Add expiration
      // if (Date.now() >= body.iat * 1000) {
      //     return window.location = '/';
      // }
      return authMiddleware(Page);
    }

    window.location = '/';
  };
};


const checkLogin = (Page) => {
  return () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
    if (token) {
      window.location = process.env.REACT_APP_DEFAULT_HOME || '/dashboard';
      return;
    }
    return Page;
  };
};

function App() {
  return <BrowserRouter>
    <Switch>

      <Route exact path={'/'} component={checkLogin(<Login/>)}/>
      <Route exact path={'/register'} component={checkLogin(<Register/>)}/>
      <Route exact path={'/reset-password'} component={ResetPassword}/>
      <Route exact path={'/auth/verify'} component={Verify}/>
      <Route exact path={'/forgot-password'} component={checkLogin(<ForgotPassword/>)}/>
      <Route exact path={'/forgot-password-message'}
             component={checkLogin(<ForgotPasswordMessage/>)}/>
             
      <Route exact strict path={'/public-events/:id'} component={PublicEventPage}/>
      <Route exact path={'/privacy-and-security'} component={Privacy}/>
      <Route exact path={'/terms-and-conditions'} component={Terms}/>
      <Route exact path={'/terms-and-conditions-pe'} component={TermsPE}/>
      <Route exact path={'/terms-of-use'} component={Usage}/>
      <Route exact path={'/payment'} component={checkAuth(<Payment/>)}/>
      <MainLayout>
        <Route exact path={'/dashboard'} component={checkAuth(<Dashboard/>)}/>

        <Route exact path={'/help'} component={checkAuth(<HelpPage/>)}/>
          
        <Route exact path={'/events'} component={checkAuth(<EventPage/>)}/>
        <Route exact strict path={'/events/:id'} component={checkAuth(<EventDetailsPage/>)}/>

        <Route exact path={'/offers'} component={checkAuth(<PerksPage/>)}/>
        <Route exact strict path={'/offers/:id'} component={checkAuth(<PerksDetails/>)}/>

        <Route exact path={'/member-directory'} component={checkAuth(<MemberDirectory/>)}/>
        <Route exact path={'/member-directory/:id'} component={checkAuth(<MemberDirectory/>)}/>

        <Route exact path={'/digital-library'} component={checkAuth(<DigitalLibraryPage/>)}/>
        <Route exact path={'/digital-library/:id'} component={checkAuth(<DigitalLibraryDetails/>)}/>

        <Route exact path={'/get-advice'} component={checkAuth(<ExpertsPage/>)}/>
        <Route exact path={'/get-advice/:id'} component={checkAuth(<ExpertsDetails/>)}/>

        <Route exact path={'/profile/token-history'} component={checkAuth(<TokenHistory/>)}/>
        <Route exact path={'/profile/settings'} component={checkAuth(<ProfileInformation/>)}/>
        <Route exact path={'/profile/me'} component={checkAuth(<ProfileInformation/>)}/>

        <Route exact path={'/upgrade-account'} component={checkAuth(<SubscriptionPage/>)}/>
        <Route exact path={'/top-up'} component={checkAuth(<TopUpPage/>)}/>

        <Route exact path={'/messages'} component={checkAuth(<MessagesContainer/>)}/>
        <Route exact path={'/user-subscription'} component={checkAuth(<UserSubscriptionPage/>)}/>

        <Route exact path={'/verification'} component={Verification}/>
        {/*<Route exact path={'/get-advice'} component={checkAuth(<GetAdvicePage/>)}/>*/}
      </MainLayout>
    </Switch>
  </BrowserRouter>;
}

export default App;

