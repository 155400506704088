import { Form, Input, Rate } from 'antd';
import 'antd/es/icon/style/css';
import 'antd/es/rate/style/css';
import 'antd/es/tooltip/style/css';
import React, { useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../config/with-apollo';
import { M_EXPERT_LISTING_REVIEW } from '../../graphql/experts';
import { AUTH } from '../../utils/common';

const ExpertCommentForm = (props) => {
  const { form: { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue }, client, match } = props;
  const USER = AUTH();
  const ID = match.params.id;
  
  const [errors, setErrors] = useState({});
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields(async (error, values) => {
      if (error) {
        setErrors(error);
      }
      
      if (!error) {
        if (values.rating === 0) {
          setErrors({ rating: true });
          return;
        }
        try {
          await client.mutate({
            mutation: M_EXPERT_LISTING_REVIEW,
            variables: {
              objects: [
                {
                  comment: values.review,
                  rating: values.rating,
                  user_id: USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'],
                  expert_listing_id: ID
                }
              ]
            }
          });
        } catch (e) {
          console.log(e.response);
        } finally {
          resetFields(['review']);
          setFieldsValue({ rating: 0 });
          setErrors([]);
        }
      }
    });
  };
  
  return <Form className={'mb-8'} onSubmit={handleSubmit}>
    <div className={'mt-3'}>
      <ul className={'list-disc list-inside'}>
        {!!errors.length && errors.map(value => <li className={'text-red-500 text-sm'}>{value}</li>)}
      </ul>
    </div>
    <div className={'mt-5 flex flex-row'}>
      {getFieldDecorator('rating', { rules: [{ required: true }] })(<Rate
        allowClear
        allowHalf
        className={'text-primary text-3xl'}/>)}
      {!!errors.rating && <p className={'text-xs text-red-500 pl-2 flex self-center'}>* Required</p>}
    </div>
    <div className={'mt-5 '}>
      <p className={'text-red-500 text-xs'}>{errors.review ? errors.review.errors[0].message : ''}</p>
      {getFieldDecorator('review',
        {
          rules: [
            { required: true, message: '* Required' },
            { min: 50, message: '* 50 characters minimum' }
          ]
        })(
        <Input.TextArea placeholder={'Description'}
          rows={5}
          maxLength={500}
          minLength={50}
          style={{ borderWidth: '1px', borderColor: !!errors.review ? '#F56565' : '#CBD5E0' }}
          className={`border h-35 p-3 w-full rounded bg-white custom-focus-hover`}
        />
      )}
      <p className={'font-light'} style={{ fontSize: '0.625rem' }}>{getFieldValue(
        'review') ? 500 - getFieldValue(
        'review').length : '500'} characters remaining </p>
    </div>
    <div className={'flex justify-end'}>
      <button type={'submit'}
        className={'bg-primary text-white px-4 py-2 text-sm rounded active:bg-primary focus:outline-none '}>
        Post
      </button>
    </div>
  </Form>;
};

export default compose(Form.create({ name: 'comment-form' }), withApollo, withRouter)(ExpertCommentForm);
