import { Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { PageContext } from '../../helpers/context';
import { baseAxios } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const ExpertSuggestionForm = (props) => {
  const { getFieldDecorator, getFieldValue, validateFields, resetFields } = props.form;
  const USER = AUTH();
  const {
    setShowSuccessModal,
    showSuggestionFormModal,
    setShowSuggestionFormModal,
    setCurrentResource
  } = useContext(PageContext);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    validateFields(async (error, values) => {
      if (!error && values.description) {
        try {
          await baseAxios('POST', process.env.REACT_APP_FRESHDESK_DOMAIN, {
            name: USER.name,
            email: USER.email,
            subject: `Suggest an expert inquiry`,
            description: values.description,
            status: 2,
            priority: 1
          }, {}, {
            headers: {
              'Authorization': 'Basic ' + new Buffer(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`).toString('base64')
            }
          });

          resetFields(['description']);
          setCurrentResource({});
          setShowSuggestionFormModal(false);
          setShowSuccessModal(true);
        } catch (e) {
          console.log(e);
        }
      }
    });

  };

  return <Modal visible={showSuggestionFormModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={false}
    onCancel={() => { setShowSuggestionFormModal(false); }}
    destroyOnClose={true}
  >
    <Form onSubmit={handleOnSubmit}>
      <div className={'text-lg font-grumpy text-black'}>What are your high level queries?</div>
      <p className={'text-xs'}>
        Providing this information will enable us to determine the right expert to meet your needs.
      </p>
      {getFieldDecorator('description')(
        <Input.TextArea placeholder={'Description'}
          style={{ textDecoration: 'none' }}
          rows={6}
          maxLength={500}
          className={'border mt-5 p-3 w-full rounded bg-white custom-focus-hover h-full'}
        />
      )}
      <p className={'font-thin mb-3'} style={{ fontSize: '0.625rem' }}>{getFieldValue(
        'description') ? 500 - getFieldValue(
          'description').length : '500'} characters remaining </p>
      <div className={'flex justify-end mb-3'}>
        <button type={'submit'}
          className={'bg-primary text-white text-sm font-light md:text-base px-4 py-1 md:px-6 md:py-2 rounded active:bg-primary focus:outline-none '}>
          Submit Request
        </button>
      </div>
      <div className={'flex items-center justify-center text-sm'}>
        Note: There will be no tokens deducted to submit this request
      </div>
    </Form>
  </Modal>;
};

export default Form.create({ name: 'form' })(ExpertSuggestionForm);
