import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import UserModal from '../../components/modals/user-modal';
import withApollo from '../../config/with-apollo';
import { Q_USER_BUSINESS_GOALS_TAGS } from '../../graphql/business-goals';
import { S_MEMBER_DIRECTORIES } from '../../graphql/member-directories';
import { AppContext, PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import UserMessageModal from './message-modal';
import MemberDirectoryPresentation from './presentation';

const MemberDirectory = (props) => {
  const USER = AUTH();
  const [submitting, setSubmitting] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedUserDataMessage, setSelectedUserDataMessage] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [filter, setFilter] = useState({
    category_id: null,
    offering: 'Product and Services',
    pillar: 'All',
    category: null,
    keyword: null,
    tags: []
  });
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [introText, setIntroText] = useState(null);
  const [introduce, setIntroduce] = useState(false)
  const {
    user_name,
    user_avatar,
    user_completion,
    user_plan,
    user_businesses,
  } = useContext(AppContext);
  const { data: userBusinessGoals } = useQuery(Q_USER_BUSINESS_GOALS_TAGS, { client: props.client, variables: { userId: USER.id } });
  const currentURL = window.location.pathname;
  const urlId = currentURL.substring(currentURL.lastIndexOf('/') + 1)

  useEffect(() => {
    if (urlId.length === 36) {
      setSelectedId(urlId);
      setSelectedUserData(props);
      setShowUserModal(true);
    } else {
      
    }
  }, [urlId]);

  useEffect(() => {
    // Populate user goals tags for filter
    if (!userBusinessGoals) return;

    const userTags = userBusinessGoals.user_business_goals_tags || [];
    setFilter(state => ({ ...state, tags: userTags.map(tags => tags.business_goals_tag.id) }))
  }, [userBusinessGoals]);

  const handleGoToPage = (page) => () => {
    localStorage.removeItem('@return-url')
    props.history.push(page);
    window.scrollTo(0, 0);
  };

  const handleSelectUser = async (props) => {
    setSelectedId(props.id);
    setSelectedUserData(props);
    setShowUserModal(true);
  };

  const handleSelectUserMessage = (data, type = false) => {
    setIntroduce(type);
    setSelectedUserDataMessage(data);
  };

  const handleCloseUser = () => {
    setSelectedId(null);
    setSelectedUserData(null);
    setShowUserModal(false);
    if(urlId.length === 36){
      return props.history.push('/member-directory');
    };
  };

  const handleSetSearch = (key, value) => {
    setFilter((oldState) => ({ ...oldState, [key]: value }));
  };

  const handleResetSearch = () => {
    setFilter({
      type: 'Product and Services',
      keyword: '',
    });
    setData([]);
    setIntroText(0);
  };

  const [loadMembersList, {
    data: usersList, loading: loadingUsersList
  }] = useLazyQuery(S_MEMBER_DIRECTORIES, { fetchPolicy: 'no-cache', client: props.client });

  useEffect(() => {
    handleSearch();
  }, [])

  const handleSearch = () => {
    try {
      setSubmitting(true);
      let users_where = {
        "_and": [
          { "subscription_type": { "_neq": "SIGN_UP" } },
          { "subscription_type": { "_neq": "FREE" } }
        ]
      };


      if (filter.keyword && filter.keyword.trim()) {
        users_where = {
        _and : [
          {
            _or: [
              { name: { _ilike: `%${filter.keyword.trim()}%` } },
              { about_me: { _ilike: `%${filter.keyword.trim()}%` } },
              { username: { _ilike: `%${filter.keyword.trim()}%` } },
              { businesses: { about: { _ilike: `%${filter.keyword.trim()}%` } } },
              { user_business_goals_tags: { business_goals_tag: { name: { _ilike: `%${filter.keyword.trim()}%` } }, private: { _eq: false } } }
            ]
          },
          { subscription_type: { _neq: "SIGN_UP" } },
          { subscription_type: { _neq: "FREE" } }
          ]
      }
    }

      const tagsFilter = {};
      if (filter.is_related) tagsFilter.business_goals_tag_id = { _in: filter.tags };
      if (filter.pillar !== 'All') tagsFilter.pillars_id = { _eq: filter.pillar };
      if (filter.category) tagsFilter.categories_id = { _eq: filter.category };
      users_where.user_business_goals_tags = !isEmpty(tagsFilter) ? tagsFilter : undefined;

      loadMembersList({ variables: { users_where, limit: 20, offset } });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!loadingUsersList && usersList && usersList.users) {
      let data = usersList.users.map((value) => ({
        ...value
      }));

      if (data.length) {
        setIntroText(1);
      } else {
        setIntroText(2);
      }

      const me = [];
      let content = '';
      let ctaMessage = '';
      let ctaAction = () => { };
      let featured;
      let showMe = true;
      if (user_completion < 60) {
        content =
          'Update your profile to increase your chances of being listed in the member directory and expanding your network.';
        featured = user_plan === 'ACCELERATE'; // TODO: (active subscription) only
        ctaMessage = 'Update Profile';
        ctaAction = handleGoToPage('/profile/me');
      } else {
        if (user_plan === 'TRIAL') {
          content =
            'Upgrade now to get your business highlighted. Membership provides loads of other benefits to grow your business too.';
          featured = true;
          ctaMessage = 'Upgrade Now';
          ctaAction = handleGoToPage('/upgrade-account');
        } else {
          showMe = false;
        }
      }

      const businesses =
        user_businesses && user_businesses.length > 0
          ? user_businesses.map((b) => ([{
            about: content,
            name: b.name,
          }]))
          : [{ about: content }];
      if (showMe) {
        me.push({
          name: user_name,
          avatar: typeof user_avatar !== 'function' && user_avatar,
          featured,
          businesses,
          greyscale: true,
          ctaMessage,
          ctaAction,
        });
      }

      // data = [...data.slice(0, 5), ...me, ...(usersList.length > 5 ? data.slice(usersList.length - 5) : [])];

      setData(state => {
        const newState = state.concat(data);
        return newState;
      });
      setSubmitting(false);
    }
  }, [loadingUsersList]);

  useEffect(() => {
    setOffset(0);
    setData([]);
    handleSearch();
  }, [filter]);

  const handleChangeFilter = (id) => () => {
    setFilter(oldState => ({ ...oldState, category_id: id }));
  };

  useEffect(() => {
    handleSearch();
  }, [offset]);

  return (
    <PageContext.Provider
      value={{
        handleSearch,
        handleSetSearch,
        data,
        showUserModal,
        setShowUserModal,
        selectedId,
        handleSelectUser,
        handleCloseUser,
        submitting,
        selectedUserData,
        handleSelectUserMessage,
        introduce,
        setIntroduce,
        selectedUserDataMessage,
        setSelectedUserDataMessage,
        handleResetSearch,
        introText,
        filter,
        setFilter,
        handleChangeFilter,
        offset,
        setOffset,
        loadingUsersList,
        urlId
      }}
    >
      <UserModal source="MD" hideMessageButton={true} />
      <UserMessageModal />
      <MemberDirectoryPresentation />
    </PageContext.Provider>
  );
};

export default compose(
  withApollo,
  withRouter,
)(MemberDirectory);
