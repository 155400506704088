import gql from 'graphql-tag';

export const Q_PILLARS = gql`
  query getPillars {
    pillars {
        id
        name
        color
    }
  }
`;

export const Q_USER_BUSINESS_GOALS_TAGS = gql`
    query getUserBusinessGoalsTags($userId: uuid){
        user_business_goals_tags(
            where: {
                user_id: {_eq: $userId}
            }
        ) {
            user_id
            private
            business_goals_tag {
                id
                name
                categories_id
                pillar_id
                pillar {
                    color
                    id
                }
            }
            category {
                id
                name
            }
        }
    }
`;

export const S_BUSINESS_GOALS_TAGS = gql`
    query getBusinessGoalsTags{
        business_goals_tags {
            id
            name
            categories_id
            pillar_id
            pillar {
                color
                id
            }
            category {
                id
                name
            }
        }
    }
`;

export const M_USER_BUSINESS_GOALS_TAGS = gql`
  mutation userBusinessGoalsTagsInsert($objects: [user_business_goals_tags_insert_input!]!, $deleteUserBusinessGoalsTags: [uuid!]!) {
    insert_user_business_goals_tags(objects: $objects) {
      returning {
        id
      }
    }
    delete_user_business_goals_tags(where: {business_goals_tag_id: {_in: $deleteUserBusinessGoalsTags}}){
      returning{
        id
      }
    }
  }
`;

export const S_CATEGORIES = gql`
    subscription categories{
        categories(order_by: {name: asc}){
            id
            name
        }
    }
`;