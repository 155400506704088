import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import mumsAndCoLogoWhite from '../../assets/icons/mums_and_co_logo.png';
import { css } from '@emotion/react';

const { Header } = Layout;

const PublicHeader = () => {
  return (
    <Header style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.25)"
    }} className='px-13 py-5 md:px-20 bg-primary h-20'>
      <div className='flex justify-between align-middle'>
        <div>
          <img src={mumsAndCoLogoWhite} alt="Logo" className='w-24 h-auto' />
        </div>
        <div className='flex'>
          <p className='invisible md:visible text-sm font-normal text-white font-sans mr-3 mt-2'>Already have an account?</p>
          <Link to={'/'}>
            <Button className='bg-transparent text-lg text-white font-semibold py-1 px-5 border-2 border-white hover:text-primary hover:border-transparent rounded-lg font-grumpy tracking-wide'>Login</Button>
          </Link>
        </div>
      </div>
    </Header>
  );
}

export default withRouter(PublicHeader);