import { Icon, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { compose } from 'react-apollo';
import cancelBookingConfirmation from '../../assets/icons/booking-cancel-confirmation-coin-icon.png';
import withApollo from '../../config/with-apollo';
import { M_EVENT_ATTENDEE_CANCEL } from '../../graphql/events';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';

const EventBookingCancel = (props) => {
  const { client } = props;
  const { event, showCancelModal, setShowCancelModal } = useContext(PageContext);
  let eventData = (props.data || event);
  const USER = AUTH();
  const USER_ID = USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'];
  const [submitting, setSubmitting] = useState(false);
  
  const handleCancelGoing = (id) => async () => {
    setSubmitting(true);
    try {
      await client.mutate({
        mutation: M_EVENT_ATTENDEE_CANCEL,
        variables: {
          where: {
            event_id: { _eq: id },
            user_id: { _eq: USER_ID }
          },
          set: { status: 2 }
        }
      });
      
      setShowCancelModal(!showCancelModal);
    } catch (e) {
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <Modal visible={showCancelModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={'flex flex-col '}>
        <img src={cancelBookingConfirmation} alt={''} className={'self-center w-24 mb-6'}/>
        <p className={'self-center text-3xl text-black'}>Cancel Confirmation</p>
        <p className={'self-center text-lg mb-5 text-center'}>Are you sure you would like to cancel the RSVP?</p>
        <div className={'flex flex-row w-full justify-center'}>
          <button type={'submit'}
            onClick={() => {setShowCancelModal(!showCancelModal);}}
            className={'self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none '}>
            Cancel
          </button>
          <button type={'submit'}
            disabled={submitting}
            onClick={handleCancelGoing(eventData.id)}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${submitting
              ? 'bg-disabled'
              : 'bg-primary'}`}>
            {submitting && <Icon type={'loading'} className={'mr-2'}/>}
            Confirm
          </button>
        </div>
        <div className={'mt-2 flex self-center'}>
          <p>Note : Token refunds are at Mums & Co’s sole discretion.</p>
        </div>
      </div>
    </Modal>
  );
};

export default compose(withApollo)(EventBookingCancel);
