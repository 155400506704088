import { Spin } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { Subscription } from 'react-apollo';
import PerkCard from '../../components/cards/perk-card';
import withApollo from '../../config/with-apollo';
import { S_PERKS } from '../../graphql/perks';
import { PageContext } from '../../helpers/context';
import { USER_TRIAL } from '../../utils/common';

const PerkSubscription = () => {
  const { filter, user_plan } = useContext(PageContext);
  
  const checkBetweenDates = (start, end) => {
    return moment().isBetween(moment(moment(start).startOf('day')).format(), moment(moment(end).endOf('day')).format());
  };
  
  return <Subscription subscription={S_PERKS}
    variables={{
      categoryId: filter.perk_category_id
    }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <div className={'w-full flex justify-center items-center h-full flex-col'}>
            <Spin size={'large'}/>
            <p>Getting perks</p>
          </div>
        );
      }
      
      return data.perks.map(perk => {
        if (checkBetweenDates(perk.start_date, perk.end_date) && perk.is_featured && user_plan !== USER_TRIAL) {
          return <div className={'mb-6 md:pr-10 sm:w-full md:w-1/2 lg:w-1/2 xl:w-4/12'}>
            <PerkCard data={perk}/>
          </div>;
        }
        
        if (checkBetweenDates(perk.start_date, perk.end_date) && !perk.is_featured) {
          return <div className={'mb-6 md:pr-10 sm:w-full md:w-1/2 lg:w-1/2 xl:w-4/12'}>
            <PerkCard data={perk}/>
          </div>;
        }
      });
    }}
  </Subscription>;
};

export default withApollo(PerkSubscription);
