import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./tailwind.css";
import { get } from "./utils/api-handler";
import { AUTH } from "./utils/common";
import apolloClient from "./config/apollo-client";
import { M_INSERT_USER_ACTIVITY, M_USER_ACTIVITY } from "./graphql/user";

if (process.env.REACT_APP_LOCAL === "false") {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
  setupLogRocketReact(LogRocket);
}

let checkVersion = async () => {
  
  let response = await get("/version");
  console.log("server version: ", response.data.version);
  console.log("app version: ", process.env.REACT_APP_VERSION);
  if (response.data.version !== process.env.REACT_APP_VERSION) {
    caches.keys().then(function (names) {
      for (let name of names) {
        caches.delete(name);
      }
      window.location.reload(true);
    });
  }


  try {
    const USER = AUTH();
    const USER_ID = USER.hasura['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    const r = await window.fetch('https://api.ipify.org/?format=json');
    const j = await r.json();
    const currentURL = window.location.pathname;
    const page_accessed = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    const userActivity = {
      user_id: USER_ID,
      is_mobile: navigator.userAgentData.mobile,
      user_agent: navigator.userAgent,
      ip_address: j.ip,
      last_page_accessed: page_accessed
    }
    const client = apolloClient();
    
    try {
      await client.mutate({
        mutation: M_INSERT_USER_ACTIVITY,
        variables: {
            objects: [userActivity]
        }
      });
    }catch(e){
    }
    
    await client.mutate({
      mutation: M_USER_ACTIVITY,
      variables: {
        user_id: USER_ID,
        _set: {
          is_mobile: navigator.userAgentData.mobile,
          user_agent: navigator.userAgent,
          ip_address: j.ip,
          last_page_accessed: page_accessed
        },
      },
    });
  } catch(e){
  }

};
window.addEventListener("focus", () => {
  checkVersion();
});
checkVersion();

const tagManagerArgs = {
  gtmId: "GTM-MNHW6C2",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
