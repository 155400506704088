import { Form, Icon, Input, notification, Switch } from 'antd';
import 'antd/es/switch/style/css';
import React, { useContext, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import inputIconError from '../../../assets/icons/input-icon-error.png';
import withApollo from '../../../config/with-apollo';
import { M_USER_PROFILE } from '../../../graphql/user';
import { AppContext, PageContext } from '../../../helpers/context';
import { get, post } from '../../../utils/api-handler';
import { AUTH } from '../../../utils/common';

const ProfileSettingPassword = (props) => {
  const { user_opt_in_communications, user_opt_in_marketing_membership, user_opt_in_offers_opportunities } = useContext(
    AppContext);
  const { setShowSuccessModal, setShowErrorModal, setShowDeleteModal } = useContext(PageContext);
  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);
  const USER = AUTH();
  const { client, form: { getFieldDecorator, validateFields, getFieldValue, getFieldsError, resetFields } } = props;
  const [error, setError] = useState({
    password: null,
    old_password: null,
    confirm_password: null
  });
  const [submitting, setSubmitting] = useState(false);
  const handleConfirmPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      return callback('Confirm Password does not match.');
    }

    return callback();
  };

  const rules = {
    old_password: [
      { required: true, message: 'Old Password is required' },
      { min: 8, message: 'Please enter at least 8 characters' }
    ],
    password: [
      { required: true, message: 'Password is required' },
      { min: 8, message: 'Please enter at least 8 characters' }
    ],
    confirm_password: [
      { required: true, message: 'Confirm Password is required' },
      { validator: handleConfirmPassword }
    ]
  };

  const handleUpdatePassword = () => {
    let mapErrors = getFieldsError(['password', 'old_password', 'confirm_password']);

    setSubmitting(true);

    validateFields(async (error, values) => {
      Object.keys(mapErrors).map(keys => {
        setError(oldState => ({ ...oldState, [keys]: null }));
      });

      if (!error) {
        try {
          await post('/auth/change-password', {
            userId: USER.id,
            oldPassword: values.old_password,
            password: values.password
          });

          resetFields(['password', 'old_password', 'confirm_password']);
          setShowSuccessModal(true);
        } catch (e) {
          setShowErrorModal(true);
        } finally {
          setSubmitting(false);
        }
      } else {
        Object.keys(mapErrors).map(keys => {
          setError(oldState => ({ ...oldState, [keys]: mapErrors[keys] ? mapErrors[keys][0] : null }));
        });

        setSubmitting(false);
      }
    });
  };

  const handleUpdateOptIn = (column) => async (value) => {
    try {
      await get(`/${value ? 'subscribe' : 'unsubscribe'}/${USER.id}?type=${column}`);

      await client.mutate({
        mutation: M_USER_PROFILE,
        variables: {
          update: {
            constraint: 'users_pkey',
            update_columns: ['id', column]
          },
          object: {
            id: USER.id,
            username: '',
            name: '',
            password: '',
            [column]: value
          }
        }
      });

    } catch (e) {
      notification['error']({
        message: 'Profile Update',
        description:
          'Profile failed to update.',
      });
      console.log(e);
    }
  };

  const handleDeleteAccount = () => {
    setShowDeleteModal(true);
  };

  const DeleteAccountCard = () => {
    return (
      <div className={'w-full bg-white md:rounded-lg p-6  mr-6'}>
        <div className={'text-black font-grumpy text-lg pb-4'}>
          Delete Account
        </div>
        <div className={'pb-4'}>
          <div className={'text-sm'}>
            <p>
              If you want to delete the account because you experienced some issues, please don't hesitate to contact
              us
              via the Help icon so our team can work with you to resolve those issues! If you still want to delete
              your
              account, click on the link below and confirm the action.
            </p>
          </div>
        </div>
        <a onClick={handleDeleteAccount} className={'text-red-600 hover:text-red-600 focus:text-red-600 font-medium'}>
          Delete account
        </a>
      </div>
    );
  };

  return (
    <div className={'flex flex-col w-full'}>
      <div className={'flex md:flex-row flex-col w-full'}>
        <div className={'w-full md:w-2/4 bg-white rounded-lg p-6 mr-6'}>
          <div className={''}>
            <div>
              <div className={'text-black font-grumpy text-lg pb-4'}>
                Change Password
              </div>
              <div className={'flex flex-col w-full pb-4'}>
                <div className="mb-3">
                  {getFieldDecorator('old_password', { rules: rules.old_password })(
                    <Input.Password
                      placeholder={'Old Password'}
                      className={'w-full rounded bg-white profile-input'} />
                  )}
                  {!!error.old_password && (
                    <div className={'flex flex-row mt-2'}>
                      <img src={inputIconError} alt={''} className={'w-auto h-4'} />
                      <p className={'text-xs text-gray-600 font-light mb-1 ml-3'}>{error.old_password}</p>
                    </div>
                  )}
                </div>
                <div className="mb-3 ">
                  {getFieldDecorator('password', { rules: rules.password })(<Input.Password
                    placeholder={'New Password'}
                    className={'w-full rounded bg-white profile-input'} />)}
                  {!!error.password && (
                    <div className={'flex flex-row mt-2'}>
                      <img src={inputIconError} alt={''} className={'w-auto h-4'} />
                      <p className={'text-xs text-gray-600 font-light mb-1 ml-3'}>{error.password}</p>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  {getFieldDecorator('confirm_password', { rules: rules.confirm_password })(<Input.Password
                    placeholder={'Confirm Password'}
                    className={'w-full rounded bg-white profile-input'} />)}
                  {!!error.confirm_password && (
                    <div className={'flex flex-row mt-2'}>
                      <img src={inputIconError} alt={''} className={'w-auto h-4'} />
                      <p className={'text-xs text-gray-600 font-light mb-1 ml-3'}>{error.confirm_password}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className={'flex w-full justify-end'}>
                <button disabled={submitting}
                  onClick={handleUpdatePassword}
                  className={`flex self-center py-1 px-6 rounded text-white focus:outline-none ${submitting
                    ? 'bg-disabled'
                    : 'bg-primary'}`}>
                  {submitting && <Icon type={'loading'} className={'mr-2'} />} Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={'w-full bg-white md:rounded-lg mt-8 md:mt-0 p-6'}>
          <div className={''}>
            <div className={'text-black font-grumpy text-lg pb-4'}>
              Notification Settings
            </div>
            <div className='my-3 text-md'>
              <p><strong>Marketing opt-in</strong></p>
              <p>
                As a Mums & Co member I consent to Mums & Co contacting me regarding my membership and its benefits. I also consent to Mums & Co contacting me regarding:
              </p>
            </div>
            <div className={'flex flex-row items-start'}>
              <Switch defaultChecked={user_opt_in_communications} value={true} className={'mr-4 mt-2'} onChange={handleUpdateOptIn('opt_in_communications')} />
              <div className={'text-sm'}>
                <strong>Mums & Co Membership Communications: </strong>you are automatically subscribed to this list to receive information about your Mums & Co membership and newsletter. You can unsubscribe from this list via the emails you receive but you’ll miss out on communications about your membership.
              </div>
            </div>
            <br></br>
            <div className={'flex flex-row items-start'}>
              <Switch defaultChecked={user_opt_in_marketing_membership} value={true} className={'mr-4 mt-2'} onChange={handleUpdateOptIn('opt_in_marketing_membership')} />
              <div className={'text-sm'}>
                <strong> Mums & Co promotional content such as new membership features and upcoming events:</strong> At times we may launch a new Mums & Co product or event to support your mumbition which we would love to tell you about.
              </div>
            </div>
            <br></br>
            <div className={'flex flex-row items-start'}>
              <Switch defaultChecked={user_opt_in_offers_opportunities} value={true} className={'mr-4 mt-2'} onChange={handleUpdateOptIn('opt_in_offers_opportunities')} />
              <div className={'text-sm'}>
                <strong>Partner offers and opportunities:</strong> We work with a variety of partners who occasionally have offers and opportunities to share with the Mums & Co community.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'pt-8 flex md:flex-row flex-col w-full'}>
        <DeleteAccountCard />
        <div className={'w-full p-6'} />
      </div>
    </div>
  );
};

export default compose(withApollo, Form.create({ name: 'password-form' }), withRouter)(ProfileSettingPassword);
