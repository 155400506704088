import React, { useContext, useState, useEffect, useCallback } from 'react';
import { PageContext } from '../../helpers/context';
import MessagesInbox from './messages-inbox';
import MessagesThread from './messages-thread';
import { isMobile } from 'react-device-detect';
import newMessage from '../../assets/icons/newMessage.png';
import introIcon  from '../../assets/icons/introIcon.png';
import messageIcon  from '../../assets/icons/chatbubble.png';
import chatIcon  from '../../assets/icons/chat.png';
import { Modal, Input } from 'antd';
import Select from 'react-select';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { M_CONNECTION_USERS, M_CONNECT_USER, M_MESSAGE, Q_GET_CONNECTED_USER_BY_STATE } from '../../graphql/messages';
import { Q_LIST_MEMBERS } from '../../graphql/user';
import { AUTH } from '../../utils/common';
import apolloClient from '../../config/apollo-client';
import { post } from '../../utils/api-handler';

const MessagesPresentation = () => {
  const client = apolloClient();
  const { handleSetState, state, handleGetConnection } = useContext(PageContext);
  
  const USER = AUTH();
  const [introduce, setIntroduce] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [selectedUserDataMessage, setSelectedUserDataMessage] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendMessage] = useMutation(M_MESSAGE, { client });
  const [getConnectedUser, { data: getConnectedUserData }] = useLazyQuery(Q_GET_CONNECTED_USER_BY_STATE, { client });
  const [connectUser] = useMutation(M_CONNECT_USER, { client });
  const [createConnectionUsers] = useMutation(M_CONNECTION_USERS, { client });
  const [getAllUsers, { data: userList }] = useLazyQuery(Q_LIST_MEMBERS, { client, fetchPolicy: 'network-only' });
  const [sources, setSources] = useState([]);
  const [members, setMembers] = useState([]);
  const [name, setName] = useState(null);  
  useEffect(() => {
    (async () => {
      getAllUsers({
        variables: {
          users: [
            USER.id,
          ]
        }
      });

      await getConnectedUser({
        variables: {
          where: {
            user_id: { _eq: USER.id },
            connection_id: { _eq: selectedUserDataMessage.value }
          }
        }
      });
    })();
  }, [USER.id, selectedUserDataMessage.value]);

  const handleConnectUser = async () => {

    try {
      const response = await connectUser({
        variables: {
          objects: [
            {
              user_id: USER.id,
              connection_id: selectedUserDataMessage.value,
              type: introduce ? 'group' : 'single',
              name: name || null
            }
          ],
          messagesUnseenWhere: {
            seen: { _eq: false },
            from: { _neq: USER.id }
          }
        }
      });
      return response.data.insert_connections.returning[0].id;
    } catch (e) {
      console.log(e);
    }
  };

  const handleConnectionUsers = async (connection_id) => {
    const users = [{ value: USER.id }, { value: selectedUserDataMessage.value }];
    await createConnectionUsers({
      variables: {
        objects: [...users, ...members].map((data) => ({ user_id: data.value, connection_id }))
      },
    })
  }

  const handleSendMessage = useCallback(async (event) => {
    try{
      event.preventDefault();
    } catch(event){ console.log(event) }

      setLoading(true);
    let connectionId = null;

    if (!getConnectedUserData.connections.length || introduce) {
      connectionId = await handleConnectUser();
    } else {
      connectionId = getConnectedUserData.connections[0].id;
    }

    if (introduce) {
      await handleConnectionUsers(connectionId);
    }

    await sendMessage({
      variables: {
        objects: [
          {
            from: USER.id,
            to: introduce ? null : selectedUserDataMessage.value,
            connection_id: connectionId,
            message
          }
        ]
      }
    });

    try {
      if(introduce) {
        let response1 = await post('/new-message-intro', {
          connection_id: connectionId,
          from: USER.id
        });
        console.log(response1);
      } else {
        let response2 = await post('/new-message', {
          to: selectedUserDataMessage.value, 
          from: USER.id
        });
        console.log(response2)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false);
    setMessage(null);
    setSelectedUserDataMessage({});
    setShowSelectModal(false);
  }, [message, USER.id, getConnectedUserData]);

  const onSelect = (value) => {
    if(value.length <= 10) {
      setMembers(value);
    }
  }

  
  useEffect(() => {
    (async () => {
      getAllUsers({
        variables: {
          users: [
            USER.id,
          ]
        }
      });
    })();
  }, [USER.id]);

  
  const showInbox = () => {
    if (isMobile) {
      return state.currentMessage ? 'md:inline-block hidden' : '';
    }
  };
  
  const showThread = () => {
    if (isMobile) {
      return state.currentMessage ? '' : 'md:inline-block hidden';
    }
  };
  
  const ShowCurrentMessage = () => {
    if (state.currentMessage && isMobile) {
      return (
        <div className={'flex'}>
          <span className=''>  </span>
          <span className={'mr-5 text-red-700'}>
            Close
          </span>
        </div>
      );
    }
    
    return <></>;
  };
  
  const clearCurrentMessage = () => {
    isMobile && handleSetState(oldState => ({ ...oldState, currentMessage: null }));
  };

  useEffect(() => {
    if (userList && userList.users && userList.users.length) {
      const members = userList.users.map(data => ({ value: data.id, label: data.name }));
      setSources(members)
    }
  }, [userList])

  const handleModal = (type) => {
    setShowSelectModal(true);
    setIntroduce(type)
  };

  const handleCancel = () => {
    setShowSelectModal(false);
    setLoading(false);
    setMessage(null);
    setSelectedUserDataMessage({});
  };

  const handleChange = (value) => {
      setSelectedUserDataMessage(value);
  };
  
  return (
    <div className={'bg-light-graypb-10'} style={{ minHeight: '100vh' }}>
      <div className={'md:px-16 container mx-auto'}>
        <Modal
          title={<div className='flex items-center flex-row space-x-2'>
            <img src={introduce ? chatIcon : messageIcon}  className={'w-5 h-auto'} alt={''} />
            <span>{introduce ? 'Introduce' : 'New Message'}</span>
          </div>}
          visible={showSelectModal}
          onCancel={handleCancel}
          footer={false}
          bodyStyle={{ padding: '12px 0px' }}
        >
          <div className={''}>
            <div className={'px-6 mb-3 flex flex-col w-full bottom-0'}>
              <div className='space-x-3 flex'>
                <label htmlFor="user" className='text-base font-medium font-sans pt-1'>Send To:</label>
                <Select
                  id='user'
                  defaultValue={[]}
                  name="name"
                  className='w-1/2 mb-3 text-sm font-normal'
                  classNamePrefix="select"
                  options={sources}
                  onChange={handleChange}
                  placeholder='User'
                  value={selectedUserDataMessage}
                />
              </div>
              {introduce && (
                <>
                  <div>
                    <Input
                      placeholder='Conversation title'
                      className='w-full mb-3 text-sm font-normal'
                      value={name}
                      size='large'
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="name"
                      className='w-full mb-3 text-sm font-normal'
                      classNamePrefix="select"
                      options={sources}
                      onChange={onSelect}
                      placeholder='Introduce to'
                      value={members}
                    />
                  </div>
                </>
              )}
              <div className={'mb-2'}>
                <Input.TextArea onChange={(e) => {
                  e.persist();
                  setMessage(e.target.value);
                }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      event.preventDefault();
                      handleSendMessage(); 
                    }
                  }}
                  rows={3}
                  maxLength={500}
                  value={message}
                  className={'message-input border rounded border-light-gray-header w-full text-black font-normal font-sans p-3'}
                  style={{ fontSize: '16px' }} />
              </div>
              <p className={'font-thin mb-3'} style={{ fontSize: '0.625rem' }}>{message
                ? 500 - message.length
                : '500'} characters remaining </p>
              <div className={'flex flex-row justify-between'}>
                <button disabled={loading}
                  onClick={() => { handleCancel(); }}
                  type={'submit'}
                  className={`flex self-end  rounded py-2 px-3 text-white bg-red-500`}>
                  Cancel
                </button>
                <button disabled={loading || !message}
                  onClick={handleSendMessage}
                  type={'submit'}
                  className={`flex self-end rounded py-2 px-3 text-white ${loading || !message
                    ? 'bg-disabled'
                    : 'bg-primary'}`}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className='flex justify-center md:justify-end space-x-3 lg:mt-3'>
          <button onClick={() => handleModal(false)} className="flex text-white bg-primary space-x-2 focus:ring-4 font-medium rounded-sm text-sm px-3 py-1 md:px-5 md:py-2">
            <img src={newMessage}  className={'w-5 h-auto'} alt={''} />
            <span>New Message</span>
          </button>
          <button onClick={() => handleModal(true)} className="flex text-white bg-primary space-x-2 focus:ring-4 font-medium rounded-sm text-sm px-3 py-1 md:px-5 md:py-2">
            <img src={introIcon} className={'w-5 h-auto'} alt={''}/>
            <span>Introduce</span>
          </button>
        </div>
        <div onClick={clearCurrentMessage}
          className={'pb-5 pt-5 md:pr-0 pl-3 text-base md:text-lg font-semibold text-primary flex justify-between flex-row md:inline-block w-full'}>
            <div className=''>
              <p className=''>Messages</p>
            </div>
          <ShowCurrentMessage/>
        </div>
        
        <div className={'flex flex-row w-full h-144 bg-light-gray border md:rounded-lg overflow-hidden md:shadow-lg'}>
          
          <div className={`w-full md:w-4/12 h-full bg-white overflow-y-scroll ${showInbox()}`}>
            <MessagesInbox/>
          </div>
          <div className={`flex-1 h-full ${showThread()}`}>
            <MessagesThread/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPresentation;
