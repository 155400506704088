import { Checkbox, Form, Icon } from 'antd';
import 'antd/es/checkbox/style/css';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import ReactGA from 'react-ga4';
import { Link, withRouter } from 'react-router-dom';
import coverLogin from '../../assets/icons/cover-login.png';
import inputEmailIcon from '../../assets/icons/input-icon-email.png';
import inputPasswordIcon from '../../assets/icons/input-icon-password.png';
import inputUserIcon from '../../assets/icons/input-icon-user.png';
import mumsAndCoLogoWhite from '../../assets/icons/mums_and_co_logo.png';
import mumsAndCoLogo from '../../assets/icons/mums_and_co_logo_login.png';
import CustomInput from '../../components/common/input';
import { PageContext } from '../../helpers/context';
import { get, post, setHeaders } from '../../utils/api-handler';
import { LOGIN_URL, REGISTER_URL } from '../../utils/url-handler';

const Register = (props) => {
  const { form: { getFieldDecorator, validateFields, getFieldError, getFieldValue, setFields, setFieldsValue }, history, location } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdn.useproof.com/proof.js?acc=V4TEaRYanjdSM1PzhNJov8nKrkA2';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (location && location.search) {
      let search = new URLSearchParams(location.search);

      let name = search.get('name'),
        username = search.get('username'),
        oldsys = search.get('oldsys');

      setFieldsValue({ name, username, oldsys });
    }
  }, []);

  const handleConfirmPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      return callback('Confirm Password does not match.');
    }

    return callback();
  };

  const rules = {
    name: [
      { required: true, message: 'Full Name is required' }
    ],
    username: [
      { required: true, message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email address' }
    ],
    password: [
      { required: true, message: 'Password is required' },
      { min: 8, message: 'Please enter atleast 8 characters' }
    ],
    confirm_password: [
      { required: true, message: 'Confirm Password is required' },
      { validator: handleConfirmPassword }
    ]
  };

  const handleSubmit = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    validateFields(async (errors, values) => {
      try {
        if (!errors) {
          setLoading(true)
          let registerResponse = await post(REGISTER_URL, { ...values });
          await get(`/subscribe/${registerResponse.data.id}?type=opt_in`)

          const mailList = [
            'opt_in_communications'
          ]
          if (values.opt_in_marketing_membership) mailList.push('opt_in_marketing_membership');
          if (values.opt_in_offers_opportunities) mailList.push('opt_in_offers_opportunities');

          const mailRequests = mailList.map(async (mailList) => await get(`/subscribe/${registerResponse.data.id}?type=${mailList}`))
          await Promise.all(mailRequests);

          const response = await post(LOGIN_URL, { username: values.username, password: values.password });
          setHeaders('Authentication', `Bearer ${response.data.token}`);
          localStorage.setItem(process.env.REACT_APP_TOKEN, JSON.stringify(response.data));
          let returningUrl = sessionStorage.getItem('mumsnco-returning-url');

          setLoading(false)

          ReactGA.event({
            category: "General",
            action: "Register new account",
            nonInteraction: true,
          });

          if (returningUrl) {
            window.location = returningUrl;
          } else {
            window.location.href = process.env.REACT_APP_DEFAULT_HOME;
          }

          sessionStorage.removeItem('mumsnco-returning-url');
          //history.push('/');
        }
      } catch (e) {
        setLoading(false)
        if (e.response.status === 500) {
          setFields({ 'username': { errors: ['Something went wrong'] } });
        }

        if (e.response.status === 400) {
          history.push(`/?type=error&email=${values.username}&message=${e.response.data.message}`);
          setFields({ 'username': { errors: [e.response.data.message] } });
        }
        console.log(e.response);
      }
    });
  };

  return <PageContext.Provider value={{}}>
    <div className={'h-full bg-white lg:flex lg:flex-row-reverse'}>
      <div className={'lg:w-3/4 bg-primary justify-between flex flex-col pt-20 lg:pt-0'}>
        <div className={'w-full flex flex-row z-10 lg:inline-flex hidden '}>
          <div className={'md:w-full flex items-center justify-center'}>
            <div className={'text-white text-2xl px-10 xl:px-40 mt-44 flex flex-col font-thin'}>
              <div className={'text-center'}>
                <img alt={''} src={mumsAndCoLogoWhite} className={'m-auto mb-10'}/>
                <div className={'font-grumpy text-3xl mb-6'}>Mums & Co helps women in business grow</div>
                <div className={'text-xl mb-3'}>We advocate for the ambition of Australia's business owning women by providing a digital membership to help you build deep networks, access strategic guidance, resources and support at every stage of business.</div>
                <div className={'text-black font-grumpy bg-white p-1 rounded inline'}>Register FREE Today</div>
              </div>
            </div>
          </div>
        </div>
        <img alt={''} src={coverLogin} className={'w-full h-auto'} />
      </div>
      <div className={'flex flex-col mt-4 px-10 md:px-40 lg:mt-40 lg:px-28 lg:w-3/4 xl:w-1/3'}>
        <Form onSubmit={handleSubmit}>
          <img alt={''} src={mumsAndCoLogo} className={'m-auto mb-10'} />
          {getFieldDecorator('oldsys', {})}
          {getFieldDecorator('name', { rules: rules.name })(
            <CustomInput disabled={loading} error={getFieldError('name')}
              placeholder={'Full Name'}
              name={'name'}
              className={classNames({ shadow: true, 'mb-5': !getFieldError('name') })}
              prefixIcon={inputUserIcon}
              style={{ backgroundSize: '1.25rem' }} />
          )}
          {getFieldDecorator('username', { rules: rules.username })(
            <CustomInput disabled={loading} error={getFieldError('username')}
              placeholder={'Email'}
              name={'username'}
              className={classNames({ shadow: true, 'mb-5': !getFieldError('username') })}
              prefixIcon={inputEmailIcon}
              style={{ backgroundSize: '1.25rem' }} />
          )}
          {getFieldDecorator('password', { rules: rules.password })(
            <CustomInput disabled={loading} error={getFieldError('password')}
              placeholder={'Password'}
              type={'password'}
              name={'password'}
              className={classNames({ shadow: true, 'mb-5': !getFieldError('password') })}
              prefixIcon={inputPasswordIcon}
              style={{ backgroundSize: '1rem', backgroundPosition: '1.15rem' }} />
          )}
          {getFieldDecorator('confirm_password', { rules: rules.confirm_password })(
            <CustomInput disabled={loading} error={getFieldError('confirm_password')} placeholder={'Confirm Password'}
              className={'shadow'}
              type={'password'}
              name={'confirm_password'}
              prefixIcon={inputPasswordIcon}
              style={{ backgroundSize: '1rem', backgroundPosition: '1.15rem' }} />
          )}
          <div>
            <p className='text-xs mt-2'>By registering I consent to Mums & Co contacting me regarding my membership and its benefits. I also consent to Mums & Co contacting me regarding:</p>
            {getFieldDecorator('opt_in_marketing_membership', { initialValue: false })(
              <Checkbox disabled={loading} defaultChecked={false} value={false} className={'text-xs mt-2'}>
                Mums & Co promotional content such as new membership features and upcoming events.
              </Checkbox>
            )}
          </div>
          {getFieldDecorator('opt_in_offers_opportunities', { initialValue: false })(
            <Checkbox disabled={loading} defaultChecked={false} value={false} className={'text-xs mt-2'}>
              Partner offers and opportunities.
            </Checkbox>
          )}
          <button disabled={loading} type={'submit'}
            className={' bg-primary text-white h-10 w-full text-sm mt-5 mb-4 font-grumpy font-light rounded-full focus:outline-none'}>
            {loading ? <>
              <Icon type={'loading'} className={'mr-2'} />
              {'Registering...'}
            </> : 'Register'}
          </button>
          <p className={'text-xs'}>
            By clicking on Register you agree to our {' '}
            <a href={'/terms-and-conditions'}
              target={'_blank'}
              className={'text-primary'}>
              Terms
            </a> {' '} and {' '}
            <a href={'/privacy-and-security'}
              target={'_blank'}
              className={'text-primary'}>
              Privacy
            </a> {' '}
            rules
          </p>
          <div className={'flex justify-between mt-5'}>
            <p className={'text-sm'}>Already a member? {' '}
              <Link to={'/'}>
                <a className={'text-primary cursor-pointer'}>Login</a>
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </div>
  </PageContext.Provider>;
};

export default compose(Form.create({ name: 'register-form' }), withRouter)(Register);
