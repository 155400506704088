import React, { useEffect, useState, useContext } from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../../config/with-apollo';
import { Q_PILLARS } from '../../../graphql/business-goals';
import { PageContext } from '../../../helpers/context';
import { Icon, message, Tooltip } from 'antd';
import addIcon from '../../../assets/icons/add.png';
import deleteIcon from '../../../assets/icons/delete.png';
import publicIcon from '../../../assets/icons/public.png';
import privateIcon from '../../../assets/icons/private.png';
import '../../../assets/styles/business_tags.css';
import 'antd/es/message/style/css';

const BusinessCard = (props) => {
    const { client } = props;
    const {
        EDIT_TAGS,
        businessTags,
        userBusinessTags,
        businessTagsSubmitting,
        pillars,
        setPillars,
        selectedPillar,
        setSelectedPillar,
        editBusinessTags,
        submitUserBusinessTags
    } = useContext(PageContext);
    const { data } = useSubscription(Q_PILLARS, { client });
    const [selectedUserTags, setSelectedUserTags] = useState([]);
    const [userPrivateTags, setUserPrivateTags] = useState([]);
    const [search, setSearch] = useState('');
    const [showErr, setShowErr] = useState(false);

    const onSelectTag = (tag) => () => {
        // check pillar count
        const currentTags = selectedUserTags.filter(tags => tags.pillarId === tag.pillar_id)
        if (currentTags.length === 3) {
            setShowErr(true);

            if (!showErr) {
                message.error({
                    content: 'You can select a maximum of three(3) Focus Areas per Pillar', onClose: () => {
                        setShowErr(false);
                    }
                });
            }
            return;
        }

        setSelectedUserTags(state => {
            const newState = [...state];
            newState.push({ tagId: tag.id, pillarId: tag.pillar.id });
            return newState;
        })
    };

    useEffect(() => {
        if (data && data.pillars) {
            const pillars = data.pillars;
            pillars.unshift({ id: 'All', name: 'All Pillars', color: '4ca6b7' })
            setPillars(pillars);
        }
    }, [data])

    useEffect(() => {
        let currentUserTags = [];
        let privateTags = [];

        if (userBusinessTags && userBusinessTags.length) {
            userBusinessTags.forEach(item => {
                item.tags.forEach(item => {
                    if (item.private) {
                        privateTags.push(item.id);
                    }
                    currentUserTags.push({ tagId: item.id, pillarId: item.pillar_id })
                })
            });
            setSelectedUserTags(currentUserTags);
            setUserPrivateTags(privateTags);
        }
    }, [userBusinessTags])

    const makeTagPrivate = async (isPublic, tagId, pillarId) => {
        let privatePerPillar = 0;
        if (isPublic) {
            await businessTags.forEach(item => {
                item.tags.forEach(tag => {
                    if (userPrivateTags.includes(tag.id) && tag.pillar.id === pillarId) {
                        privatePerPillar++;
                    }
                })
            });
            if (privatePerPillar < 3) {
                setUserPrivateTags(state => {
                    let newState = [...state];
                    newState.push(tagId);
                    return newState;
                })
            }
        } else {
            setUserPrivateTags(state => {
                let newState = [...state];
                newState = state.filter(p => p !== tagId)
                return newState;
            })
        }


    }

    return <div className={'bg-white rounded-lg p-6 w-full break-words mb-5'}>
        <div className={'md:inline-flex p-5 rounded-lg flex flex-row justify-between'} style={{ width: '100%' }}>
            <div className={'flex flex-col'}>
                <h1 class="flex-auto text-lg font-semibold text-slate-900">My Personalised Action Plan</h1>
            </div>
            <div>
                <button disabled={businessTagsSubmitting}
                    onClick={() => EDIT_TAGS ? submitUserBusinessTags(selectedUserTags, userPrivateTags) : editBusinessTags()}
                    className={`bg-primary py-1 px-6 rounded text-sm text-white focus:outline-none flex items-center
                       ${businessTagsSubmitting ? 'bg-disabled' : 'bg-primary'}
                    `}>
                    {businessTagsSubmitting && EDIT_TAGS && <Icon type={'loading'} className={'mr-2'} />}
                    {EDIT_TAGS ? 'Save' : 'Edit Action Plan'}
                </button>
            </div>
        </div>
        {EDIT_TAGS ?  
            <div className='mb-6 px-5'>
                <p className='text-black mb-3'>
                    Think about where you'd like to progress in the next 3-6 months.
                </p>
                <p className='text-black mb-3'>
                    1. Select up to three focus areas from each pillar and we will create a personalised plan for you.
                </p>
                <p className='text-black mb-3'>
                    2. Using the <img
                        className='inline-block'
                        src={publicIcon} alt={'Private'}
                    /> icon, you can make your focus areas private or public (so that other members with similar challenges can connect with you).
                </p>
                <p className='text-black mb-3'>
                    3. You can change your focus areas at any time.
                </p>
            </div> :
            <div className='mb-6 px-5'>
                <p className='text-black mb-3'>
                    Here are your current focus areas. You can edit this at any time to change your personalised action plan.
                </p> 
            </div>}
        <div className={'mb-5'}>
            <div className='pillars-list'>
                <div className={'md:w-2/3 w-full'} style={{ position: 'relative' }}>
                    <ul className={'list-disc list-inside'} style={{ width: (pillars.length * 94) }}>
                        {
                            pillars.map(item =>
                                <li
                                    className="text-sm font-medium mr-2"
                                    key={item.id}
                                    style={{
                                        background: '#' + ((selectedPillar.id || selectedPillar) === item.id ? item.color : 'FFF'),
                                        color: '#' + ((selectedPillar.id || selectedPillar) === item.id ? 'FFF' : item.color),
                                        border: '1px solid #' + item.color
                                    }}

                                    onClick={() => { setSelectedPillar(item) }}
                                >{item.name}</li>)
                        }
                    </ul>
                </div>
                <div className={'md:w-1/3 w-full'}>
                    <input
                        placeholder='Search'
                        className={
                            "h-7.5 border rounded border-light-gray-header  focus:outline-none text-black font-normal font-sans p-3"
                        }
                        style={{ fontSize: "12px" }}
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <ul className={'list-disc list-inside category-list'}>
                {
                    (EDIT_TAGS ? businessTags : userBusinessTags).map(item => {
                        const currentPillarTags = item.tags.filter(item => item.name.toLowerCase().includes(search.toLowerCase()) && (item.pillar.id === selectedPillar.id || selectedPillar.id === 'All'));
                        return (
                            <>
                                {!!currentPillarTags.length &&
                                    <li key={item.category.id}>
                                        <span>{item.category.name}</span>
                                        <ul>
                                            {currentPillarTags.map(tag => {
                                                const isPublic = !userPrivateTags.includes(tag.id);
                                                return (<li
                                                    key={tag.id}
                                                    style={{
                                                        outlineColor: ('#' + tag.pillar.color),
                                                        boxShadow: ('#' + tag.pillar.color + ' 0 0px 8px')
                                                    }}
                                                >
                                                    {tag.name}
                                                    {
                                                        !EDIT_TAGS && !isPublic &&
                                                        <Tooltip placement="top" title={'This focus area is currently set to Private and will not be displayed on your profile'}>
                                                            <img
                                                                src={privateIcon} alt={'Private'}
                                                                className={'self-center w-auto h-4 pr-1'}
                                                                title={"Private"}
                                                                style={{ display: 'inline-block', position: 'absolute', right: 6 }}
                                                            />
                                                        </Tooltip>

                                                    }
                                                    {
                                                        EDIT_TAGS &&
                                                        <div className='tag-actions-box'>
                                                            {
                                                                selectedUserTags.find(data => data.tagId === tag.id) ?
                                                                    <>
                                                                        <Tooltip placement="top" title={isPublic ?
                                                                            'This focus area is currently set to Public and will be visible to other members on your profile' :
                                                                            'This focus area is currently set to Private and will not be displayed on your profile'}>

                                                                            <img
                                                                                src={isPublic ? publicIcon : privateIcon} alt={''}
                                                                                className={'self-center w-auto h-4 pr-1'}
                                                                                onClick={() => makeTagPrivate(isPublic, tag.id, tag.pillar.id)}
                                                                                title={isPublic ? "Public" : "Private"}
                                                                            />
                                                                        </Tooltip>
                                                                        <img
                                                                            src={deleteIcon} alt={''}
                                                                            className={'self-center c-pointer w-auto h-4'}
                                                                            onClick={() => setSelectedUserTags(state => state.filter(data => data.tagId !== tag.id))}
                                                                            title="Delete"
                                                                        />
                                                                    </>
                                                                    :
                                                                    <img
                                                                        src={addIcon} alt={''}
                                                                        className={'self-center w-auto h-4'}
                                                                        onClick={onSelectTag(tag)}
                                                                        title="Add"
                                                                    />
                                                            }
                                                        </div>
                                                    }
                                                </li>)
                                            }
                                            )}
                                        </ul>
                                    </li>
                                }
                            </>
                        )
                    })
                }
            </ul>
        </div>
    </div >
}

export default compose(withRouter, withApollo)(BusinessCard);