import gql from 'graphql-tag';

export const S_USER_PLAN = gql`
    subscription userPlan($id: uuid!){
        user_plans(order_by: {created_at: desc}, where:{user_id: {_eq: $id}}, limit: 1){
            id
            plan_type
            status
        }
    }
`;

export const S_USER = gql`
    subscription user($id: uuid!){
        users_by_pk(id: $id){
            id
            status
            avatar
            active
            stripe_id
            name
            username
            created_at
            number
            address
            about_me
            opt_in
            business_intros
            profile_completion
            subscription_type
            mailchimp_id
            opt_in_communications
            opt_in_marketing_membership
            opt_in_offers_opportunities
            user_business_goals_tags {
                private
                id
                business_goals_tag {
                    id
                    name
                }
                category {
                    id
                    name
                }
                pillar {
                    color
                    id
                    name
                }
            }
            businesses{
                id
                stage
                type
                url
                name
                about
                offering
                industry
                #                business_tags{
                #                    id
                #                    tag
                #                }
            }
            payments_aggregate(order_by: {created_at: desc}, where: {status:{_eq: "SUCCESS"}}){
                aggregate{
                    count
                }
                nodes{
                    id
                    details
                    subscription_id
                    status
                }
            }
            payment_status: payments(limit: 1, order_by:{updated_at:desc}){
                id
                details
                status
            }
            user_plans(limit: 1, order_by: {created_at: desc}){
                id
                plan_type
                expire_at
                canceled_at
                subscription_type
                available_group_expert_sessions
                available_single_expert_sessions
                available_meet_ups
                available_special_events
                subscription_property
                subscriptionPropertyBySubscriptionProperty {
                    billing
                    digital_library
                    earn_tokens
                    group_expert_sessions
                    single_expert_sessions
                    meet_ups
                    member_listing
                    pay_as_you_go
                    select_partner_offers
                    personalized_plan_and_chat
                    podcast_member_feature
                    price
                    special_events
                    subscription
                    subscription_property
                }
            }
            user_current_tokens{
                total
            }
            user_tours {
                id
                tour_id
                has_read
            }
        }
    }
`;

export const QUERY_USER = gql`
    query user($id: uuid!){
        users_by_pk(id: $id){
            id
            status
            avatar
            active
            stripe_id
            name
            username
            created_at
            number
            address
            about_me
            opt_in
            business_intros
            profile_completion
            subscription_type
            mailchimp_id
            opt_in_communications
            opt_in_marketing_membership
            opt_in_offers_opportunities
            user_business_goals_tags(where: {private: {_eq: false}}) {
                private
                id
                business_goals_tag {
                    id
                    name
                }
                category {
                    id
                    name
                }
                pillar {
                    color
                    id
                    name
                }
            }
            businesses(order_by: {order:asc}){
                id
                stage
                type
                url
                name
                about
                offering
                industry
            }
            user_plans(limit: 1, order_by: {created_at: desc}){
                id
                plan_type
                expire_at
                canceled_at
                subscription_type
                subscription_property
            }
        }
    }
`;

export const Q_USER = gql`
    query user($id: uuid!){
        users_by_pk(id: $id){
            id
            status
            avatar
            name
            username
            created_at
            number
            address
            about_me
            subscription_type
            opt_in
            opt_in_communications
            opt_in_marketing_membership
            opt_in_offers_opportunities
            #            user_tags{
            #                id
            #                tag
            #            }
            businesses(order_by: {order:asc}){
                id
                stage
                type
                url
                name
                about
                offering
                industry
            }
            user_plans(limit: 1, order_by: {created_at: desc}){
                id
                plan_type
                expire_at
                subscription_type
            }
            user_business_goals_tags(where: {private: {_eq: false}}) {
                business_goals_tag {
                    name
                    id
                    category {
                        name
                        id
                    }
                    pillar {
                        color
                    }
                }
            }
            user_tours {
                id
                tour_id
                has_read
            }
        }
    }
`;

export const S_USER_TRANSACTIONS = gql`
    subscription userTransactions($where: user_token_transaction_bool_exp){
        user_token_transaction(
            where: $where
        ) {
            id
            transaction
        }
    }
`;

export const S_USER_BUSINESSES = gql`
    subscription userBusinesses($id: uuid!){
        business(where: {user_id: {_eq: $id}}, order_by: {order: asc}){
            id
            stage
            type
            url
            offering
            name
            about
            order
            industry
            #            business_tags{
            #                id
            #                tag
            #            }
        }
    }
`;

export const M_USER_PROFILE = gql`
    mutation userProfile($object: [users_insert_input!]!, $update: users_on_conflict){
        insert_users(objects: $object, on_conflict: $update){
            returning{
                id
            }
        }
    }
`;

export const Q_USER_PAYMENTS = gql`
    query userPayments($where: payments_bool_exp){
        payments(where: $where){
            id
            status
        }
    }
`;

export const Q_INDUSTRIES = gql`
    query industries{
        industries{
            name
        }
    }
`;

export const Q_USER_TOUR = gql`
  query tourQuery($page: String, $user_id: uuid) {
    user_tour(where: {has_read: {_eq: false}, tour: {page_route: {_eq: $page}}, user_id: {_eq: $user_id}}) {
      id
      tour {
        page_route
        tour_steps {
          image
          target
          title
          content
          video_id
        }
      }
      has_read
      user_id
    }
  }
`;

export const Q_NOT_INSERTED_USERS_TOURS = gql`
    query MyQuery {
        tour(where: {inserted_users_tour: {_eq: false}}) {
            id
        }
    }
`;

export const Q_TOURS = gql`
    query MyQuery {
        tour {
            id
        }
    }
`;

export const M_USER_TOURS = gql`
  mutation userTourInsert($objects: [user_tour_insert_input!]!, $update_tour_id: uuid) {
    update_tour(where: {id: {_eq: $update_tour_id}},
    _set:{
        inserted_users_tour: true
    }) {
        affected_rows
    }
    insert_user_tour(objects: $objects) {
        returning {
            id
        }
    }
    
  }
`;

export const M_INSERT_USER_TOURS = gql`
    mutation userTourInsert($objects: [user_tour_insert_input!]!) {
        insert_user_tour(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const Q_LIST_USERS = gql`
    query MyQuery {
        users {
            id
        }
    }
`;

export const Q_LIST_MEMBERS = gql`
    query users($users: [uuid!]) {
        users(where: {id: {_nin: $users}, profile_completion: { _gte: 20 }}, order_by: {avatar: desc_nulls_last, profile_completion: desc}) {
            id
            name
            username
        }
    }
`;

export const M_USER_TOUR_HAS_READ = gql`
    mutation update_user_tour($id: uuid!) {
        update_user_tour(where: {id: {_eq: $id}},
        _set:{
            has_read: true
        }) {
            affected_rows
        }
    }
`;

export const M_USER_PLAN = gql`
    mutation update_user_plans($id: Int!, $_set: user_plans_set_input) {
        update_user_plans(where: {id: {_eq: $id}},
        _set: $_set) {
            affected_rows
        }
    }
`;

export const M_INSERT_USER_ACTIVITY = gql`
    mutation userActivityInsert($objects: [user_activity_insert_input!]!) {
        insert_user_activity(objects: $objects) {
            returning {
                id
            }
        }
    }
`;

export const M_USER_ACTIVITY = gql`
    mutation update_user_activity($user_id: uuid!, $_set: user_activity_set_input) {
        update_user_activity(where: {user_id: {_eq: $user_id}},
        _set: $_set) {
            affected_rows
        }
    }
`;
