import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { memo, useEffect, useMemo, useState } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { M_INSERT_USER_TOURS, M_USER_TOUR_HAS_READ, Q_TOURS, Q_USER, Q_USER_TOUR } from '../../graphql/user';
import TourTooltip from './tourTooltip';

const Tour = (props) => {
    const { client, location, USER, startTour } = props;
    const [getUserTour, { data: tour_data, refetch: refetchTour }] = useLazyQuery(Q_USER_TOUR, { client });
    const { data: user } = useQuery(Q_USER, { client, variables: { id: USER.id } });
    const { data: tours } = useQuery(Q_TOURS, { client });

    const [tourState, setTourState] = useState({
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: [],
        key: new Date()
    });

    const userTours = useMemo(() => tour_data ? tour_data.user_tour : [], [tour_data])

    useEffect(() => {
        (async () => {
            if (!user || !user.users_by_pk || !tours || !(tours.tour || []).length) return;
            // check if has tours
            const userData = user.users_by_pk;
            if (!userData.user_tours.length) {
                const userTour = {
                    user_id: userData.id,
                    tour_id: tours.tour[0].id,
                    has_read: false
                };
                await client.mutate({
                    mutation: M_INSERT_USER_TOURS,
                    variables: {
                        objects: [userTour]
                    }
                });
                const pathName = location.pathname.replace('/', '');
                const m = pathName.indexOf('/');
                const page = pathName.substr(0, m > 0 ? m : pathName.length);
                refetchTour({
                    page,
                    user_id: USER.id
                })
            }
        })()
    }, [user, tours])


    useEffect(() => {
        if (tour_data && tour_data.user_tour && tour_data.user_tour.length) {
            const steps = tour_data.user_tour[0].tour.tour_steps;
            steps[0].disableBeacon = true;
            setTourState(state => ({
                ...state,
                steps
            }))
        }

    }, [(tour_data || {}).user_tour])

    useEffect(() => {
        const pathName = location.pathname.replace('/', '');
        const m = pathName.indexOf('/');
        const page = pathName.substr(0, m > 0 ? m : pathName.length);
        (async () => {
            await getUserTour({
                variables: {
                    page,
                    user_id: USER.id
                }
            });
        })();
    }, [location.pathname])

    const callback = async (data) => {
        const { action, index, type, status } = data;
        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            setTourState(state => ({ ...state, run: false }));
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            setTourState(state => ({
                ...state,
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
            }))
        }

        if (action === ACTIONS.CLOSE) {
            document.body.style.overflow = "scroll";
        }

        // Read only when finished, reset scrolling
        if (status === STATUS.FINISHED) {
            document.body.style.overflow = "scroll";
            await client.mutate({
                mutation: M_USER_TOUR_HAS_READ,
                variables: {
                    id: userTours[0].id
                }
            });
        }
    };

    useEffect(() => {
        if (startTour && tourState && tourState.steps.length) {
            // disable scrolling
            document.body.style.overflow = "hidden";
            setTourState(state => ({
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date(),
            }));
        }
    }, [(tourState || {}).steps, startTour])

    return (
        <>
            {
                (userTours || []).length &&
                <JoyRide
                    {...tourState}
                    spotlightClicks
                    callback={callback}
                    showSkipButton={true}
                    disableScrolling={true}
                    tooltipComponent={TourTooltip}
                />
            }
        </>
    )
}

export default memo(Tour)