import { Tooltip, Divider, Modal, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { get, post} from '../../utils/api-handler';
import { PUBLIC_EVENTS_URL } from '../../utils/url-handler';
import tooltipIcon from '../../assets/icons/tooltip-icon.png';
import moment from 'moment-timezone';
import PublicHeader from '../../components/header/header-public';
import bigCheckIcon from '../../assets/icons/big-check-icon.png';
import calendarIcon from '../../assets/icons/calendar-icon.png';
import locationIcon from '../../assets/icons/location-icon.png';
import Footer from '../../components/footer';

const PublicEventsPage = (props) => {
  const { location } = props;
  
  const [submitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [showDisabled, setShowDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showReserveModal, setShowReserveModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: '',
    email: ''
  });
  const url = window.location.pathname;
  const [counter, setCounter] = useState(1);

  // const incrementCounter = () => {
  //   setCounter(counter + 1);
  // };

  // const decrementCounter = () => {
  //   if (counter !== 0) {
  //     setCounter(counter - 1);
  //   }
  // };

  function urlify(text) {
    const urlRegex = /(href=('|")[^'"]*('|"))|(http[s]?:\/\/[^\s<]+)/g;
    const transformedText = text.replace(urlRegex, function(match, href) {
        // If the match is an anchor tag, return it with added styling
        if (href) {
            return '<a ' + match + ' style="color: #00AABB; text-decoration: underline; cursor: pointer;" onmouseover="this.style.textDecoration=\'none\';" onmouseout="this.style.textDecoration=\'underline\';"';
        } else {
            // Otherwise, wrap the URL in an anchor tag with styling
            return '<a href="' + match + '" target="_blank" rel="noopener noreferrer" style="color: #00AABB; text-decoration: underline; cursor: pointer;" onmouseover="this.style.textDecoration=\'none\';" onmouseout="this.style.textDecoration=\'underline\';">' + match + '</a>';
        }
    });
    return transformedText;
  };

  const extractLastSegment = (url) => {
    const segments = url.split('/');
    return segments[segments.length - 1];
  };
  
  
  const id = extractLastSegment(url); 

  
  const handleSelect = (e) => {
    setDisabled(
      !e.target.checked,
    );
    setShowDisabled(false);
  }

  useEffect(() => {
    const fetchEvent = async() => {
      try {   
        const EVENT_ID_URL = `${PUBLIC_EVENTS_URL}/${id}`;
        let response = await get(EVENT_ID_URL)
        setData(response.data);
        if (response.data.length > 0) {
          const firstRow = response.data[0];
          const stripeLink = firstRow.stripe_link;
  
          setPrice(stripeLink);
        }
      } catch (e) {
        console.error('Error fetching data:', e);
      }
    }

    fetchEvent();
  }, []);
  
  
  useEffect(() => {
    // Check if the URL contains a success parameter
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get('status');

    // Open the modal if the status parameter is 'success'
    if (statusParam === 'success') {
      setShowSuccessModal(true);
    }

  }, []);

  const closeModal = () => {
    setShowSuccessModal(false);
    setShowReserveModal(false);
  };

  const handleReserve = () => {
    setShowReserveModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    console.log(inputValues)
  };

  const handleSubmit = async () => {
    // Create an object with the data to be sent
    // Define the API endpoint URL
    const response = await post(`/create-product-checkout-session/${price}`, {
      redirect: url,
      redirect_success: `${window.location.origin}/public-events/${id}?status=success`,
      redirect_fail: `${window.location.origin}${url || ''}`,
      email: inputValues.email,
      name: inputValues.name,
      eventId: id
    });;


    // Proceed with checkout
    window.open(response.data.sessionURL, "_self")
  };

  const handleDisabled = async () => {
    setShowDisabled(true);
  };

  const formatTimeWithTimezone = (time_from, time_to) => {
    // Helper function to get the timezone abbreviation based on the offset
    const getTimezoneAbbreviation = (offset) => {
      if (offset === "+10") {
        return "AEST";
      } else if (offset === "+11") {
        return "AEDT";
      } else {
        return " ";
      }
    };
    const timezoneOffset = time_from.slice(-3);
    
    
    const timezoneAbbreviation = getTimezoneAbbreviation(timezoneOffset)

    let formattedTimeFrom = moment();
    let formattedTimeTo = moment();
    if(timezoneAbbreviation === "AEDT"){
      formattedTimeFrom = moment(time_from, 'HH:mm:ssZ').tz('Asia/Magadan').format('h:mm A');
      formattedTimeTo = moment(time_to, 'HH:mm:ssZ').tz('Asia/Magadan').format('h:mm A');
    } else {
      formattedTimeFrom = moment(time_from, 'HH:mm:ssZ').tz('Australia/Sydney').format('h:mm A');
      formattedTimeTo = moment(time_to, 'HH:mm:ssZ').tz('Australia/Sydney').format('h:mm A');
    }
  
    return `${formattedTimeFrom} - ${formattedTimeTo} ${timezoneAbbreviation}`
  };


  return <>
  <PublicHeader />
  <div>
    {data.map((row, index) => (
      <>
      <div key={index}>
        <div className='min-h-screen bg-light-gray pb-5'>
          <div className={'w-full'}>
            <div className={'container mx-auto px-5 md:p-10'}>
              <div className={'container mx-auto px-4 md:px-16'}>
                <div className={'flex flex-col md:flex-row md:flex-wrap'}>
                <Modal 
                  visible={showSuccessModal}
                  onCancel={closeModal}
                  okButtonProps={{ disabled: true }}
                  cancelButtonProps={{ disabled: true }}  
                  footer={null}
                  >
                  <div className={'flex flex-col'}>
                    <img src={bigCheckIcon} alt={''} className={'self-center w-24 mb-6'}/>
                    <p className={'self-center text-center text-3xl text-black'}>Event RSVP</p>
                    <p className={'self-center text-center text-lg mb-5'}>
                      You have successfully booked a seat in this event.
                    </p>
                    <button
                      onClick={closeModal}
                      className={'self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none '}>
                      Close
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showReserveModal}
                  onCancel={closeModal}
                  okButtonProps={{ disabled: true }}
                  cancelButtonProps={{ disabled: true }}  
                  footer={null}
	    	  closable={false}>
                  <div className='grid grid-cols-4 col-span-4 gap-2'>
                    <div className="col-span-4 w-full max-w-md p-3 sm:p-4 lg:p-5 ">
                      <div className="space-y-3 lg:space-y-5" >
                      <h5 className="text-2xl font-bold text-black dark:text-white text-center font-sans ">Want to Join?</h5>
                        <div>
                          <label htmlFor="email" className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Email</label>
                          <input type="email" name="email" value={inputValues.email} onChange={handleChange} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-7 text-base" required/>
                        </div>
                        <div>
                          <label htmlFor="name" className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Name</label>
                          <input type="text" name="name" value={inputValues.name} onChange={handleChange} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-7 text-base" required/>
                        </div>
                        <div className='flex justify-center'>
                          <p className={`text-red-600 ${showDisabled ? 'mx-3' : 'hidden'}`}>*</p>
                          <Checkbox checked={!isDisabled} onChange={handleSelect} >Disclaimer about <a className='text-primary underline hover:text-primary hover:underline' rel="noopener noreferrer" target='_blank' href='https://app.mumsandco.com.au/terms-and-conditions-pe'>Terms and Conditions</a></Checkbox>    
                        </div>
                        <div className='text-center'>
                          <button type="submit" onClick={isDisabled ? () => handleDisabled() : () => handleSubmit()} className={`bg-transparent w-1/2 mr-3 text-sm  text-primary font-semibold  py-1 lg:py-3 px-1 border-2  rounded ${isDisabled ? 'border-disabled' : 'border-primary hover:bg-primary hover:text-white hover:border-transparent'
                            }`}>Join as a Guest (${row.stripe_cost})</button>
                          <Link to={{ pathname: `/events/${id}` }}>
                            <button className="bg-primary w-1/3 text-sm hover:text-primary hover:bg-transparent text-white font-semibold py-1 lg:py-3 px-1 border-2 border-transparent hover:border-primary rounded">Join as a Member</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className={'flex md:items-center justify-center text-center w-full md:pt-6 md:pb-6'}>
              <img  src={window.innerWidth >= 768 ? row.banner ? row.banner : row.image : row.image} className='rounded-lg w-full lg:h-84 col-start-7 md:ml-3 lg:ml-0 lg:col-start-6 md:col-span-5 md:order-2 object-fill'></img>
            </div>
            <div className=''>
              <div className='md:flex justify-between'>
                <div className=' w-full my-3 md:my-0 md:w-3/5'>
                  <h5 className='font-grumpy tracking-wider text-4xl'>{row.name}</h5>
                  {/* <span className='mt-2 text-sm'>Hosted by {row.host_id}</span> */}
                </div>
                <div className="w-full md:w-1/3 mb-3 md:mb-0 rounded-lg overflow-hidden border border-gray-300 bg-white">
                  <div className="items-center px-4 py-7">
                    <div className="items-center text-center mb-4">
                      {/* <div className="mb-2 border border-gray-400 rounded-lg p-3 px-3 flex  justify-between">
                        <span className=" font-medium self-center">General Admission </span>
                        <div className=' space-x-2'>
                          <button className="px-2 py-0.5 rounded bg-gray-300" onClick={decrementCounter} disabled>-</button>
                          <span>{counter}</span>
                          <button className="px-1.5 py-0.5 rounded text-white bg-primary" onClick={incrementCounter} disabled>+</button>
                        </div>
                      </div> */}
                      <h2 className='text-lg font-semibold'>From  A${row.stripe_cost}.00</h2>
                    </div>
                    <button onClick={handleReserve} className="bg-primary transform hover:scale-105 transition-all duration-300 ease-in-out text-white font-bold py-2 w-full rounded">
                      Reserve a Spot
                    </button>
                  </div>
                </div>
              </div>
              <div className='mb-4 space-y-1'>
                <h1 className=' tracking-wide font-bold text-lg text-gray-800 mb-2'>Date and time</h1>
                <div className='flex space-x-4'>
                  <div className={'self-center'}>
                    <img src={calendarIcon} className={'w-4 h-auto'} alt={''} />
                  </div>
                  <span className={'text-black font-sans font-normal text-base '}>
                    {moment(row.date_from).isSame(row.date_to) ? (
                      moment(row.date_to).format('LL')
                    ) : (
                      `${moment(row.date_from).format('LL')} - ${moment(row.date_to).format('LL')}`
                    )} . {formatTimeWithTimezone(row.time_from, row.time_to)}
                  </span>
                </div>
              </div>
              <div className='mb-4 space-y-1'>
                <h1 className='tracking-wide font-bold text-lg text-gray-800 mb-2'>Location</h1>
                <div className='flex'>
                  <div className={'items-center self-center mr-4'}>
                      <img src={locationIcon} className={'w-4 h-auto'} alt={''} />
                  </div>
                  <p className='text-black font-sans font-normal text-base'>
                  {row.online ? null : row.location} {!!row.location_notes && (
                    <Tooltip placement="topLeft" title={row.location_notes}>
                      <img src={tooltipIcon} className={'w-4 h-auto inline'} alt={''} />
                    </Tooltip>
                  )}
                  </p>
                    {row.online && (
                      <p className={`text-black font-sans font-normal text-base`}>
                        Microsoft Teams Meeting
                      </p>
                    )}
                    {!row.online &&
                      <a href={`https://www.google.com/maps/search/?api=1&query=${row.location}`}
                        target='_blank'
                        className={'text-primary text-lg hover:text-primary'}>View on map</a>}
                </div>
              </div>
              {/* <div className='mb-7 space-y-1'>
                <h1 className=' tracking-wide font-bold text-lg text-gray-800 mb-2'>Tags</h1>
                <div className='flex space-x-4'>
                  <p>sample tags</p>
                </div>
              </div> */}
              <div className='mb-4 space-y-1'>
                <div className='text-base html-div whitespace-pre-line' dangerouslySetInnerHTML={{ __html: urlify(row.description || 'N/A') }}/>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </>
  ))} 
  </div>
  <Footer />
  </>;
};

export default withRouter(PublicEventsPage);

