import { useSubscription } from '@apollo/react-hooks';
import { Spin } from 'antd';
import 'antd/es/spin/style/css';
import { startCase } from 'lodash';
import LogRocket from 'logrocket';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Footer from '../components/footer';
import PrimaryHeader from '../components/header/header-primary';
import SecondaryHeader from '../components/header/header-secondary';
import HeaderToken from '../components/header/header-token';
import SendVerificationSuccess from '../components/modals/email-verification-confirm';
import ResendConfirmation from '../components/modals/resend-confirmation';
import UpgradeAccountModal from '../components/modals/upgrade-account-modal';
import UserModal from '../components/modals/user-modal';
import Tour from '../components/tour/tour';
import withApollo from '../config/with-apollo';
import { S_USER } from '../graphql/user';
import { AppContext, PageContext } from '../helpers/context';
import { AUTH } from '../utils/common';

const MainLayout = ({ client, children, history, location }) => {
  const USER = AUTH();
  const { loading, error, data } = useSubscription(S_USER, { client, variables: { id: USER.id } });
  let user = loading ? {} : data.users_by_pk;
  let user_membership_expired_at = null;
  let user_subscription_payment_status = null;
  let user_subscription_id = null;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showHighlightModal, setShowHighlightModal] = useState({ show: false, url: null });
  const [startTour, setStartTour] = useState(false);
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get('user_id')) {
      setSelectedId(params.get('user_id'));
      setShowUserModal(true);
    }
  }, []);

  if (typeof user.payments_aggregate !== 'undefined') {
    if (user.payments_aggregate.nodes.length) {
      user_subscription_id = user.payments_aggregate.nodes[0].subscription_id;
      user_subscription_payment_status = (user.payment_status[0] || {}).status;
    } else {
      user_subscription_id = (user.payment_status[0] || {}).subscription_id;
      user_subscription_payment_status = (user.payment_status[0] || {}).status;
      if ((user.payment_status[0] || {}).details) {
        user_membership_expired_at = moment
          .unix((user.payment_status[0] || {}).details.data.object.lines.data[0].period.end)
          .format('YYYY-MM-DD');
      }
    }
  }

  if (process.env.REACT_APP_LOCAL === 'false') {
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.username,
    });
  }

  useEffect(() => {
    if (!user || !Object.keys(user).length || loading) return;
    if (user.status === 'PENDING' && !localStorage.getItem('mums-and-co-has-login')) {
      localStorage.setItem('mums-and-co-has-login', 'true');
      return setShowSuccessModal(true);
    }

    if (!showSuccessModal) setStartTour(true)
  }, [user, loading, showSuccessModal]);

  const profileIconContainer = (classname, options) => {
    return (
      <div className={classname} {...options}>
        {user.name[0].toUpperCase()}
      </div>
    );
  };

  if (error) {
    sessionStorage.removeItem('mumsnco-init');
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    return history.push('/');
  }

  if (typeof user === 'undefined') {
    sessionStorage.removeItem('mumsnco-init');
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    return history.push('/');
  }

  if (loading) {
    return (
      <div className={'w-full h-full flex justify-center items-center'}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const user_plan = user.user_plans[0].plan_type;

  const onClose = () => {
    setShowSuccessModal(!showSuccessModal);
    setTimeout(() => setStartTour(true), 500)
  }

  return (
    <AppContext.Provider
      value={{
        user_id: user.id,
        user_active: user.active,
        user_mailchimp_id: user.mailchimp_id,
        user_plan,
        user_current_plan: user.user_plans[0],
        user_expired_at:
          user_plan === 'TRIAL'
            ? user.user_plans[0].expire_at
            : moment()
              .add(10, 'years')
              .format('YYYY-MM-DD'),
        user_is_expired: false,
        user_membership_expired_at,
        user_subscription_payment_status,
        user_subscription_id,
        user_tokens: user.user_current_tokens ? user.user_current_tokens.total : 0,
        user_status: user.status,
        user_avatar: user.avatar || profileIconContainer,
        stripe_id: user.stripe_id,
        user_name: user.name,
        user_email: user.username,
        user_joined: user.created_at,
        user_payments: user.payments_aggregate,
        user_number: user.number,
        user_address: user.address,
        user_about_me: user.about_me,
        user_completion: user.profile_completion,
        user_opt_in: user.opt_in,
        user_opt_in_communications: user.opt_in_communications,
        user_opt_in_marketing_membership: user.opt_in_marketing_membership,
        user_opt_in_offers_opportunities: user.opt_in_offers_opportunities,
        user_businesses: user.businesses,
        user_business_intros: user.business_intros,
        user_subscription_type: user.subscription_type ? startCase(user.subscription_type.toLowerCase()) : null,
        user_business_goals_tags: user.user_business_goals_tags,
        showSuccessModal,
        setShowSuccessModal,
        showTitle,
        setShowTitle,
        showHighlightModal,
        setShowHighlightModal
      }}
    >
      <Tour startTour={startTour} client={client} location={location} USER={USER} />
      <UpgradeAccountModal
        appContext={true}
        title={'Registration successful'}
        buttonText={'Okay'}
        redirect={onClose}
        tutorialVideo={true}
        client={client}
        subtitle={`We have sent an email to ${user.username}. Click on the verify link in the email.`}
      />

      <SendVerificationSuccess
        appContext={true}
        title={'Registration successful'}
        buttonText={'Okay'}
        redirect={onClose}
        tutorialVideo={true}
        client={client}
        subtitle={`We have sent an email to ${user.username}. Click on the verify link in the email.`}
      />
      <ResendConfirmation
        appContext={true}
        title={''}
        buttonText={'Okay'}
        subtitle={`We have sent an email to ${user.username}. Click on the verify link in the email.`}
      />
      <PrimaryHeader />
      <SecondaryHeader />
      {(user || {}).status !== 'PENDING' && <HeaderToken />}
      <PageContext.Provider value={{ showUserModal, setShowUserModal, selectedId }}>
        <UserModal source="LINK" />
      </PageContext.Provider>
      {children}
      <Footer />
    </AppContext.Provider>
  );
};

export default compose(
  withRouter,
  withApollo,
)(MainLayout);
