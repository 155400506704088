import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';

import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import UserModal from '../../components/modals/user-modal';
import withApollo from '../../config/with-apollo';
import {
  M_CONNECT_USER,
  M_MESSAGE,
  M_UPDATE_MESSAGE_SEEN,
  Q_GET_CONNECTED_USER_BY_STATE,
  S_CONNECTIONS
} from '../../graphql/messages';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import MessagesPresentation from './messages-presentation';
import { post } from '../../utils/api-handler';

const MessagesContainer = (props) => {
  const USER = AUTH();

  const [state, setState] = useState({
    currentMessage: null,
    message: null,
    firstLoad: false,
  });

  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [getConnectedUser, { loading: getConnectedUserLoading, data: getConnectedUserData },] = useLazyQuery(Q_GET_CONNECTED_USER_BY_STATE, { client: props.client });
  const [connectUser] = useMutation(M_CONNECT_USER, { client: props.client });
  const [sendMessage] = useMutation(M_MESSAGE, { client: props.client });
  const [updateMessageSeen] = useMutation(M_UPDATE_MESSAGE_SEEN, { client: props.client });

  const { loading: connectionLoading, data: connectionData } = useSubscription(S_CONNECTIONS, {
    client: props.client,
    variables: {
      where: {
        _or: [
          { user_id: { _eq: USER.id } },
          { connection_id: { _eq: USER.id } },
          { connections_users: { user_id: { _eq: USER.id } } }
        ],
      },
      messagesUnseenWhere: {
        seen: { _eq: false },
        from: { _neq: USER.id },
      },
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!getConnectedUserLoading && getConnectedUserData) {
      if (!getConnectedUserData.connections.length) {
        (async () => {
          const response = await connectUser({
            variables: {
              objects: [
                {
                  user_id: USER.id,
                  connection_id: props.location.state.user_id,
                },
              ],
              messagesUnseenWhere: {
                seen: { _eq: false },
                from: { _neq: USER.id },
              },
            },
          });

          setState((oldState) => ({
            ...oldState,
            currentMessage: isMobile ? null : response.data.insert_connections.returning[0],
            firstLoad: true,
          }));
        })();
      } else {
        let firstConversation = _.orderBy(
          getConnectedUserData.connections,
          [(value) => (value.messages.length ? value.messages[0].created_at : value.created_at)],
          ['desc'],
        )[0];

        setState((oldState) => ({ ...oldState, currentMessage: isMobile ? null : firstConversation, firstLoad: true }));
      }
    }
  }, [getConnectedUserLoading, getConnectedUserData, props.location.state, isMobile]);

  useEffect(() => {
    if (props.location.state) {
      (async () => {
        await getConnectedUser({
          variables: {
            where: {
              _or: [
                { user_id: { _eq: USER.id }, connection_id: { _eq: props.location.state.user_id } },
                { user_id: { _eq: props.location.state.user_id }, connection_id: { _eq: USER.id } },
              ],
            },
            messagesUnseenWhere: {
              seen: { _eq: false },
              from: { _neq: USER.id },
            },
          },
        });
      })();
    }
  }, [props.location.state]);

  useEffect(() => {
    if (!connectionLoading && !state.firstLoad && !props.location.state) {
      if (connectionData.connections.length) {
        let firstConversation = _.orderBy(
          connectionData.connections,
          [(value) => (value.messages.length ? value.messages[0].created_at : value.created_at)],
          ['desc'],
        )[0];

        handleSetState((oldState) => ({
          ...oldState,
          currentMessage: isMobile ? null : firstConversation,
          firstLoad: true,
        }));
      }
    }
  }, [connectionData, connectionLoading, state, isMobile]);

  const handleSetState = useCallback((callback) => {
    setState((oldState) => callback(oldState));
  }, []);

  const handleSendMessage = useCallback(
    async (event) => {
      try {
        event.preventDefault();
      } catch (error) {
       console.log(error); 
      }
      await sendMessage({
        variables: {
          objects: [
            {
              from: USER.id,
              to: handleGetConnection(state.currentMessage).id,
              connection_id: state.currentMessage.id,
              message: state.message,
              seen: false
            },
          ],
        },
      });


      try {
        if(state.currentMessage.type === 'group') {
          let response1 = await post('/new-message-intro', {
            connection_id: state.currentMessage.id,
            from: USER.id
          });
          console.log(response1);
        } else {
          let response2 = await post('/new-message', {
            to: handleGetConnection(state.currentMessage).id, 
            from: USER.id
          });
          console.log(response2)
        }
      } catch (error) {
        console.log(error)
      }


      handleSetState((oldState) => ({ ...oldState, message: null }));
    },
    [state],
  );

  const handleUpdateMessageSeen = useCallback(async () => {
    if (state.currentMessage) {
      await updateMessageSeen({
        variables: {
          where: {
            _and: [ 
              {connection_id: { _eq: state.currentMessage.id }},
              {to: { _eq: USER.id}}
            ]
          },
          set: {
            seen: true,
          },
        },
      });
    }
  }, [state]);
  
  // const handleUpdateGrpMessageSeen = useCallback(async () => {
  //   if (state.currentMessage) {
  //     await updateMessageSeen({
  //       variables: {
  //         where: {
  //           connection_id: { _eq: state.currentMessage.id }
  //         },
  //         set: {
  //           seen: true,
  //         },
  //       },
  //     });
  //   }
  // }, [state]);

  function handleGetConnection(value) {
    if (USER.id !== value.connection_id) {
      return value.user_connection || {};
    } else {
      return value.user || {};
    }
  }

  const handleSetMessage = (event) => {
    event.persist();

    handleSetState((oldState) => ({ ...oldState, message: event.target.value }));
  };

  const handleGetUser = (id) => (e) => {
    e.stopPropagation();
    setSelectedId(id);
    setShowUserModal(true);
  };

  return (
    <PageContext.Provider
      value={{
        connectionData,
        connectionLoading,
        state,
        handleSetState,
        handleSendMessage,
        handleUpdateMessageSeen,
        // handleUpdateGrpMessageSeen, 
        handleGetConnection,
        handleSetMessage,
        handleGetUser,
        USER,
        showUserModal,
        setShowUserModal,
        selectedId,
        setSelectedId,
      }}
    >
      <UserModal source="IN" />
      <MessagesPresentation />
    </PageContext.Provider>
  );
};

export default withApollo(withRouter(MessagesContainer));
