import gql from 'graphql-tag';

export const S_USER_TOKENS = gql`
    subscription userTokens($id: uuid!){
        user_tokens(order_by: {created_at: desc}, where:{user_id:{_eq: $id}}){
            id
            quantity
            transaction
            created_at
            transaction_type
            by
        }
    }
`;

export const S_USER_CURRENT_TOKENS = gql`
    subscription userCurrentTokens($id: uuid!){
        users_by_pk(id: $id){
            id
            status
            user_current_tokens{
                total
            }
        }
    }
`;
