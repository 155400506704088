import { Modal } from 'antd';
import React, { useContext } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import bigCloseIcon from '../../assets/icons/big-close-icon.png';
import { PageContext } from '../../helpers/context';

const PapAccessModal = (props) => {
  const { showModal, setShowModal } = useContext(PageContext);

  return <Modal
    visible={showModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={true}
    onCancel={() => setShowModal(false)}
  >
    <div className={'flex flex-col'}>
      <img src={bigCloseIcon} alt={''} className={'self-center w-24 mb-6'} />
      <p className={'self-center text-center text-3xl text-black mb-3'}>Cant Access!</p>
      <p className={'self-center text-center text-lg mb-5'}>
        This feature is only available for Community, Standard and Premium subscriptions. <span className='text-primary cursor-pointer' onClick={() => props.history.push('/user-subscription')}>Upgrade now!</span>
      </p>
    </div>
  </Modal>;
};

export default compose(withRouter)(PapAccessModal);
