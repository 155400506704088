export const businessFields = {
  id: undefined,
  name: undefined,
  stage: undefined,
  type: undefined,
  url: undefined,
  offering: undefined,
  about: undefined,
  order: undefined,
  industry: undefined
  //tags: []
};
//Thinking, Setting up, Growing, Thriving
export const stageOption = [
  {
    key: 'Thinking',
    value: 'Thinking',
    label: 'Thinking'
  },
  {
    key: 'Setting up',
    value: 'Setting up',
    label: 'Setting up'
  },
  {
    key: 'Growing',
    value: 'Growing',
    label: 'Growing'
  },
  {
    key: 'Thriving',
    value: 'Thriving',
    label: 'Thriving'
  }
];
//B2B, B2C, Both, Not sure
export const typeOption = [
  {
    key: 'B2B',
    value: 'B2B',
    label: 'B2B'
  },
  {
    key: 'B2C',
    value: 'B2C',
    label: 'B2C'
  },
  {
    key: 'B2B and B2C',
    value: 'B2B and B2C',
    label: 'B2B and B2C'
  },
  {
    key: 'Not sure',
    value: 'Not sure',
    label: 'Not sure'
  }
];
// Product, Services, Both, Not sure
export const offeringOption = [
  {
    key: 'Product',
    value: 'Product',
    label: 'Product'
  },
  {
    key: 'Services',
    value: 'Services',
    label: 'Services'
  },
  {
    key: 'Product and Services',
    value: 'Product and Services',
    label: 'Product and Services'
  },
  {
    key: 'Not sure',
    value: 'Not sure',
    label: 'Not sure'
  }
];
