import { useSubscription } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { validate } from 'uuid';
import ExpertCard from '../../components/cards/expert-card';
import ExpertCommentCard from '../../components/cards/expert-comment-card';
import ExpertDescriptionCard from '../../components/cards/expert-description-card';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import ExpertBookingForm from '../../components/modals/expert-booking-form';
import ExpertConfirmation from '../../components/modals/expert-confirmation';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { S_EXPERT } from '../../graphql/experts';
import { AppContext, PageContext } from '../../helpers/context';
import ExpertEvents from './expert-events';

let fromNow = moment()
  .format('YYYY-MM-DD');

const ExpertsDetails = (props) => {
  const { user_email } = useContext(AppContext)
  const ID = props.match.params.id;
  let where;
  if (validate(ID)) {
    where = { id: { _eq: ID } };
  } else {
    where = { slug: { _eq: ID } };
  }
  const [heightOfCards, setHeightCards] = useState({
    details: 0,
  });
  const [showModal, setShowModal] = useState(null);
  const [currentResource, setCurrentResource] = useState({});
  const { loading, error, data } = useSubscription(S_EXPERT, {
    client: props.client,
    variables: { where, fromNow },
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showBookingFormModal, setShowBookingFormModal] = useState(false);
  //const { loading: loadingTransactions, errorTransactions, data: dataTransactions } = useSubscription(
  //  S_USER_TRANSACTIONS,
  //  {
  //    client: props.client,
  //    variables: {
  //      where: {
  //        user_id: {
  //          _eq: USER.id
  //        },
  //        transaction: {
  //          _contains: {
  //            transaction_id: ID
  //          }
  //        }
  //      }
  //    }
  //  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loading && data.expert_listings[0]) {
      setCurrentResource(data.expert_listings[0])
    }
  }, [loading, data])

  const handleBackToList = () => {
    props.history.push('/get-advice');
  };

  return (
    <PageContext.Provider
      value={{
        loading,
        error,
        data,
        heightOfCards,
        setHeightCards,
        showModal,
        setShowModal,
        currentResource,
        setCurrentResource,
        showSuccessModal,
        setShowSuccessModal,
        showBookingFormModal,
        setShowBookingFormModal,
      }}
    >
      <div className={'min-h-screen bg-light-gray'}>
        <HeaderCoverBanner1
          title={'Get practical and tailored Expert Advice'}
          subtitle={'Solve your business challenges so you can move forward with confidence.'}
        />
        <HeaderCoverBanner1Mobile
          title={'Get practical and tailored Expert Advice'}
          subtitle={'Solve your business challenges so you can move forward with confidence.'}
        />
        <div className={'container mx-auto px-4 md:px-16 pb-10'}>
          <div className={'py-8 container mx-auto'}>
            <span className={'text-base md:text-lg text-primary'}>
              <button className={'focus:outline-none hover:text-primary'} onClick={handleBackToList}>
                Expert Advice
              </button>
              &nbsp; {'>'} &nbsp;
            </span>
            <span className={'text-base md:text-lg text-black'}>{!loading && data.expert_listings[0].name}</span>
          </div>
          {!loading && (
            <>
              <ExpertConfirmation data={currentResource} />
              <ExpertBookingForm />
              <ModalSuccess
                title={'Expert session request received'}
                subtitle={`You'll receive a message at ${user_email} from our team to set up the session.`}
              />
              <div className={'md:flex md:flex-row md:items-stretch'}>
                <div className={'mb-8 md:w-3/4 md:pr-10'}>
                  <ExpertCard isDetails={true} hasTransaction={false} data={!loading && data.expert_listings[0]} />
                  <ExpertEvents data={!loading && data.expert_listings[0]} />
                </div>
                <div className={'md:w-1/3 mb-8 h-full'}>
                  <ExpertDescriptionCard />
                </div>
              </div>
              <ExpertCommentCard hasTransaction={true} />
            </>
          )}
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default compose(
  withApollo,
  withRouter,
)(ExpertsDetails);
