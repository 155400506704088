import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/styles/ribbon.css';

const PerkCard = (props) => {
  const perks = props.data;
  return <div onClick={() => {props.history.push(`/offers/${perks.id}`);}}
    className={'flex flex-row  h-32 cursor-pointer'}>
    <div className={'w-7/12 relative bg-white rounded-l-lg perk-card-left'}
      style={{ boxShadow: '4px 4px 4px -2px #d8d3d3' }}>
      {perks.is_featured && <div className="ribbon">Featured</div>}
      <div className={'py-6 pl-7.5 pr-5 mt-1'}>
        <p className={'text-black text-sm font-grumpy mb-1 h-7 leading-none truncate-2'}>
          {perks.name}
        </p>
        <p className={'text-3xl font-grumpy text-primary'}>{perks.discount}</p>
        <p className={'text-xs -mt-3 font-light'}>{perks.discount_description}</p>
      </div>
    </div>
    <div className={'w-5/12 bg-light-gray-header rounded-r-lg py-6 px-5 flex justify-center items-center perk-card-right'}
      style={{ boxShadow: '-1px 4px 4px -2px #d8d3d3' }}>
      <img src={perks.image} alt={''} style={{ maxWidth: '150px', width: '100%' }}/>
    </div>
  </div>;
};

export default withRouter(PerkCard);
