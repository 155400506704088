import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from '../../helpers/context';
import EventAttendeesModal from '../modals/event-attendees-modal';
import UserModal from '../modals/user-modal';

const EventMembersCard = () => {
  const { event, setShowModalAttendees, setHeightCards } = useContext(PageContext);
  const cardRef = useRef(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    setHeightCards((oldState) => ({ ...oldState, members: cardRef.current.clientHeight }));
  }, [cardRef]);

  const handleShowModalAttendees = () => {
    setShowModalAttendees(true);
  };

  const handleShowUserModal = (id) => () => {
    setSelectedId(id);
    setShowUserModal(true);
  };
  return (
    <>
      <EventAttendeesModal />
      <PageContext.Provider value={{ showUserModal, setShowUserModal, selectedId, setSelectedId }}>
        <UserModal source="EV" />
        <div ref={cardRef}>
          <div className={'rounded-lg shadow-md bg-white p-4 w-full'}>
            <div className={'flex items-center'}>
              {(event.user || {}).avatar ? (
                <div
                  onClick={handleShowUserModal((event.user || {}).id)}
                  className={'rounded-full w-10 h-10 bg-cover bg-no-repeat bg-center cursor-pointer'}
                  style={{ backgroundImage: `url(${(event.user || {}).avatar})` }}
                />
              ) : (
                <div
                  onClick={handleShowUserModal((event.user || {}).id)}
                  className={
                    'flex items-center justify-center font-grumpy text-white text-xl bg-event-going rounded-full h-10 w-10 cursor-pointer'
                  }
                >
                  {event.user ? (event.user || {}).name[0].toUpperCase() : 'X'}
                </div>
              )}
              <p className={'ml-5 text-base text-black'}>Hosted by {(event.user || {}).name}</p>
            </div>
            <div className={'mt-8 5'}>
              <p className={'text-lg text-black'}>Members</p>
              <div className={'flex flex-row mt-3'}>
                {_.takeRight(event.attendees, 4).map((value, key) => {
                  return key >= 3 ? (
                    <div
                      className={'flex flex-col items-center w-4/12 text-center cursor-pointer'}
                      onClick={handleShowModalAttendees}
                      key={value.id}
                    >
                      <div className={'h-10 w-10 rounded-full bg-gray-400 flex justify-center items-center text-base'}>
                        +{event.attendees_aggregate.aggregate.count}
                      </div>
                      <p className={'mt-3 text-base text-primary'}>More</p>
                    </div>
                  ) : (
                    <div
                      onClick={handleShowUserModal(value.user.id)}
                      key={value.id}
                      className={`flex flex-col cursor-pointer items-center w-4/12 text-center ${
                        key === 2 && _.takeRight(event.attendees, 4).length === 4 ? 'hidden' : ''
                      }`}
                    >
                      {value.user.avatar ? (
                        <div
                          className={'rounded-full w-10 h-10 bg-cover bg-no-repeat bg-center'}
                          style={{ backgroundImage: `url(${value.user.avatar})` }}
                        />
                      ) : (
                        <div
                          className={
                            'flex items-center justify-center font-grumpy text-white text-xl bg-event-going rounded-full h-10 w-10'
                          }
                        >
                          {value.user.name[0].toUpperCase()}
                        </div>
                      )}
                      <p className={'mt-3 text-base'}>{value.user.name.split(' ')[0]}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </PageContext.Provider>
    </>
  );
};

export default EventMembersCard;
