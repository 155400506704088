import React from 'react';
import ExpertCommentDetails from './expert-comment-details-card';
import ExpertCommentForm from './expert-comment-form';

const ExpertCommentCard = (props) => {
  return (
    <div className={'rounded-lg shadow-md p-4 bg-white'}>
      {props.hasTransaction && (
        <>
          <p className={'text-lg text-black font-bold'}>Write Review</p>
          <ExpertCommentForm />
        </>
      )}
      <ExpertCommentDetails hasTransaction={props.hasTransaction} />
    </div>
  );
};

export default ExpertCommentCard;
