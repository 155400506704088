import { Input, } from 'antd';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import inputIconError from '../../assets/icons/input-icon-error.png';

const CustomInput = (props) => {
  let renderClass = classNames({ 'pl-12': !!props.prefixIcon });
  
  return <Fragment>
    <Input
      {...props}
      className={`rounded-full w-full h-10 text-sm  bg-no-repeat bg-center-4 rounder-full focus:outline-none ${props.className} ${renderClass}`}
      style={{ 'backgroundImage': `url(${props.prefixIcon})`, ...props.style }}/>
    {!!props.error && <div className={'flex flex-row mt-1'}>
      <img src={inputIconError} alt={''} className={'w-auto h-4 ml-2'}/>
      <p className={'text-xs text-gray-500 font-light mb-1 ml-3'}>{props.error}</p>
    </div>}
  </Fragment>;
};

export default CustomInput;
