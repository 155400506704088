import bigCheckIcon from '../../assets/icons/big-check-icon.png';
import React, { useContext } from 'react';
import { Modal } from 'antd';
import { PageContext } from '../../helpers/context';

const ModalSuccessDiscount = () => {
  const { discountVisible, handleSetDiscountVisible, discountCode } = useContext(PageContext);
  
  return (
    <Modal visible={discountVisible}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={'flex flex-col'}>
        <img src={bigCheckIcon} alt={''} className={'self-center w-24 mb-6'}/>
        <p className={'self-center text-center text-3xl text-black'}>Success!</p>
        <p className={'self-center text-center text-lg mb-5'}>
          Promo code <span className={'text-black'}>{discountCode}</span> has been applied successfully! Please enter a
          valid credit card and click on Pay Now.
        </p>
        <button
          onClick={handleSetDiscountVisible}
          className={'self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none '}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default ModalSuccessDiscount;