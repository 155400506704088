import React from 'react';
import DigitalLibraryCommentDetails from './digital-library-comment-details-card';
import DigitalLibraryCommentForm from './digital-library-comment-form';

const DigitalLibraryCommentCard = (props) => {
  return <div className={'rounded-lg shadow-md p-4 bg-white'}>
    {props.hasTransaction && (
      <>
        <p className={'text-lg text-black font-bold'}>
          Write Review
        </p>
        <DigitalLibraryCommentForm />
      </>
    )}
    <DigitalLibraryCommentDetails hasTransaction={props.hasTransaction} />
  </div>;
};

export default DigitalLibraryCommentCard;
