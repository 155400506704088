import gql from 'graphql-tag';

export const S_MEMBER_DIRECTORIES = gql`
    query memberDirectories($users_where: users_bool_exp, $limit: Int, $offset: Int){
        users(
            order_by: {user_plans_aggregate: {max: {available_group_expert_sessions: desc_nulls_last}}}, 
            where: $users_where,
            limit: $limit, offset: $offset
        ) {
            id
            updated_at
            created_at
            subscription_type
            number
            profile_completion
            status
            avatar
            address
            active
            about_me
            business_intros
            name
            username
            businesses(order_by: {order:asc}){
                id
                stage
                type
                url
                name
                about
                offering
                industry
            }
            user_plans(limit: 1, order_by: {created_at: desc}){
                id
                plan_type
                expire_at
                subscription_type
            }
            user_business_goals_tags(where: {private: {_eq: false}}) {
              id
              business_goals_tag {
                id
                name
                pillar {
                  color
                }
              }
              category {
                name
                id
              }
              pillar {
                color
              }
            }
        }
    }
`;

export const MEMBER_TRACKER = gql`
  mutation member_tracker($objects: [member_directory_tracker_insert_input!]!){
    insert_member_directory_tracker(objects: $objects){
      returning{
        id
      }
    }
  }
`;
