import { useSubscription } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import DigitalLibraryCard from '../../components/cards/digital-library-card';
import DigitalLibraryCommentCard from '../../components/cards/digital-library-comment-card';
import DigitalLibraryDescriptionCard from '../../components/cards/digital-library-description-card';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import DigitalLibraryConfirmation from '../../components/modals/digital-library-confirmation';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { S_DIGITAL_LIBRARY } from '../../graphql/digital-libraries';
import { S_USER_TRANSACTIONS } from '../../graphql/user';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';

const DigitalLibraryDetails = (props) => {
  const { setBookNow, setRequest } = props;
  const ID = props.match.params.id;
  const USER = AUTH();
  const [heightOfCards, setHeightCards] = useState({
    details: 0
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentResource, setCurrentResource] = useState({});
  const { loading, error, data } = useSubscription(S_DIGITAL_LIBRARY, { client: props.client, variables: { id: ID } });
  const { loading: loadingTransactions, data: dataTransactions } = useSubscription(
    S_USER_TRANSACTIONS,
    {
      client: props.client, variables: {
        where: {
          user_id: {
            _eq: USER.id
          },
          transaction: {
            _contains: {
              transaction_id: ID
            }
          }
        }
      }
    });

  let hasTransaction = !loadingTransactions && dataTransactions.user_token_transaction.length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(!loading && data.digital_library_by_pk) {
      setCurrentResource(data.digital_library_by_pk)
    }
  }, [loading, data])

  const handleBackToList = () => {
    props.history.push('/digital-library');
  };

  return <PageContext.Provider value={{
    loading,
    error,
    data,
    heightOfCards,
    setHeightCards,
    showModal,
    setShowModal,
    currentResource,
    setCurrentResource,
    showSuccessModal,
    setShowSuccessModal,
    setBookNow, 
    setRequest
  }}>
    <div className={'min-h-screen bg-light-gray'}>
      <HeaderCoverBanner1 title={'Access 1000+ documents, courses and videos and grow your business.'}
        subtitle={'We’ve organised these brilliant offers at great prices to support your unique business needs'} />
      <HeaderCoverBanner1Mobile title={'Access 1000+ documents, courses and videos and grow your business.'}
        subtitle={'We’ve organised these brilliant offers at great prices to support your unique business needs'} />
      <div className={'container mx-auto px-4 md:px-16 pb-10'}>
        <div className={'py-8 container mx-auto'}>
          <span className={'text-base md:text-lg text-primary'}><a className={'hover:text-primary'}
            onClick={handleBackToList}>Digital Resources</a>&nbsp;  &nbsp;</span>
          <span className={'text-base md:text-lg text-black'}>{!loading && data.digital_library_by_pk.title}</span>
        </div>
        {!loading && (
          <>
            <DigitalLibraryConfirmation data={currentResource} />
            <ModalSuccess title={'Digital Resources'} subtitle={'You can now access this resource. Enjoy!'}  />
            <div className={'md:flex md:flex-row md:items-stretch'}>
              <div className={'mb-8 md:w-3/4 md:pr-10'}>
                <DigitalLibraryCard hasTransaction={!!hasTransaction}
                  data={data.digital_library_by_pk} />
              </div>
              <div className={'md:w-1/3 mb-8 h-full'}>
                <DigitalLibraryDescriptionCard />
              </div>
            </div>
            <DigitalLibraryCommentCard hasTransaction={!!hasTransaction} />
          </>
        )}
      </div>
    </div>
  </PageContext.Provider>;
};

export default compose(withApollo, withRouter)(DigitalLibraryDetails);
