import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { Form, Icon } from 'antd';
import 'antd/es/notification/style/css';
import _, { difference } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import HeaderCoverBanner1 from '../../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../../components/header/header-cover-banner-mobile';
import CancelSubscription from '../../../components/modals/cancel-subscription';
import CancelSubscriptionSuccess from '../../../components/modals/cancel-subscription-success';
import DeleteAccountConfirmation from '../../../components/modals/delete-account-confirmation';
import ModalDeleteSuccess from '../../../components/modals/modal-delete-success';
import ModalError from '../../../components/modals/modal-error';
import ModalSuccess from '../../../components/modals/modal-success';
import withApollo from '../../../config/with-apollo';
import {
  M_USER_BUSINESS_GOALS_TAGS, Q_USER_BUSINESS_GOALS_TAGS, S_BUSINESS_GOALS_TAGS
} from '../../../graphql/business-goals';
import { M_USER_PROFILE, S_USER_BUSINESSES } from '../../../graphql/user';
import { AppContext, PageContext } from '../../../helpers/context';
import computation from '../../../layouts/completion-computation';
import { post } from '../../../utils/api-handler';
import { AUTH } from '../../../utils/common';
import BusinessGoalsCard from './business-goals';
import { businessFields } from './data-objects';
import ProfileCard from './profile-card';
import ProfileSettingPassword from './profile-setting-password';
import ProfileSettingCard from './profile-settings-card';
import UserActiveIcon from '../../../assets/icons/user-active.png'
import UserInactiveIcon from '../../../assets/icons/user-inactive.png'
import GridActiveIcon from '../../../assets/icons/grid-active.png'
import GridInactiveIcon from '../../../assets/icons/grid-inactive.png'


const ProfileInformation = (props) => {
  const { client } = props;
  const USER = AUTH();
  const {
    user_address,
    user_number,
    user_about_me,
    user_is_expired,
    user_avatar,
    user_plan,
    user_membership_expired_at,
    user_tokens,
    user_subscription_type,
    user_business_intros
  } = useContext(AppContext);
  

  let primary = Object.assign({}, businessFields);
  let secondary = Object.assign({}, businessFields);

  const [EDIT, setEDIT] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState(0);
  const [business, setBusiness] = useState([primary, secondary]);
  const [submitting, setSubmitting] = useState(false);
  const [tempImageUrl, setTempImageUrl] = useState({ blob: null, image: null });
  const [activeTab, setActiveTab] = useState(1);

  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);

  const [pillars, setPillars] = useState([]);
  const [selectedPillar, setSelectedPillar] = useState({ id: 'All', name: 'All Pillars', color: '4ca6b7' });
  const [EDIT_TAGS, setEDIT_TAGS] = useState(false);
  const [businessTags, setBusinessTags] = useState([]);
  const [userBusinessTags, setUserBusinessTags] = useState([]);
  const [businessTagsSubmitting, setBusinessTagsSubmitting] = useState(false);

  const [editMode, setEditMode] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelSubscription, setCancelSubscription] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showSuccessCancelSubscription, setShowSuccessCancelSubscription] = useState(false);
  const [userTags, setUserTags] = useState([]);
  const [profile, setProfile] = useState({
    address: user_address,
    number: user_number,
    about_me: user_about_me,
    business_intros: user_business_intros
  });


  const { loading, data } = useSubscription(S_USER_BUSINESSES, { client, variables: { id: USER.id } });
  const { data: allBusinessTags } = useSubscription(S_BUSINESS_GOALS_TAGS, { client });
  // const { loading: loadingUserBusinessTags, data: userBusinessTagsData } = useSubscription(Q_USER_BUSINESS_GOALS_TAGS, { client, variables: { userId: USER.id } });
  const [loadUserBusinessGoalsTags, { data: userBusinessTagsData, refetch: refetchUserTags }] = useLazyQuery(
    Q_USER_BUSINESS_GOALS_TAGS,
    { client, fetchPolicy: 'network-only', variables: { userId: USER.id } }
  );

  useEffect(() => {
    loadUserBusinessGoalsTags()
  }, [])

  useEffect(() => {
    if (userBusinessTagsData && userBusinessTagsData.user_business_goals_tags && userBusinessTagsData.user_business_goals_tags.length) {
      const data = [];
      userBusinessTagsData.user_business_goals_tags.forEach(item => {
        const category = data.find(category => category.category.id === item.category.id);
        if (category) {
          category.tags.push({ ...item.business_goals_tag, private: item.private });
        } else {
          data.push({
            category: { name: item.category.name, id: item.category.id },
            tags: [{ ...item.business_goals_tag, private: item.private }]
          })
        }
      })
      setUserBusinessTags(data)
    }
  }, [userBusinessTagsData])

  useEffect(() => {
    if (window.location.hash.endsWith("#pap")) {
      setActiveTab(2);
    } else {
      setActiveTab(1); 
    }
  }, []);

  useEffect(() => {
    if (allBusinessTags && allBusinessTags.business_goals_tags) {
      const data = [];
      allBusinessTags.business_goals_tags.forEach(item => {
        const category = data.find(category => category.category.id === item.category.id);
        if (category) {
          category.tags.push(item);
        } else {
          data.push({
            category: { name: item.category.name, id: item.category.id },
            tags: [item]
          })
        }
      })
      setBusinessTags(data)
    }
  }, [allBusinessTags])

  useEffect(() => {
    if (!loading) {
      if (data.business.length) {

        //data.business.forEach(value => {
        //  value['tags'] = value['business_tags'].map(({ tag }) => tag);
        //});

        setBusiness(data.business);
      }
    }
  }, [data]);

  const changeBusiness = (business) => () => {
    setCurrentBusiness(business);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleOnChangeUserTags = (value) => {
    let clone = _.cloneDeep(userTags);

    clone = value;

    if (clone.length <= 2) {
      setUserTags(clone);
    }
  };

  const onChange = (field) => (e) => {
    e.persist && e.persist();

    if (field === 'avatar') {
      const files = e.target.files;

      getBase64(files[0], (image) => {
        setTempImageUrl({ blob: image, image: files[0] });
      });

      return;
    }

    setProfile(oldState => ({ ...oldState, [field]: ((e || {}).target || {}).value || e }));
  };

  const editProfile = () => {
    setEditMode('profile');
    setEDIT(!EDIT);
  };

  const editBusinessTags = () => {
    setEditMode('businessTags');
    setEDIT_TAGS(!EDIT_TAGS);
  };

  const onChangeBusiness = (field) => (e) => {
    let value;
    let clone = _.cloneDeep(business);

    if ((e.target)) {
      if (e.target.value) {
        value = e.target.value;
      } else {
        value = undefined;
      }
    } else {
      value = e;
    }

    clone[currentBusiness][field] = value;
    clone[currentBusiness]['order'] = currentBusiness;
    setBusiness(clone);
  };

  const handleSelectTags = (field) => (value) => {
    let clone = _.cloneDeep(business);

    clone[currentBusiness][field] = value;
    clone[currentBusiness]['order'] = currentBusiness;

    if (clone[currentBusiness][field].length <= 1) {
      setBusiness(clone);
    }
  };

  const updateCompletion = async () => {
    let avatar;
    let update_columns = ['id', 'address', 'number', 'about_me', 'profile_completion'];
    let businessObject = _.cloneDeep(business);

    delete businessFields.tags;

    if (tempImageUrl.image) {
      const formData = new FormData();

      formData.append('folder', 'Profile');
      formData.append('file', tempImageUrl.image);

      const response = await post('/upload', formData);

      avatar = response.data.Location;

      update_columns.push('avatar');
    }

    let tags;
    if (((userBusinessTagsData || {}).user_business_goals_tags || []).length) {
      tags = userBusinessTagsData.user_business_goals_tags
    }

    let profile_completion = computation({
      avatar: avatar || user_avatar,
      tags,
      ...profile,
      businesses: businessObject
    });
    await client.mutate({
      mutation: M_USER_PROFILE,
      variables: {
        //deleteBusinessTags,
        //deleteUserTags,
        update: {
          constraint: 'users_pkey',
          update_columns
        },
        object: {
          id: USER.id,
          username: '',
          name: '',
          password: '',
          avatar,
          ...profile,
          profile_completion
        }
      }
    });
  }

  const submitProfile = async () => {
    business.forEach(value => {
      delete value.__typename;
    });

    setSubmitting(true);

    try {
      let avatar;
      let update_columns = ['id', 'address', 'number', 'about_me', 'profile_completion', 'business_intros'];
      let businessObject = _.cloneDeep(business);

      delete businessFields.tags;

      if (tempImageUrl.image) {
        const formData = new FormData();

        formData.append('folder', 'Profile');
        formData.append('file', tempImageUrl.image);

        const response = await post('/upload', formData);

        avatar = response.data.Location;

        update_columns.push('avatar');
      }

      let tags;
      if (((userBusinessTagsData || {}).user_business_goals_tags || []).length) {
        tags = userBusinessTagsData.user_business_goals_tags
      }
      /** Calculate profile completion **/
      let profile_completion = computation({
        avatar: avatar || user_avatar,
        tags,
        ...profile,
        businesses: businessObject
      });
      await client.mutate({
        mutation: M_USER_PROFILE,
        variables: {
          //deleteBusinessTags,
          //deleteUserTags,
          update: {
            constraint: 'users_pkey',
            update_columns
          },
          object: {
            id: USER.id,
            username: '',
            name: '',
            password: '',
            avatar,
            ...profile,
            profile_completion,
          }
        }
      });

      await client.mutate({
        mutation: M_USER_PROFILE,
        variables: {
          //deleteBusinessTags,
          //deleteUserTags,
          update: {
            constraint: 'users_pkey',
            update_columns: ['id']
          },
          object: {
            id: USER.id,
            username: '',
            name: '',
            password: '',
            businesses: {
              //data: businessObject.map(value => {
              //  if (value['tags']) {
              //    value['business_tags'] = {
              //      data: value['tags'].map(tag => ({ tag })),
              //      on_conflict: {
              //        constraint: 'business_tags_pkey',
              //        update_columns: ['tag']
              //      }
              //    };
              //  }
              //
              //  delete value['tags'];
              //  return value;
              //}),
              data: businessObject,
              on_conflict: {
                constraint: 'business_pkey',
                update_columns: Object.keys(businessFields)
              }
            }
          }
        }
      });

      setEDIT(!EDIT);

      setShowSuccessModal(true);
    } catch (e) {
      setShowErrorModal(true);
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  const submitUserBusinessTags = async (selectedTags, userPrivateTags) => {
    setBusinessTagsSubmitting(true);

    const oldUserTags = await new Promise((resolve) => {
      const userTags = [];
      userBusinessTags.forEach(item => {
        item.tags.forEach(tag => {
          userTags.push(tag.id);
        })
      })
      resolve(userTags)
    });
    const selectedTagIds = selectedTags.map(data => data.tagId);
    const insertItems = difference(selectedTagIds, oldUserTags);
    const deleteItems = difference(oldUserTags, selectedTagIds);

    const insertData = [];

    businessTags.forEach(item => {
      item.tags.filter(item => insertItems.includes(item.id)).forEach(item => {
        insertData.push({
          categories_id: item.category.id,
          pillars_id: item.pillar.id,
          business_goals_tag_id: item.id,
          user_id: USER.id,
          private: userPrivateTags.includes(item.id)
        })
      });
    });
    try {
      await updateCompletion();
      await client.mutate({
        mutation: M_USER_BUSINESS_GOALS_TAGS,
        variables: {
          objects: insertData,
          deleteUserBusinessGoalsTags: deleteItems
        }
      });
      refetchUserTags()
      setEDIT_TAGS(state => !state);
      setShowSuccessModal(true);
    } catch (e) {
      setShowErrorModal(true);
      console.log(e);
    } finally {
      setBusinessTagsSubmitting(false);
    }
  };

  const redirectToLogin = () => {
    sessionStorage.removeItem('mumsnco-init');
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    props.history.push('/');
  };

  const handleActiveTab = (tabNumber) => {
    setActiveTab(tabNumber);

    if (tabNumber === 2) {
      return props.history.push('/profile/me#pap');
    } else {
      return props.history.push('/profile/me');
    }
  };


  return (
    <div className={'bg-light-gray min-h-screen profile-container'}>
      {props.location.pathname === '/profile/settings' && (
        <>
          <HeaderCoverBanner1 title={' Member Account Settings'}
            subtitle={'\n' +
              'Review or update your account settings below \n'} />
          <HeaderCoverBanner1Mobile title={' Member Account Settings'}
            subtitle={'\n' +
              'Review or update your account settings below \n'} />
        </>
      )}
      <div className={'container mx-auto md:pt-2 md:px-16'}>
        <div className={`flex flex-col w-full`}>
          <PageContext.Provider value={{
            EDIT,
            setEDIT,
            editProfile,
            submitting,
            submitProfile,
            updateCompletion,
            EDIT_TAGS,
            setEDIT_TAGS,
            pillars,
            setPillars,
            selectedPillar,
            setSelectedPillar,
            editBusinessTags,
            businessTagsSubmitting,
            setBusinessTagsSubmitting,
            submitUserBusinessTags,
            userBusinessTags,
            setUserBusinessTags,

            onChange,
            profile,
            onChangeBusiness,
            changeBusiness,
            currentBusiness,
            business,
            tempImageUrl,
            setShowSuccessModal,
            showSuccessModal,
            setBookNow,
            setRequest,

            showErrorModal,
            setShowErrorModal,
            showDeleteModal,
            setShowDeleteModal,
            showSuccessDeleteModal,
            setShowSuccessDeleteModal,
            handleSelectTags,
            handleOnChangeUserTags,
            userTags,
            user_is_expired,
            user_plan,
            showCancelSubscription,
            setCancelSubscription,
            showSuccessCancelSubscription,
            setShowSuccessCancelSubscription,
            businessTags,
            setBusinessTags
          }}>
            <DeleteAccountConfirmation />
            <ModalDeleteSuccess />
            <CancelSubscription />
            <CancelSubscriptionSuccess title={'We are sorry to see you go :('}
              subtitle={`
                Your membership will remain active till
                 <span class="font-medium text-black">${moment(user_membership_expired_at).format('ll')}</span>.
                Your account will not be charged going forward. You have
                <span class="font-medium text-black">${user_tokens}</span>
                tokens in your account which you can consume till then.
              `}
            />
            <ModalSuccess title={props.location.pathname === '/profile/me' ?
              editMode !== 'profile' ? 'Setting goals to kick goals! ' : 'Profile Update' : 'Password Update'}
              subtitle={props.location.pathname === '/profile/me'
                ? editMode !== 'profile' ? 'Your focus areas for your personalised action plan successfully updated.' : 'Profile successfully updated.'
                : 'Password successfully updated. Please login again'}
              redirect={props.location.pathname === '/profile/settings' && redirectToLogin}
              buttonText={props.location.pathname === '/profile/settings' && 'Close'}
            />
            <ModalError title={props.location.pathname === '/profile/me'
              ? editMode !== 'profile' ? 'Business Goals Tags Update Failed' : 'Profile Update Failed'
              : 'Password Update Failed'} subtitle={'Something went wrong. Please try again.'} />
            {props.location.pathname === '/profile/me' && (
              <>
                <div className={'md:inline-flex hidden bg-light-gray-header p-5 mb-2 border rounded-lg flex flex-row justify-between'}>
                  <div className={'flex flex-col'}>
                    <p className={'text-lg font-grumpy text-primary'}>Profile Information</p>
                    <p className={'text-black mb-3'}>
                      Update your profile so we can show off your business to our members and give you personalised
                      offers.
                    </p>
                  </div>
                  <div>
                    {activeTab === 2 ? '' :
                    <>
                      <button disabled={submitting}
                        onClick={EDIT ? submitProfile : editProfile}
                        className={`bg-primary py-1 px-6 rounded text-sm text-white focus:outline-none flex items-center
                        ${submitting ? 'bg-disabled' : 'bg-primary'}
                        `}>
                        {submitting && EDIT && <Icon type={'loading'} className={'mr-2'} />}{EDIT ? 'Save' : 'Edit'}
                      </button>
                    </>
                    }
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center mt-3 md:ml-5 text-sm font-sans overflow-x-auto overflow-y-hidden justify-center md:justify-start flex-nowrap space-x-2 md:space-x-4">
              <a rel="noopener noreferrer" className={`flex items-center flex-shrink-0 px-4 py-2 lg:px-7 lg:py-3 space-x-2 ${activeTab === 1 ? 'bg-white border border-b-0 rounded-t-lg active-tab' : 'border border-b-0 rounded-t-lg'}`} onClick={() => handleActiveTab(1)}>
                <img src={activeTab === 1 ? UserActiveIcon : UserInactiveIcon} className={'w-5 h-auto'} alt={''} />
                <span className={`font-semibold ${activeTab === 1 ? 'text-primary' : 'text-gray-600'}`}>Profile Details</span>
              </a>
              <a rel="noopener noreferrer" className={`flex items-center flex-shrink-0 px-4 py-2 lg:px-5 lg:py-3 space-x-2 ${activeTab === 2 ? 'bg-white border border-b-0 rounded-t-lg active-tab' : 'border border-b-0 rounded-t-lg'}`} onClick={() => handleActiveTab(2)}>
                <img src={activeTab === 2 ? GridActiveIcon : GridInactiveIcon} className={'w-5 h-auto'} alt={''} />
                <span className={`font-semibold ${activeTab === 2 ? 'text-primary' : 'text-gray-600'}`}>Personalised Action Plan</span>
              </a>
            </div>
            {activeTab === 1 ? 
            <>
            <div className={'flex md:flex-row flex-col w-full items-start'}>
              {props.location.pathname === '/profile/me' && (
                <>
                  {user_subscription_type ?
                    <>
                      <div className={'w-full lg:grid lg:grid-cols-10 bg-white rounded-lg p-6 break-words mb-2'}>
                        <div className='w-full flex lg:col-span-4 lg:col-start-1 lg:order-1'>
                          <ProfileCard />
                        </div>
                        <div className='w-full flex lg:col-span-5 lg:col-start-6 lg:order-2'>
                          <ProfileSettingCard />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className='md:w-6/12 w-full md:mr-10'>
                        <ProfileCard />
                      </div>
                      <ProfileSettingCard />
                    </>
                  }
                </>
              )}
              {props.location.pathname === '/profile/settings' && <ProfileSettingPassword />}
            </div>
            </> 
            :
            <>
            <div className={'flex md:flex-row flex-col w-full items-start'}>
              {props.location.pathname === '/profile/me' && (
                <>
                  {user_subscription_type ?
                    <>
                      <div className={'w-full'}>
                        <BusinessGoalsCard />
                      </div>
                    </>
                    :
                    <>
                      <div className='md:w-6/12 w-full md:mr-10'>
                        <ProfileCard />
                      </div>
                      <ProfileSettingCard />
                    </>
                  }
                </>
              )}
              {props.location.pathname === '/profile/settings' && <ProfileSettingPassword />}
            </div>
            </> 
            }
            
          </PageContext.Provider>
        </div>
        <div className={`flex flex-col justify-end w-full mt-2`}>

        </div>
      </div>
    </div>
  );
};

export default compose(Form.create({ name: 'profile-form' }), withApollo, withRouter)(ProfileInformation);
