import { Modal } from 'antd';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import bigCheckIcon from '../../assets/icons/big-check-icon.png';
import { PageContext } from '../../helpers/context';

const ModalDeleteSuccess = (props) => {
  const { showSuccessDeleteModal } = useContext(PageContext);

  const handleClose = () => {
    sessionStorage.removeItem('mumsnco-init');
    localStorage.removeItem('mums-and-co-has-login');
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    props.history.push('/');
  };

  return <Modal visible={showSuccessDeleteModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={false}
  >
    <div className={'flex flex-col'}>
      <img src={bigCheckIcon} alt={''} className={'self-center w-24 mb-6'} />
      <p className={'self-center text-center text-3xl text-black'}>Account Disabled</p>
      <p className={'self-center text-center text-lg mb-5'}>
        We are sorry to see you go <spn role="img">😥</spn>
      </p>
      <button
        onClick={handleClose}
        className={'self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none '}>
        Logout
      </button>
    </div>
  </Modal>;
};

export default withRouter(ModalDeleteSuccess);
