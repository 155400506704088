import { useQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import ExpertConfirmation from '../../components/modals/expert-confirmation';
import ExpertSuggestionForm from '../../components/modals/expert-suggestion-form';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { Q_USER_BUSINESS_GOALS_TAGS } from '../../graphql/business-goals';
import { AppContext, PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import ExpertsFilters from './experts-filters';
import ExpertsSubscription from './experts-subscription';

const ExpertsPage = (props) => {
  const USER = AUTH();
  const { user_email } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [currentResource, setCurrentResource] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showBookingFormModal, setShowBookingFormModal] = useState(false);
  const [showSuggestionFormModal, setShowSuggestionFormModal] = useState(false);
  const [introText, setIntroText] = useState();
  const { data: userBusinessGoals } = useQuery(Q_USER_BUSINESS_GOALS_TAGS, { client: props.client, variables: { userId: USER.id } });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [filterTags, setFilterTags] = useState([]);
  const [filter, setFilter] = useState({
    myResources: false,
    resource_category_id: null,
    resource_tag_idx: [],
    pillar: 'All',
    category: null,
    keyword: null,
    is_related: false,
    tags: []
  });

  useEffect(() => {
    // Populate user goals tags for filter
    if (!userBusinessGoals) return;

    const userTags = userBusinessGoals.user_business_goals_tags || [];
    setFilter(state => ({ ...state, tags: userTags.map(tags => tags.business_goals_tag.id) }))
  }, [userBusinessGoals]);

  const [heightOfCards, setHeightCards] = useState({
    details: 0,
  });

  const handleChangeFilter = (id) => () => {
    setFilter((oldState) => ({ ...oldState, resource_category_id: id }));
  };

  const handleChangeFilterTag = (idx) => {
    setFilter((oldState) => ({ ...oldState, resource_tag_idx: idx }));
  };

  const handleGetMyResources = () => {
    setFilter((oldState) => ({
      ...oldState,
      myResources: USER.id,
    }));
  };

  const handleGetAllResources = () => {
    setFilter((oldState) => ({
      ...oldState,
      myResources: undefined,
    }));
  };

  return (
    <PageContext.Provider
      value={{
        heightOfCards,
        setHeightCards,
        currentResource,
        setCurrentResource,
        showModal,
        setShowModal,
        filter,
        setFilter,
        handleChangeFilter,
        showSuccessModal,
        setShowSuccessModal,
        showBookingFormModal,
        setShowBookingFormModal,
        showSuggestionFormModal,
        setShowSuggestionFormModal,
        handleChangeFilterTag,
        filterTags,
        introText,
        setIntroText,
        setFilterTags,
      }}
    >
      <div className={'min-h-screen bg-light-gray'}>
        <HeaderCoverBanner1
          title={'Gain confidence with strategic guidance from Experts'}
          subtitle={
            <span>
              Solve your business challenges in 30 mins by speaking online with experts to guide you and give you the
              confidence to move forward.
              {/*<Tooltip placement="topLeft"*/}
              {/*  className={'ml-1'}*/}
              {/*  title={(*/}
              {/*    <ul className={'list-disc list-inside'}>*/}
              {/*      <li>Get practical, strategic guidance from our pool of 20+ experts</li>*/}
              {/*      <li>Accessible price: 30 tokens for 30 minutes</li>*/}
              {/*      <li>Ease of booking: simply book online, whenever you need guidance</li>*/}
              {/*      <li>On demand: the session is conducted via zoom online at a convenient time</li>*/}
              {/*      <li>*/}
              {/*        Quality experts: trusted and vetted by Mums & Co and our community of businesswomen*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*  )}>*/}
              {/*  <Icon type="info-circle"/>*/}
              {/*</Tooltip>*/}
            </span>
          }
        />
        <HeaderCoverBanner1Mobile
          title={'Gain confidence with strategic guidance from Experts'}
          subtitle={
            <span>
              Solve your business challenges in 30 mins by speaking online with experts to guide you and give you the
              confidence to move forward.
              {/*<Tooltip placement="topLeft"*/}
              {/*  className={'ml-1'}*/}
              {/*  title={(*/}
              {/*    <ul className={'list-disc list-inside'}>*/}
              {/*      <li>Get practical, strategic guidance from our pool of 20+ experts</li>*/}
              {/*      <li>Accessible price: 30 tokens for 30 minutes</li>*/}
              {/*      <li>Ease of booking: simply book online, whenever you need guidance</li>*/}
              {/*      <li>On demand: the session is conducted via zoom online at a convenient time</li>*/}
              {/*      <li>*/}
              {/*        Quality experts: trusted and vetted by Mums & Co and our community of businesswomen*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*  )}>*/}
              {/*  <Icon type="info-circle"/>*/}
              {/*</Tooltip>*/}
            </span>
          }
        />
        <div className={' bg-light-gray-header'}>
          <div className={'container mx-auto  flex items-end h-10 md:h-15 px-4 md:px-16'}>
            <button
              onClick={handleGetAllResources}
              className={`${!filter.myResources &&
                'rounded-t bg-white'} h-7.5 md:h-12 p-4 flex justify-center items-center hover:text-black focus:outline-none`}
            >
              Available Experts
            </button>
            <button
              onClick={handleGetMyResources}
              className={`${filter.myResources &&
                'rounded-t bg-white'} h-7.5 md:h-12 p-4 flex justify-center items-center hover:text-black focus:outline-none`}
            >
              My Experts
            </button>
          </div>
        </div>
        <div className={'my-6'} />
        <div className={'container mx-auto px-4 md:px-16'}>
          <div className={'text-black text-sm'}>
            <span className={'pr-4'}>Not sure which expert is right for you? Let Team Mums &amp; Co find the perfect match.{' '}</span>
            <button
              onClick={() => {
                setShowSuggestionFormModal(true);
              }}
              className={'bg-primary text-white px-2 py-1 rounded text-sm focus:outline-none'}
            >
              Help me choose an expert
            </button>
          </div>
          {/* <div className={`my-6 flex flex-row flex-wrap`}>
            <div
              onClick={handleChangeFilter(null)}
              className={`${allFilter} h-7 md:h-10 mr-6 rounded px-4 flex items-center cursor-pointer mb-4 md:mb-0`}
            >
              All
            </div>
            <ExpertsCategorySubscription />
          </div> */}
          <ExpertsFilters />
          {/* <ExpertsTagSubscription /> */}
          <div className={'flex flex-col md:flex-row md:flex-wrap'}>
            <ExpertConfirmation data={currentResource} />
            {/*<ExpertBookingForm/>*/}
            <ExpertSuggestionForm />
            <ModalSuccess
              title={'Expert session request received'}
              subtitle={`You'll receive a message at ${user_email} from our team to set up the session.`}
            />
            <ExpertsSubscription />
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default withApollo(ExpertsPage);
