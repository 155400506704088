import React, { useContext, useMemo } from 'react';
import { PageContext } from '../../helpers/context';

const PerkDescriptionCard = () => {
  const { perks, heightOfCards } = useContext(PageContext);
  
  return useMemo(() => {
    return <div className={'rounded-lg border shadow-md p-4 bg-white overflow-y-auto'}
      style={{ height: heightOfCards.details }}>
      <div className={'mb-6'}>
        <p className={'text-base html-div'}>
          <div dangerouslySetInnerHTML={{ __html: (perks.description || 'N/A') }}/>
        </p>
      </div>
    </div>;
  }, [heightOfCards]);
};

export default PerkDescriptionCard;
