import React from 'react';
import { ApolloProvider } from 'react-apollo';
//import { initApolloClient } from '../utils/hooks';
import apolloClient from './apollo-client';

const withApollo = (Component) => (renderProps) => {
  let client = apolloClient();
  //initApolloClient(client);
  
  return (
    <ApolloProvider client={client}>
      <Component {...renderProps} client={client}/>
    </ApolloProvider>
  );
};

export default withApollo;
