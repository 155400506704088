import { Checkbox, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { PageContext } from '../../helpers/context';
import { baseAxios } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const ExpertBookingForm = (props) => {
  const { getFieldDecorator, getFieldValue, validateFields, resetFields, getFieldError } = props.form;
  const USER = AUTH();
  const {
    currentResource,
    setShowSuccessModal,
    showBookingFormModal,
    setShowBookingFormModal,
    setCurrentResource,
  } = useContext(PageContext);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    validateFields(async (error, values) => {
      console.log(error);
      if (!error && values.description && values.checkbox) {
        try {
          await baseAxios(
            'POST',
            process.env.REACT_APP_FRESHDESK_DOMAIN,
            {
              name: USER.name,
              email: USER.email,
              subject: `Expert session request for ${currentResource.name}`,
              description: values.description,
              status: 2,
              priority: 1,
            },
            {},
            {
              headers: {
                Authorization: 'Basic ' + new Buffer(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`).toString('base64'),
              },
            },
          );

          resetFields(['description']);
          setCurrentResource({});
          setShowBookingFormModal(false);
          setShowSuccessModal(true);
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  return (
    <Modal
      visible={showBookingFormModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <Form onSubmit={handleOnSubmit}>
        <div className={'text-lg font-grumpy text-black'}>What are your high level queries?</div>
        <p className={'text-xs'}>
          Providing this information in advance will maximise the session and the advice that your expert can provide.
          Once the request is received, we'll set up the session at a mutually convenient time.
        </p>
        {getFieldDecorator('description')(
          <Input.TextArea
            placeholder={'Description'}
            style={{ textDecoration: 'none' }}
            rows={6}
            maxLength={500}
            className={'border mt-5 p-3 w-full rounded bg-white custom-focus-hover h-full'}
          />,
        )}
        <p className={'font-thin mb-1'} style={{ fontSize: '0.625rem' }}>
          {getFieldValue('description') ? 500 - getFieldValue('description').length : '500'} characters remaining{' '}
        </p>
        {getFieldDecorator('checkbox', {
          rules: [{ required: true, message: '* This is required' }],
          valuePropName: 'checked',
        })(
          <Checkbox>
            <div className={'text-black font-grumpy'}>
              I agree <span className={'text-red-500 text-xs'}>{getFieldError('checkbox')}</span>
            </div>
            <p className={'text-xs'}>
              Any business advice provided is general in nature and not tailored to an individual or business. Mums & Co
              and the listed experts will not be held accountable for any business advice provided. By booking the
              session, you consent to Mums & Co recording and storing audio, digital and written materials and content
              from that session ('Content'). The Content will be stored, held and may be used by Mums & Co in accordance
              with its Privacy Policy.
            </p>
          </Checkbox>,
        )}
        <div className={'flex justify-end'}>
          <button
            type={'submit'}
            className={
              'bg-primary text-white text-sm font-light md:text-base px-4 py-1 md:px-6 md:py-2 rounded active:bg-primary focus:outline-none '
            }
          >
            Submit Request
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'form' })(ExpertBookingForm);
