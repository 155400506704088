import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { post } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const subscriptionTypes = {
  community_quarterly: process.env.REACT_APP_COMMUNITY_QUARTERLY,
  community_yearly: process.env.REACT_APP_COMMUNITY_YEARLY,
  standard_quarterly: process.env.REACT_APP_STANDARD_QUARTERLY,
  standard_yearly: process.env.REACT_APP_STANDARD_YEARLY,
  premium_quarterly: process.env.REACT_APP_PREMIUM_QUARTERLY,
  premium_yearly: process.env.REACT_APP_PREMIUM_YEARLY
}

const Payment = (props) => {
  const USER = AUTH();

  useEffect(() => {
    (async () => {
      // return;
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get("type")
        const billed = urlParams.get("billed")
        const redirect = urlParams.get("redirect")
        const free = urlParams.get("free")
        if (type && billed) {
          const price = subscriptionTypes[`${type}_${billed}`]
          const response = await post(`/create-checkout-session/${price}`, {
            redirect_success: `${window.location.origin}/user-subscription?status=success`,
            redirect_fail: `${window.location.origin}/${redirect || ''}`,
            userId: USER.id,
            type: type.toUpperCase(),
            billed,
            free: !!free
          });
          if (!response.data.sessionURL) return props.history.goBack();

          // Proceed with checkout
          window.open(response.data.sessionURL, "_self")
        };
      } catch (e) {
        props.history.goBack();
      }
    })()
  }, [])

  return (
    <div className={'w-full flex justify-center items-center h-full flex-col'}>
      <Spin size={'large'} />
      <p>Please wait...</p>
    </div>
  )
}

export default compose(withRouter, withApollo)(Payment);