import React from 'react';

const SubscriptionDetails = () => {
  return (
    <div className='p-6 md:w-2/3'>
      <div className="elfsight-app-10ef67a8-41b8-4fee-afff-13662c4f6bdc"></div>
      {/* <div className="elfsight-app-663dfead-6350-4286-b2c1-3cd026e27c18"></div> */}
      {/* <div className='mb-4'>
        <h3 className='text-md font-bold tracking-wide'>Upgrading / Downgrading</h3>
        <div className='text-sm'>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>You can always upgrade/downgrade your subscription at any time.</p>
          </div>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>Upon upgrade/downgrade your total payable amount will be prorated based on the current subscription accumulated cost per day.</p>
          </div>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>After upgrade/downgrade, your subscription will be updated and will renew based on the new subscription period.</p>
          </div>
          <p className='my-1 text-xs font-semibold'>Note that any unused previous inclusions will not be added to your new subscription inclusions.</p>
        </div>
      </div>

      <div className='mb-4'>
        <h3 className='text-md font-bold tracking-wide'>Renewal</h3>
        <div className='text-sm'>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>Your subscription will auto-renew after the end of the billing period, unless you cancel the subscription prior to the renewal date</p>
          </div>
        </div>
      </div>

      <div>
        <h3 className='text-md font-bold tracking-wide'>Cancellation</h3>
        <div className='text-sm'>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>You can always cancel your subscription at any time.</p>
          </div>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>Upon cancellation your subscription will still continue until the end of the billing period.</p>
          </div>
          <div className='flex'>
            <p className='mr-2'>• </p>
            <p>After canceling, customers can still renew subscriptions until the billing period ends.</p>
          </div>
          <p className='my-1 text-xs font-semibold'>Note that Mums & Co will retain any subscription fees paid prior to cancellation.</p>
        </div>
      </div> */}

    </div>
  )
}

export default SubscriptionDetails;
