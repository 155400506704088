import React, { useContext, useMemo } from 'react';
import { PageContext } from '../../helpers/context';

const EventDescriptionCard = (props) => {
  const { event, heightOfCards } = useContext(PageContext);
  
  return useMemo(() => {
    return <div className={'rounded-lg shadow-md p-4 bg-white h-full overflow-y-auto'}
      style={{ height: `${heightOfCards.details - (heightOfCards.members + 114)}px`, overflowWrap: 'break-word' }}>
      <div className={'text-base html-div'}>
        <div dangerouslySetInnerHTML={{ __html: (event.description || 'N/A') }}/>
      </div>
    </div>;
  }, [heightOfCards]);
};

export default EventDescriptionCard;
