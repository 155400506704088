import gql from 'graphql-tag';

export const Q_HELP = gql`
    query help{
        help_categories(order_by: {name: asc}){
            id
            name
            description
            icon
            help_category_reasons(order_by: {name: asc}){
                id
                name
            }
        }
    }
`;
