import { Icon, Modal, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../config/with-apollo';
import { M_USER_PROFILE } from '../../graphql/user';
import { AppContext, PageContext } from '../../helpers/context';
import { get } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';

const DeleteAccountConfirmation = (props) => {
  const { client } = props;
  const { setShowDeleteModal, showDeleteModal, setShowSuccessDeleteModal } = useContext(PageContext);
  const { user_tokens } = useContext(AppContext);
  const USER = AUTH();
  const [submitting, setSubmitting] = useState(false);
  
  const handleSubmit = async () => {
    
    try {
      setSubmitting(true);
      await get(`/unsubscribe/${USER.id}?deactivate=1`);
      
      await ({
        mutation: M_USER_PROFILE,
        variables: {
          update: {
            constraint: 'users_pkey',
            update_columns: ['id', 'opt_in', 'opt_in_communications', 'opt_in_marketing_membership', 'opt_in_offers_opportunities', 'status']
          },
          object: {
            id: USER.id,
            username: '',
            name: '',
            password: '',
            opt_in: false,
            opt_in_communications: false,
            opt_in_marketing_membership: false,
            opt_in_offers_opportunities: false,
            status: 'DEACTIVE'
          }
        }
      });
      setShowSuccessDeleteModal(true);
    } catch (e) {
      notification['error']({
        message: 'Delete Profile',
        description:
          'Failed to delete account.',
      });
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <Modal visible={showDeleteModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={'flex flex-col '}>
        <p className={'self-center text-2xl text-black text-center'}>Are you sure you want to delete the account ?</p>
        <p className={'self-center text-lg mb-5 text-center'}>
          You have {user_tokens} tokens balance in your account
        </p>
        <div className={'flex flex-row w-full justify-center'}>
          <button type={'submit'}
            disabled={submitting}
            onClick={() => {setShowDeleteModal(!showDeleteModal);}}
            className={'self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none '}>
            Cancel
          </button>
          <button type={'submit'}
            disabled={submitting}
            onClick={handleSubmit}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${submitting
              ? 'bg-disabled'
              : 'bg-primary'}`}>
            {submitting && <Icon type={'loading'} className={'mr-2'}/>}
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default compose(withApollo, withRouter)(DeleteAccountConfirmation);
