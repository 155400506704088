import { Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../helpers/context';

const HighlightModal = (props) => {
 const { showHighlightModal, setShowHighlightModal } = useContext(AppContext);
 
  const handleClose = () => {
    if (props.redirect) {
      props.redirect();
    } else {
        setShowHighlightModal({...showHighlightModal, show: false});
    }
  };

  return <Modal visible={showHighlightModal && showHighlightModal.show ? showHighlightModal.show : false}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={true}
    onCancel={handleClose}
    width='1000px'
  >
    <div className={'flex flex-col'}>
      <iframe src ={showHighlightModal && showHighlightModal.url ? showHighlightModal.url: ''} className='w-full h-screen/1.5 pt-5'></iframe>
    </div>
  </Modal>;
};

export default HighlightModal;
