import React, { useState, useEffect } from 'react';
import { Subscription } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import EventCard from '../../components/cards/event-card';
import EventCommentCard from '../../components/cards/event-comments-card';
import EventDescriptionCard from '../../components/cards/event-description-card';
import EventDetailsCard from '../../components/cards/event-details-card';
import EventMembersCard from '../../components/cards/event-members-card';
import EventBookingCancel from '../../components/modals/event-booking-cancel';
import EventBookingConfirmation from '../../components/modals/event-booking-confirmation';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { S_EVENT } from '../../graphql/events';
import { PageContext } from '../../helpers/context';
import { useUrlSearchParams } from "use-url-search-params";
import moment from 'moment-timezone';

const EventDetailsPage = (props) => {
  const ID = props.match.params.id;
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModalAttendees, setShowModalAttendees] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [queries, setQueries] = useUrlSearchParams({}, {});
  const [heightOfCards, setHeightCards] = useState({
    members: 0,
    details: 0
  });
  
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  
  const handleBackToList = () => {
    props.history.push('/events');
  };
  
  return <div className={'min-h-screen bg-light-gray'}>
    <Subscription subscription={S_EVENT} variables={{ id: ID }}>
      {({ loading, error, data }) => {
        if (loading) return <></>;
        
        if (data.events[0] === null) {
          props.history.goBack();
          return;
        }
        
        const event = data.events[0];
        
        return (
          <PageContext.Provider value={{
            event,
            showModal,
            setShowModal,
            showCancelModal,
            setShowCancelModal,
            setShowModalAttendees,
            showModalAttendees,
            setHeightCards,
            heightOfCards,
            currentEvent,
            setCurrentEvent,
            setShowSuccessModal,
            showSuccessModal,
            queries,
            setQueries
          }}>
            <EventBookingConfirmation data={event}/>
            <ModalSuccess title={'Event RSVP'} subtitle={'You have successfully booked a seat in this event.'}/>
            <EventBookingCancel data={event}/>
            <div className={'px-5 md:px-16 pt-10 container mx-auto'}>
              <span className={'text-base md:text-lg text-primary'}><a className={'hover:text-primary'}
                onClick={handleBackToList}>Events</a>&nbsp; {'>'} &nbsp;</span>
              <span className={'text-base md:text-lg text-black'}>{event.name}</span>
            </div>
            <div className={'p-5 container mx-auto md:px-16'}>
              <div className={'md:flex md:flex-row md:items-stretch'}>
                <div className={'mb-8 md:w-3/4 md:pr-10'}>
                  <div className={'md:hidden'}>
                    <EventCard/>
                  </div>
                  <div className={'hidden md:block'}>
                    <EventDetailsCard/>
                  </div>
                </div>
                <div className={'md:w-1/3 mb-8 h-full'}>
                  <div className={'mb-8'}>
                    <EventMembersCard/>
                  </div>
                  <div className={'h-full'}>
                    <EventDescriptionCard/>
                  </div>
                </div>
              </div>
              <EventCommentCard/>
            </div>
          </PageContext.Provider>
        );
      }}
    </Subscription>
  </div>;
};

export default withRouter(withApollo(EventDetailsPage));
