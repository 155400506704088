import { useQuery } from '@apollo/react-hooks';
import classname from 'classnames';
import React, { useEffect, useState } from 'react';
import { useUrlSearchParams } from "use-url-search-params";
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import EventBookingCancel from '../../components/modals/event-booking-cancel';
import EventBookingConfirmation from '../../components/modals/event-booking-confirmation';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { Q_USER_BUSINESS_GOALS_TAGS } from '../../graphql/business-goals';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import EventFilters from './event-filters';
import EventSubscription from './event-subscription';

const EventPage = (props) => {
  const { client } = props;
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [queries, setQueries] = useUrlSearchParams({}, {});
  const [filter, setFilter] = useState({
    events: null,
    event_type: null,
    is_related: false,
    pillar: 'All',
    category: null,
    keyword: null,
    tags: [],
  });
  const [introText, setIntroText] = useState();
  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);

  const USER = AUTH();
  const { data: userBusinessGoals } = useQuery(Q_USER_BUSINESS_GOALS_TAGS, { client, variables: { userId: USER.id } });

  useEffect(() => {
    // Populate user goals tags for filter
    if (!userBusinessGoals) return;

    const userTags = userBusinessGoals.user_business_goals_tags || [];
    setFilter(state => ({ ...state, tags: userTags.map(tags => tags.business_goals_tag.id) }))
  }, [userBusinessGoals]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeFilter = (id) => () => {
    // add change filter flag for related tags
    setFilter(oldState => ({ ...oldState, pillar: id }));
  };

  const handleGetMyEvents = (e) => {
    e.preventDefault();
    setFilter(oldState => ({
      ...oldState,
      events: { attendees: { status: { _eq: 1 }, user_id: { _in: [USER.id] } } }
    }));
  };

  const handleGetAllEvents = (e) => {
    e.preventDefault();
    setFilter(oldState => ({
      ...oldState,
      events: null
    }));
  };

  const isActive = (value) => {
    let active = classname(
      {
        'bg-black text-white': value.id === filter.event_type,
        'bg-light-gray-header text-black': value.id !== filter.event_type
      }
    );
    return active;
  }

  return <PageContext.Provider value={{
    showModal,
    setShowModal,
    showCancelModal,
    setShowCancelModal,
    introText,
    setCurrentEvent,
    currentEvent,
    filter,
    setFilter,
    setIntroText,
    handleChangeFilter,
    showSuccessModal,
    setShowSuccessModal,
    queries,
    setQueries,
    isBookNow,
    setBookNow,
    isRequest,
    setRequest
  }}>
    <div className={'min-h-screen bg-light-gray'}>
      <HeaderCoverBanner1 title={`Connect, upskill and harmonise with your Mums & Co events`}
        subtitle={`Search all events, filter by pillar or just the ones relevant to your Personal Action Plan focus areas`} />
      <HeaderCoverBanner1Mobile title={'Connect, upskill and harmonise with your Mums & Co events'}
        subtitle={`Search all events, filter by pillar or just the ones relevant to your Personal Action Plan focus areas`} />
      <div className={'bg-light-gray-header'}>
        <div className={'container mx-auto  flex items-end h-10 md:h-15 px-4 md:px-16'}>
          <a href="/#" onClick={handleGetAllEvents}
            className={`${!filter.events &&
              'rounded-t bg-white'} h-7.5 md:h-12 w-28.5 flex justify-center items-center hover:text-black`}>
            Active events
          </a>
          <a href="/#" onClick={handleGetMyEvents}
            className={`${filter.events &&
              'rounded-t bg-white'} h-7.5 md:h-12 w-28.5 flex justify-center items-center  hover:text-black`}>
            My events
          </a>
        </div>
      </div>
      <EventFilters />

      <div className={'container mx-auto px-4 md:px-16'}>
        <div className={'flex flex-col md:flex-row md:flex-wrap'}>
          <EventBookingConfirmation data={currentEvent} />
          <EventBookingCancel data={currentEvent} />
          <ModalSuccess title={'Event RSVP'} subtitle={'You have successfully booked a seat in this event.'} />
          <EventSubscription />
        </div>
      </div>
    </div>
  </PageContext.Provider>;
};

export default withApollo(EventPage);
