import { useQuery } from '@apollo/react-hooks';
import { Modal } from 'antd';
import React, { useContext } from 'react';
import bigCheckIcon from '../../assets/icons/big-check-icon.png';
import {
  Q_REGISTER_VIDEO
} from '../../graphql/dashboard';
import { AppContext, PageContext } from '../../helpers/context';
const videoLoading = (data) =>
  (data && data.dashboard_video && data.dashboard_video[0] && data.dashboard_video[0].video_id)
    ?
    (<iframe
      title="vid"
      className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-black`}
      src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${data.dashboard_video[0].video_id}?autoplay=1&loop=1&autopause=0&muted=0`}
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen />) : (<></>)

const SendVerificationSuccess = (props) => {
  const { showSuccessModal, setShowSuccessModal } = useContext(props.appContext ? AppContext : PageContext);
  const { data: registerVideoData } = useQuery(Q_REGISTER_VIDEO, { client: props.client });
  const handleClose = () => {
    if (props.redirect) {
      props.redirect();
    } else {
      setShowSuccessModal(!showSuccessModal);
    }
  };

  const buttonText = () => {
    if (props.buttonText) {
      return props.buttonText;
    }

    return props.redirect ? 'Back to previous page' : 'Close';
  };

  return <Modal visible={showSuccessModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={false}
  >
    <div className={'flex flex-col'}>
      <img src={bigCheckIcon} alt={''} className={'self-center w-24 mb-6'} />
      <p className={'self-center text-center text-3xl text-black'}>{(props.title || '')}</p>
      <p className={'self-center text-center text-lg mb-5'}>
        {props.subtitle}
      </p>
      {videoLoading(registerVideoData)}
      <button
        onClick={handleClose}
        className={'self-center bg-primary text-white h-10 text-lg mt-1 px-4 font-light  rounded active:bg-primary focus:outline-none '}>
        {buttonText()}
      </button>
    </div>
  </Modal>;
};

export default SendVerificationSuccess;
