import React, { useEffect, useState } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { PageContext } from '../../../helpers/context';

const SubscriptionPage = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [discountVisible, setDiscountVisible] = useState(false);
  const [errorDiscountVisible, setErrorDiscountVisible] = useState(false);
  const [discountCode, setDiscountCode] = useState(null);
  const [subInterval, setSubInterval] = useState('yearly');
  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetDiscountVisible = () => {
    setDiscountVisible(!discountVisible);
  };

  const handleSetErrorDiscountVisible = () => {
    setErrorDiscountVisible(!errorDiscountVisible)
  };

  const handleSetDiscountCode = (e) => {
    setDiscountCode(e.target.value);
  };

  return (
    <PageContext.Provider value={{
      showSuccessModal,
      setShowSuccessModal,
      showErrorModal,
      setShowErrorModal,
      discountVisible,
      discountCode,
      handleSetDiscountVisible,
      handleSetDiscountCode,
      handleSetErrorDiscountVisible,
      errorDiscountVisible,
      subInterval,
      setSubInterval,
      isBookNow,
      setBookNow,
      isRequest,
      setRequest
    }}>
      <div className={'min-h-screen bg-light-gray pb-10'}>
        <div className={'container mx-auto px-2 pt-10'}>
          {!!props.location.state && <div className={'bg-light-gray-header px-4 pt-4 pb-0 mb-6 border rounded-lg'}>
            <p className={'text-lg font-grumpy text-red-500'}>Warning - Low Balance !</p>
            <p className={'text-black mb-3'}>
              You have less than {props.location.state.amount} tokens in your account.
            </p>
          </div>}
          <div className="elfsight-app-b71385eb-73e7-429c-8c88-1e3dbf2c20d1"></div>
          <div className="elfsight-app-10ef67a8-41b8-4fee-afff-13662c4f6bdc"></div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default compose(withRouter, withApollo)(SubscriptionPage);
