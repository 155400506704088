import { useQuery } from '@apollo/react-hooks';
import classname from 'classnames';
import React, { useEffect, useState } from 'react';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import DigitalLibraryConfirmation from '../../components/modals/digital-library-confirmation';
import ModalSuccess from '../../components/modals/modal-success';
import withApollo from '../../config/with-apollo';
import { Q_USER_BUSINESS_GOALS_TAGS } from '../../graphql/business-goals';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';
import Filters from './digital-library-filters';
import DigitalLibrarySubscription from './digital-library-subscription';

const DigitalLibraryPage = (props) => {
  const { client } = props;
  const USER = AUTH();
  const { data: userBusinessGoals } = useQuery(Q_USER_BUSINESS_GOALS_TAGS, { client, variables: { userId: USER.id } });
  const [showModal, setShowModal] = useState(false);
  const [currentResource, setCurrentResource] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isBookNow, setBookNow] = useState(false);
  const [isRequest, setRequest] = useState(false);
  const [introText, setIntroText] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filter, setFilter] = useState({
    myResources: false,
    resource_category_id: null,
    is_related: false,
    pillar: 'All',
    category: null,
    keyword: null,
    tags: [],
  });

  const [heightOfCards, setHeightCards] = useState({
    details: 0
  });

  useEffect(() => {
    // Populate user goals tags for filter
    if (!userBusinessGoals) return;

    const userTags = userBusinessGoals.user_business_goals_tags || [];
    setFilter(state => ({ ...state, tags: userTags.map(tags => tags.business_goals_tag.id) }))
  }, [userBusinessGoals]);

  const handleChangeFilter = (id) => () => {
    setFilter(oldState => ({ ...oldState, resource_category_id: id }));
  };

  const handleGetMyResources = () => {
    setFilter(oldState => ({
      ...oldState,
      myResources: true
    }));
  };

  const handleGetAllResources = () => {
    setFilter(oldState => ({
      ...oldState,
      myResources: false
    }));
  };

  return <PageContext.Provider value={{
    heightOfCards,
    setHeightCards,
    currentResource,
    setCurrentResource,
    showModal,
    setShowModal,
    filter,
    introText,
    setIntroText,
    setFilter,
    handleChangeFilter,
    showSuccessModal,
    setShowSuccessModal,
    isBookNow,
    setBookNow,
    isRequest,
    setRequest
  }}>
    <div className={'min-h-screen bg-light-gray'}>
      <HeaderCoverBanner1 title={'Resources to help you upskill all in one place'}
        subtitle={'\n' +
          'Your digital resource library gives you tools & resources you need to support your business\n'} />
      <HeaderCoverBanner1Mobile title={'Resources to help you upskill all in one place'}
        subtitle={'\n' +
          'Your digital resource library gives you tools & resources you need to support your business\n'} />
      <div className={' bg-light-gray-header'}>
        <div className={'container mx-auto  flex items-end h-10 md:h-15 px-4 md:px-16'}>
          <a onClick={handleGetAllResources}
            className={`${!filter.myResources &&
              'rounded-t bg-white'} h-7.5 md:h-12 p-4 flex justify-center items-center hover:text-black`}>
            Available Resource
          </a>
          <a onClick={handleGetMyResources}
            className={`${filter.myResources &&
              'rounded-t bg-white'} h-7.5 md:h-12 p-4 flex justify-center items-center  hover:text-black`}>
            My Resource
          </a>
        </div>
      </div>
      <div className={'my-10'} />

      <div className={'container mx-auto px-4 md:px-16'}>
        <Filters />
        <div className={'flex flex-col md:flex-row md:flex-wrap'}>
          <DigitalLibraryConfirmation data={currentResource} />
          <ModalSuccess title={'Access Library'} subtitle={'You can now access this resource. Enjoy!'} />
          <DigitalLibrarySubscription />
        </div>
      </div>
    </div>
  </PageContext.Provider>;
};

export default withApollo(DigitalLibraryPage);
