import React from 'react';
import { compose, Subscription } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import HeaderCoverBanner1 from '../../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../../components/header/header-cover-banner-mobile';
import withApollo from '../../../config/with-apollo';
import { S_USER_TOKENS } from '../../../graphql/user-tokens';
import { AUTH } from '../../../utils/common';
import UserTokenRow from './user-token-row';

const TokenHistory = (props) => {
  const USER = AUTH();
  
  const handleBackToList = () => {
    props.history.push('/profile/me');
  };
  
  return <div className={'bg-light-gray pb-20'} style={{ minHeight: '100vh' }}>
    <HeaderCoverBanner1 title={'View Token History'}
      subtitle={'Here is where you can view a record of where and when you spent your membership tokens.'}/>
    <HeaderCoverBanner1Mobile title={'View Token History'}
      subtitle={'Here is where you can view a record of where and when you spent your membership tokens.'}/>
    <div className={'px-5 md:px-16 pt-10 container mx-auto'}>
      <div className={'pb-5'}>
        <span className={'text-base md:text-lg text-primary'}>
          <a className={'hover:text-primary'} onClick={handleBackToList}>Profile</a>
          &nbsp; > &nbsp;
        </span>
        <span className={'text-base md:text-lg text-black'}>Token History</span>
      </div>
      <div className={'pb-5'}>
        <p className={'font-grumpy text-bold text-xl md:text-2xl text-black'}>Token History</p>
      </div>
      <table className={'w-full'}>
        <thead className={'bg-primary text-white text-sm md:text-base'}>
        <tr>
          <td className={'py-2 md:py-4 pl-4 pr-4 md:pr-8 md:pl-6 whitespace-no-wrap'}
            style={{ width: '1px' }}>Date
          </td>
          <td className={'py-2 md:py-4 whitespace-no-wrap w-3/5'}>Reason</td>
          <td className={'py-2 md:py-4 '}>Tokens</td>
          <td className={'py-2 md:py-4 text-right pr-4 md:pr-6 '}>By</td>
        </tr>
        </thead>
        <tbody className={'text-xs md:text-sm'}>
        <Subscription subscription={S_USER_TOKENS} variables={{ id: USER.id }}>
          {({ loading, error, data }) => {
            if (loading) return <></>;
            
            return (
              data.user_tokens.map((value, key) => <UserTokenRow value={value} rowKey={key}/>)
            );
          }}
        </Subscription>
        </tbody>
      </table>
    </div>
  </div>;
};

export default compose(withRouter, withApollo)(TokenHistory);

