import { useSubscription } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import DigitalLibraryCard from '../../components/cards/digital-library-card';
import withApollo from '../../config/with-apollo';
import { S_DIGITAL_LIBRARIES } from '../../graphql/digital-libraries';
import { S_USER_TRANSACTIONS } from '../../graphql/user';
import { PageContext } from '../../helpers/context';
import { AUTH } from '../../utils/common';

const DigitalLibrarySubscription = (props) => {
  const USER = AUTH();
  const { filter, setIntroText } = useContext(PageContext);

  const keywordFilter = useMemo(() => {
    const queryFilter = {}
    if (filter.keyword && filter.keyword.trim()) {
      queryFilter._or = [
        { title: { _ilike: `%${filter.keyword.trim()}%` } },
        { presenter: { _ilike: `%${filter.keyword.trim()}%` } },
        { digital_library_business_goals_tags: { business_goals_tag: { name: { _ilike: `%${filter.keyword.trim()}%` } } } }
      ];
    }
    return queryFilter;
  }, [filter.keyword]);

  const tagsFilter = useMemo(() => {
    const queryFilter = {};
    if (filter.is_related) queryFilter.business_goals_tag_id = { _in: filter.tags };
    if (filter.pillar !== 'All') queryFilter.pillars_id = { _eq: filter.pillar };
    if (filter.category) queryFilter.categories_id = { _eq: filter.category };
    return !isEmpty(queryFilter) ? queryFilter : undefined;
  }, [filter]);

  const { loading, error, data } = useSubscription(S_DIGITAL_LIBRARIES, {
    client: props.client, variables: {
      where: {
        ...keywordFilter,
        digital_library_business_goals_tags: tagsFilter,
        digital_library_to_categories: filter.resource_category_id
          ? { digital_library_category_id: { _eq: filter.resource_category_id } }
          : undefined,
        resource_end_date: { _gte: 'now()' }
      }
    }
  });

  const { loading: loadingTransactions, data: dataTransactions } = useSubscription(S_USER_TRANSACTIONS,
    {
      client: props.client,
      variables: {
        where: {
          user_id: {
            _eq: USER.id
          },
          transaction: {
            _contains: {
              type: process.env.REACT_APP_DIGITAL_LIBRARY_KEY
            }
          }
        }
      }
    });

  useEffect(() => {
    if (loading) return setIntroText(0);

    if (((data || {}).digital_library || []).length) {
      setIntroText(1);
    } else {
      setIntroText(2);
    }
  }, [data, loading])

  if (loading) {
    return (
      <div className={'w-full flex justify-center items-center h-full flex-col'}>
        <Spin size={'large'} />
        <p>Getting resources</p>
      </div>
    );
  }



  return !loading && !loadingTransactions && ((data || {}).digital_library || []).map(value => {

    let hasTransaction = dataTransactions.user_token_transaction.find(transaction => {
      return transaction.transaction.transaction_id === value.id;
    });

    if (!filter.myResources || !!hasTransaction) {
      return <div className={`md:pr-10 pb-7 sm:w-full md:w-full lg:w-1/2 xl:w-4/12 h-full`}>
        <DigitalLibraryCard data={value} hasTransaction={!!hasTransaction} />
      </div>;
    }
  });
};

export default compose(withApollo)(DigitalLibrarySubscription);
