import { Icon } from 'antd';
import React, { useContext } from 'react';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import { PageContext } from '../../helpers/context';
import MemberFilters from './member-filters';
import MemberCard from './user-card';

const MemberDirectoryPresentation = () => {
  const {
    data,
    introText,
    setOffset,
    loadingUsersList
  } = useContext(PageContext);
  const IntroText = () => {
    switch (introText) {
      case 1:
        return (
          <div className={'mb-5 bg-light-gray-header p-3 rounded'}>
            <p className={'text-black'}>
              <span className={'text-base font-bold'}>
                Here's a listing of member businesses that match your keywords. View profiles and easily send a message
                - they would love to connect with you!
                <br />
              </span>
              <span className={'italic'}>
                Not what you are looking for? Refine your keywords to get more specific information{' '}
              </span>
            </p>
          </div>
        );
      case 2:
        return (
          <div className={'mb-5 bg-light-gray-header p-3 rounded'}>
            <p className={'text-black'}>
              <span className={'text-base font-bold'}>
                Oops, we did not find any business which matches your keywords!
                <br />
              </span>
              <span className={'italic'}>
                Refine your keywords perhaps? There are hundreds of business mums in the community, waiting to connect
                with you.{' '}
              </span>
            </p>
          </div>
        );
      case 3:
        return (
          <div className={'mb-5 bg-light-gray-header p-3 rounded'}>
            <p className={'text-black'}>
              <span className={'text-base font-bold'}>
                Here's a sneak peek of a few businesses from the community. View profiles and easily send a message -
                they would love to connect with you!
                <br />
              </span>
              <span className={'italic'}>
                Search by keywords to get tailored business listings to suit your needs.{' '}
              </span>
            </p>
          </div>
        );
      case 0:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className={'min-h-screen bg-light-gray'}>
      <HeaderCoverBanner1
        title={`Network & Grow your business with community connections`}
        subtitle={`You could find your next collaborator, business bestie or freelancer right here in your directory of business owning women`}
      />
      <HeaderCoverBanner1Mobile
        title={'Network & Grow your business with community connections'}
        subtitle={`You could find your next collaborator, business bestie or freelancer right here in your directory of business owning women`}
      />
      <div className={'container mx-auto px-4 md:px-16'}>
        <MemberFilters />
        <IntroText />
        <div className={'grid md:gap-10 md:grid-cols-2 gap-5 grid-cols-1 pb-5'}>
          {
            data.map((value, i) => (
              <MemberCard key={value.id + i.toString()} {...value} />
            ))
          }
        </div>
        {
          !!data.length && data.length % 20 === 0 &&
          <div className='flex justify-center items-center w-full py-8'>
            <button
              className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${loadingUsersList
                ? 'bg-disabled'
                : 'bg-primary'}`}
              onClick={() => setOffset(state => state + 20)}
              disabled={loadingUsersList}
            >{loadingUsersList && <Icon type={'loading'} className={'mr-2'} />}
              Show More</button>
          </div>
        }

      </div>
    </div>
  );
};

export default MemberDirectoryPresentation;
