import React, { useContext } from 'react';
import { PageContext } from '../../helpers/context';

const DigitalLibraryDescriptionCard = (props) => {
  const { loading, data, heightOfCards } = useContext(PageContext);
  
  return <div className={'rounded-lg shadow-md p-4 bg-white overflow-y-auto'} style={{ height: heightOfCards.details }}>
    <p className={'text-base html-div'}>
      {!loading &&
      <div dangerouslySetInnerHTML={{ __html: (data.digital_library_by_pk.detailed_description || 'N/A') }}/>}
    </p>
  </div>;
};

export default DigitalLibraryDescriptionCard;
