import gql from 'graphql-tag';

const F_USER_DETAILS = (name, model, additionalFields = []) => {
  let gqlTag = `
        fragment ${name} on ${model}{
            user{
                id
                name
                avatar
                ${[...additionalFields]}
                businesses(order_by: {order: asc}){
                    id
                    name
                }
            }
        }
   `;
  
  return gql`${gqlTag}`;
};

export const S_DIGITAL_LIBRARIES = gql`
    subscription digitalLibraries($where: digital_library_bool_exp){
        digital_library(order_by: {created_at: desc}, where: $where){
            id
            title
            presenter
            presenter_sub_title
            presenter_logo
            resource_end_date
            cost
            video_id
            pdf_cover
            pdf_size
            subscription_type
            digital_library_reviews_aggregate{
                aggregate{
                    avg{
                        rating
                    }
                    count
                }
            }
            digital_library_business_goals_tags {
                business_goals_tag_id
                categories_id
                digital_library_id
                pillars_id
                pillar {
                    color
                    name
                    id
                }
                business_goals_tag {
                    id
                    name
                }
            }
        }
    }
`;

export const S_DIGITAL_LIBRARY = gql`
    subscription digitalLibrary($id: uuid!){
        digital_library_by_pk(id: $id){
            id
            title
            presenter
            presenter_sub_title
            presenter_logo
            resource_end_date
            cost
            video_id
            detailed_description
            pdf_cover
            pdf_size
            subscription_type
            digital_library_reviews_aggregate{
                aggregate{
                    avg{
                        rating
                    }
                    count
                }
            }
        }
    }
`;

export const S_DIGITAL_LIBRARY_REVIEWS = gql`
    ${F_USER_DETAILS('reviewer_user_details','digital_library_reviews')}
    subscription digitalLibraryReviews($digitalLibraryId: uuid!){
        digital_library_reviews(where:{digital_library_id:{_eq:$digitalLibraryId}}, order_by:{created_at: desc}){
            id
            rating
            review
            user_id
            created_at
            ...reviewer_user_details
        }
    }
`;

export const M_DIGITAL_LIBRARY_REVIEW = gql`
    mutation digitalLibraryReview($objects:[digital_library_reviews_insert_input!]!){
        insert_digital_library_reviews(objects: $objects){
            returning{
                id
            }
        }
    }
`;

export const S_DIGITAL_LIBRARY_CATEGORIES = gql`
    subscription digitalLibraryCategories{
        digital_library_categories(order_by: {created_at: desc}){
            id
            name
        }
    }
`;

export const Q_DIGITAL_LIBRARY_GET_PDF_LINK = gql`
    query digitalLibraryPdfLink($id: uuid!){
        digital_library_by_pk(id: $id){
            id
            pdf_link
        }
    }
`;

