import { Dropdown, Layout, Menu } from 'antd';
import 'antd/es/dropdown/style/css';
import 'antd/es/icon/style/css';
import 'antd/es/menu/style/css';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import accessLibraryActive from '../../assets/icons/access-library-active.png';
import accessLibrary from '../../assets/icons/access-library.png';
import bookNowActive from '../../assets/icons/book-now-active.png';
import bookNow from '../../assets/icons/book-now.png';
import getAdviceActive from '../../assets/icons/get-advice-active.png';
import getAdvice from '../../assets/icons/get-advice.png';
import helpActive from '../../assets/icons/help-active.png';
import help from '../../assets/icons/help.png';
import memberDirectory from '../../assets/icons/member-directory-1.png';
import memberDirectoryActive from '../../assets/icons/member-directory-active.png';
import messages from '../../assets/icons/messages.png';
import messagesActive from '../../assets/icons/messages-active.png';
import mailIcon from '../../assets/icons/menu-chat-icon.png';
import logoutIcon from '../../assets/icons/menu-logout-icon.png';
import setting from '../../assets/icons/menu-setting-icon.png';
import tokenHistory from '../../assets/icons/menu-token-icon.png';
import logo from '../../assets/icons/mums_and_co_logo.png';
import activeMyHome from '../../assets/icons/my-home-active.png';
import myHome from '../../assets/icons/my-home.png';
import userMobile from '../../assets/icons/user-mobile.png';
import { AppContext } from '../../helpers/context';

const { Header } = Layout;

const SecondaryHeader = (props) => {
  const CURRENT_URL = props.location.pathname.split('/')[1];
  const { user_avatar, user_status } = useContext(AppContext);
  const [menuClicked, setMenuClicked] = useState(false);

  const logout = () => {
    sessionStorage.removeItem('mumsnco-init');
    sessionStorage.removeItem('mumsnco-returning-url');
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    localStorage.removeItem('mums-and-co-has-login');
    sessionStorage.removeItem('mumsnco-returning-url');
    window.location.href = '/';
  };

  const handleGoToPage = (route) => () => {
    props.history.push(route);
    window.scrollTo(0, 0);
  };

  const setBorderAvatar = () => {
    setTimeout(() => {
      setMenuClicked(!menuClicked);
    }, 50);
  };
  const unreadMessage = true;

  const pendingMenu = (
    <Menu className={'pt-8'} style={{ bottom: '32px' }} onClick={setBorderAvatar}>
      <Menu.Item className={'flex flex-row items-center'} onClick={logout}>
        <img className={'w-5 h-auto mr-3'} src={logoutIcon} alt="" />
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  )

  const menu = user_status === 'PENDING' ? pendingMenu : (
    <Menu className={'pt-8'} style={{ bottom: '32px' }} onClick={setBorderAvatar}>
      <Menu.Item className={'flex flex-row items-center'} onClick={handleGoToPage('/profile/me')}>
        <img className={'w-5 h-5 mr-3'} src={userMobile} alt="" />
        <a>Profile</a>
      </Menu.Item>
      <Menu.Item className={'flex flex-row items-center'} onClick={handleGoToPage('/profile/token-history')}>
        <img className={'w-5 h-auto mr-3'} src={tokenHistory} alt="" />
        <a>Token History</a>
      </Menu.Item>
      <Menu.Item className={'flex flex-row items-center'} onClick={handleGoToPage('/messages')}>
        <img className={'w-5 h-auto mr-3'} src={mailIcon} alt="" />
        <a>Messages</a>
      </Menu.Item>
      <Menu.Item className={'flex flex-row items-center'} onClick={handleGoToPage('/profile/settings')}>
        <img className={'w-5 h-5 mr-3'} src={setting} alt="" />
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item className={'flex flex-row items-center'} onClick={logout}>
        <img className={'w-5 h-auto mr-3'} src={logoutIcon} alt="" />
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className={'fixed top-0 w-full mt-13 md:mt-0 z-40 flex justify-between items-center bg-primary h-13 px-8 py-3 md:justify-around md:h-20'}
      id={'image-container-secondary'}>
      <div className={'flex justify-center hidden md:block md:w-1/8 lg:w-1/4 cursor-pointer z-50'}>
        <Dropdown overlay={menu}
          trigger={['click']}
          onClick={setBorderAvatar}
          onVisibleChange={setBorderAvatar}
          overlayStyle={{ zIndex: 40 }}
          getPopupContainer={() => document.getElementById('image-container-secondary')}
          className="user_menu_tour_class"
        >
          {typeof user_avatar !== 'function' ? (
            <div
              className={`bg-center bg-no-repeat bg-cover bg-black hidden md:inline-block rounded-full relative h-12 w-12 z-50 ${menuClicked
                ? 'avatar-menu'
                : 'avatar-menu-inactive'}`}
              style={{ backgroundImage: `url(${user_avatar})` }} />
          ) : (
            user_avatar(
              `flex items-center justify-center font-grumpy text-event-going text-2xl hidden md:inline-flex bg-white rounded-full h-12 w-12 z-50 ${menuClicked
                ? 'avatar-menu'
                : 'avatar-menu-inactive'}`)
          )}
        </Dropdown>
      </div>
      <div className={'flex-auto flex justify-between hidden md:block md:w-1/8 lg:w-1/4 logo_header_tour_class'}>
        <img onClick={handleGoToPage('/dashboard')}
          alt={''}
          src={logo}
          className={'hidden md:inline-block w-18 cursor-pointer'} />
      </div>
      {user_status !== 'PENDING' &&
        <div className={'flex-auto flex justify-between items-center md:w-3/4'}>
          <a className={'hidden md:block md:w-1/6 md:text-center help_header_tour_class'} onClick={handleGoToPage('/help')}>
            <img alt={''} src={CURRENT_URL === 'help' ? helpActive : help} className={'m-auto w-6'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Help</p>
          </a>
          <a className={'md:w-1/6 md:text-center home_header_tour_class'} onClick={handleGoToPage('/dashboard')}>
            <img alt={''} src={CURRENT_URL === 'dashboard' ? activeMyHome : myHome} className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Home</p>
          </a>
          <a className={'md:w-1/6 md:text-center'} onClick={handleGoToPage('/messages')}>
            <img alt={''} src={CURRENT_URL === 'messages' ? messagesActive : messages} className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Messages</p>
          </a>
          <a className={'md:w-1/6 md:text-center book_header_tour_class'} onClick={handleGoToPage('/events')}>
            <img alt={''} src={CURRENT_URL === 'events' ? bookNowActive : bookNow} className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Events</p>
          </a>
          <a className={'md:w-1/6 md:text-center access_directory_header_tour_class'} onClick={handleGoToPage('/member-directory')}>
            <img alt={''} src={CURRENT_URL === 'member-directory' ? memberDirectoryActive : memberDirectory}
              className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Community</p>
          </a>
          <a className={'md:w-1/6 md:text-center access_library_header_tour_class'} onClick={handleGoToPage('/digital-library')}>
            <img alt={''}
              src={CURRENT_URL === 'digital-library' ? accessLibraryActive : accessLibrary}
              className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Digital Resources</p>
          </a>
          <a className={'md:w-1/6 md:text-center advice_header_tour_class'} onClick={handleGoToPage('/get-advice')}>
            <img alt={''}
              src={CURRENT_URL === 'get-advice' ? getAdviceActive : getAdvice}
              className={'m-auto w-auto h-5'} />
            <p className={'text-white font-thin hidden md:text-xs md:block md:mt-2 lg:text-sm'}>Experts</p>
          </a>
        </div>
      }
    </Header >
  );
};

export default withRouter(SecondaryHeader);
