import { Form } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import coverLogin from '../../assets/icons/cover-login.png';
import mumsAndCoLogo from '../../assets/icons/mums_and_co_logo_login.png';
import { post } from '../../utils/api-handler';
import { FORGOT_PASSWORD_URL } from '../../utils/url-handler';

const ForgotPasswordMessage = (props) => {
  const { history, location } = props;
  
  useEffect(() => {
    if (!location.params) {
      history.push('/forgot-password');
    }
  }, [location.params]);
  
  const handleResend = async () => {
    try {
      await post(FORGOT_PASSWORD_URL, { email: location.params.email });
    } catch (e) {
      console.log(e.response);
    }
  };
  
  return <>
    <div className={'h-full bg-white lg:flex lg:flex-row-reverse'}>
      <div className={'lg:w-3/4 bg-primary justify-between flex flex-col pt-20 lg:pt-0'}>
        <div className={'w-full flex flex-row z-10 lg:inline-flex hidden '}>
          <div className={'md:w-full flex items-center justify-center'}>
            <div className={'text-white text-2xl px-10 xl:px-40 mt-44 flex flex-col font-thin'}>
            <span><span className={'font-grumpy text-3xl'}>"</span>{'  '}When you have a dream you have to work hard to achieve that dream. Your dreams when you are young can be the force that keeps you going.<span
              className={'font-grumpy text-3xl'}>"</span></span>
              <span className={'text-right italic'}>- Evonne Goolagong Cawley</span>
            </div>
          </div>
        </div>
        <img alt={''} src={coverLogin} className={'w-full h-auto'}/>
      </div>
      <div className={'flex flex-col mt-4 px-10 md:px-40 lg:mt-40 lg:px-28 lg:w-3/4 xl:w-1/3'}>
        <Form>
          <img alt={''} src={mumsAndCoLogo} className={'m-auto mb-10'}/>
          <p className={'text-sm text-gray-500  text-center'}>
            An email to reset your password has been sent.
          </p>
          <p className={'text-sm text-gray-500 mt-5 text-center'}>
            Please check your email in the next few minutes.
          </p>
          <button onClick={handleResend}
            className={'bg-primary text-white  h-10 w-full text-sm mt-10 font-grumpy font-light rounded-full focus:outline-none'}>
            Resend Verification Email
          </button>
        </Form>
      </div>
    </div>
  </>;
};

export default withRouter(ForgotPasswordMessage);
