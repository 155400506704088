import gql from 'graphql-tag';

export const S_PERKS = gql`
    subscription perks($categoryId: uuid){
        perks(where: {
            active: {_eq: true},
            start_date: {_is_null: false},
            end_date: {_is_null: false},
            perks_to_categories : {
                perk_category_id :{_eq:  $categoryId}
            }
        }){
            id
            name
            discount
            is_featured
            start_date
            end_date
            discount_description
            image
        }
    }
`;

export const S_PERK = gql`
    subscription perk($id: uuid!){
        perks_by_pk(id: $id){
            id
            name
            discount
            is_featured
            redemption_code
            description
            discount_description
            perk_url
            attachment
            attachment_name
            attachment_size
            image
        }
    }
`;

export const S_PERK_CATEGORIES = gql`
    subscription perkCategories{
        perk_categories(order_by: {created_at: desc}){
            id
            name
        }
    }
`;

export const M_PERKS_LOG = gql`
    mutation perkLog($objects: [perks_logs_insert_input!]!){
        insert_perks_logs(objects: $objects){
            returning{
                id
            }
        }
    }
`;
