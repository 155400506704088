import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { post } from '../../utils/api-handler';
import { USER_VERIFY_URL } from '../../utils/url-handler';

const Verify = (props) => {
  const { location } = props;
  
  let params = new URLSearchParams(location.search);
  
  useEffect(() => {
    if (params.get('token')) {
      verifyUser(params.get('token'));
    }
  }, []);
  
  const verifyUser = async (token) => {
    try {
      await post(USER_VERIFY_URL, { userId: token });

      ReactGA.event({
        category: "General",
        action: "Validate account",
        nonInteraction: true,
      });

      window.location.href = '/dashboard';
    } catch (e) {
      console.log(e);
    }
  };
  
  return <></>;
};

export default withRouter(Verify);
