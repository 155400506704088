import React, { useContext } from 'react';
import { PageContext } from '../../helpers/context';

const ExpertDescriptionCard = () => {
  const { loading, data, heightOfCards } = useContext(PageContext);

  return (
    <div className={'rounded-lg shadow-md p-4 bg-white overflow-y-auto'} style={{ height: heightOfCards.details + 40 }}>
      <p className={'text-base html-div'}>
        {!loading && <div dangerouslySetInnerHTML={{ __html: data.expert_listings[0].details || 'N/A' }} />}
      </p>
    </div>
  );
};

export default ExpertDescriptionCard;
