import { useLazyQuery } from '@apollo/react-hooks';
import { Card, Rate } from 'antd';
import 'antd/es/icon/style/css';
import 'antd/es/rate/style/css';
import 'antd/es/tooltip/style/css';
import _, { startCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import downloadIcon from '../../assets/icons/download-icon.png';
import playIcon from '../../assets/icons/play-icon.png';
import ticketIcon from '../../assets/icons/tickets.png';
import userMobile from '../../assets/icons/user-mobile.png';
import withApollo from '../../config/with-apollo';
import { Q_DIGITAL_LIBRARY_GET_PDF_LINK } from '../../graphql/digital-libraries';
import { PageContext } from '../../helpers/context';
import { baseAxios } from '../../utils/api-handler';

const DigitalLibraryCard = (props) => {
  const ID = props.match.params.id;
  const digitalLibrary = props.data;
  const [loadPdfLink, { called, loading, data }] = useLazyQuery(Q_DIGITAL_LIBRARY_GET_PDF_LINK,
    { client: props.client, variables: { id: digitalLibrary.id } });

  const [pdfLink, setPdfLink] = useState(null);

  useEffect(() => {
    if (props.hasTransaction && ID) {
      loadPdfLink();
    }
  }, [props.hasTransaction, ID]);

  useEffect(() => {
    if (!loading && called && props.hasTransaction && ID) {
      setPdfLink(data.digital_library_by_pk.pdf_link);
    }
  }, [loading, called, props.hasTransaction, ID]);

  const [resourceDetails, setResourceDetails] = useState({
    thumbnail: null,
    duration: '0'
  });
  const { setHeightCards, setCurrentResource, setShowModal, showModal } = useContext(PageContext);

  const cardRef = useRef(null);

  useEffect(() => {
    getCardSize();

    window.addEventListener('resize', getCardSize);
  }, [cardRef, props.hasTransaction]);

  const getCardSize = () => {
    if (ID) {
      setHeightCards(oldState => ({ ...oldState, details: cardRef.current.clientHeight }));
    }
  };

  const handleImageOnLoad = (event) => {
    event.persist();
    if (ID && event && event.target && event.target.clientHeight) {
      setHeightCards(oldState => ({ ...oldState, details: oldState.details + (event.target.clientHeight || 0) - 21 }));
    }
  };

  useEffect(() => {
    if (digitalLibrary.video_id) {
      baseAxios('GET', `${process.env.REACT_APP_VIMDEO_API_URL}/videos/${digitalLibrary.video_id}`, {}, {}, {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
          'Accept': 'application/vnd.vimeo.*+json;version=3.4',
        }
      }).then(res => {
        setResourceDetails({
          thumbnail: res.data.pictures.sizes[res.data.pictures.sizes.length - 1].link_with_play_button,
          duration: Math.ceil(res.data.duration / 60)
        });
      });
    }
  }, []);

  const handleConfirmModal = (digitalLibrary) => () => {
    // if (user_tokens < digitalLibrary.cost) {
    //   return props.history.push('/top-up', { amount: digitalLibrary.cost, return_url: props.location.pathname });
    // }
    setShowModal(!showModal);
    setCurrentResource(digitalLibrary);
  };

  const DigitalLibraryFooter = () => {
    if (!props.hasTransaction) {
      return <a
        onClick={handleConfirmModal(digitalLibrary)}
        className={'h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white'}>
        Access Now
      </a>;
    } else {
      if (!ID) {
        return <a
          onClick={() => { !ID && props.history.push(`/digital-library/${digitalLibrary.id}`); }}
          className={'h-10 w-full rounded-b-lg bg-event-going text-white text-lg flex justify-center items-center mt-6 hover:text-white'}>
          Open
        </a>;
      }

      return <div
        className={'h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white'}>
        Write review
      </div>;
    }
  };

  const forceHalfRating = (rate) => {
    let stringRate = String(rate);
    let parseRating = stringRate.split('.');
    let result;

    if (Number(parseRating[1]) >= 5) {
      result = 5;
    } else {
      result = 0;
    }

    return Number(`${parseRating[0]}.${result}`);
  };

  const cardCover = () => {
    if (!ID) {
      if (!digitalLibrary.video_id) {
        return <div className={`w-full ${!ID && 'xl:h-52 lg:h-63.5 md:h-84 h-46'} bg-cover bg-center`}
          style={{ backgroundImage: `url(${digitalLibrary.pdf_cover})` }} />;
      }

      return <img src={resourceDetails.thumbnail}
        onLoad={handleImageOnLoad}
        className={`w-full ${!ID && 'xl:h-52 lg:h-63.5'} h-auto`}
        alt={digitalLibrary.title} />;
    } else {
      if (!digitalLibrary.video_id) {
        return <div className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-cover bg-center`}
          style={{ backgroundImage: `url(${digitalLibrary.pdf_cover})` }} />;
      }

      if (!props.hasTransaction) {
        return <img src={resourceDetails.thumbnail}
          onLoad={handleImageOnLoad}
          className={`w-full ${!ID && 'xl:h-52 lg:h-63.5'} h-auto`}
          alt={digitalLibrary.title} />;
      }

      return <iframe
        className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-black`}
        src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${digitalLibrary.video_id}`}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen />;
    }
  };


  const TagList = () => {
    if (digitalLibrary.digital_library_business_goals_tags !== undefined) {
      if (ID) {
        return digitalLibrary.digital_library_business_goals_tags.map((value, key) => {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 8px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-black flex mr-2 mb-2'}>{value.business_goals_tag.name}</span>;
        });
      }
      return _.takeRight(digitalLibrary.digital_library_business_goals_tags, 4).map((value, key) => {
        if (key >= 3) {
          return <span className={'px-1 text-xs text-black font-medium italic flex mr-2 mb-2'}>and more...</span>;
        } else {
          return <span
            style={{
              outlineColor: ('#' + value.pillar.color),
              boxShadow: ('#' + value.pillar.color + ' 0 0px 8px')
            }}
            className={'rounded mt-1 px-1 ml-1 text-xs text-black flex mr-2 mb-2'}>{value.business_goals_tag.name}</span>;
        }
      });
    } else {
      return <div className={'rounded px-1 text-xs text-black bg-gray-300 flex mr-2 mb-2'}>N/A</div>;
    }
  };

  return (
    <div ref={cardRef}>
      <Card className={`bg-white h-full rounded-lg overflow-hidden shadow-md ${props.card &&
        props.card.className}`}
        cover={cardCover()}>
        <div className={'px-4'}>
          <p onClick={() => { return !ID && props.history.push(`/digital-library/${digitalLibrary.id}`); }}
            className={`pt-4 h-20 font-bold font-grumpy text-xl text-black ${!ID &&
              'cursor-pointer hover:text-primary truncate-2 h-20'}`}
          >
            {digitalLibrary.title}
          </p>
          <div className={`mb-6 mt-2 flex ${ID && 'mt-10'} `}>
            <div className={'pr-6 flex self-center w-16 h-14'} style={{ flex: ' 0 0 5rem' }}>
              <img src={digitalLibrary.presenter_logo || userMobile}
                className={'w-16 h-auto rounded-full'}
                alt={''} />
            </div>
            <div className={'flex flex-col w-full h-22'}>
              <p className={'text-xs italic'}>By</p>
              <p className={'text-black font-medium text-base'}>{digitalLibrary.presenter}</p>
              <p className={'text-sm'}>{digitalLibrary.presenter_sub_title}</p>
            </div>
          </div>
          <div className={ID && 'flex flex-col md:flex-row'}>
            <div className={`mb-6 flex ${ID && 'md:w-3/4 md:justify-center'}`}>
              <p className={`font-medium text-black block pr-2 ${ID && 'flex self-center'}`}>
                Requires
              </p>
              <div className={'border rounded border-black px-1 text-xs text-black flex self-center mr-1'}>
                {digitalLibrary.subscription_type ? startCase(digitalLibrary.subscription_type.toLowerCase()) : 'N/A'}
              </div>
              {digitalLibrary.subscription_type === "STANDARD" ? 
                <>
                  <div className={'border rounded border-black px-1 text-xs text-black flex self-center mr-1'}>
                    Premium
                  </div>
                </>
                :
                ''
              }
              <div className={'border rounded border-black px-1 text-xs text-black self-center'}>
                Pay as You Go
              </div>
            </div>
            <div className={`mb-6 flex ${ID && 'md:w-1/2 md:justify-center'}`}>
              <p className={`font-medium text-black block pr-2 ${ID && 'flex self-center'}`}>
                Expires on
              </p>
              <div className={'border rounded border-black px-1 text-xs text-black flex self-center'}>{moment(
                digitalLibrary.resource_end_date).format('D MMM YYYY')}</div>
            </div>
            <div className={`flex`}>
              <div className={'flex flex-col'}>
                <p className={`font-medium text-black block pr-2 mb-2 ${ID && 'flex'}`}>Expertise in:</p>
                <div className={'truncate-2'}>
                  <div className={`flex flex-row content-start items-start mb-3 flex-wrap ${props.isDetails ? 'h-auto' : 'h-20'}`}>
                    <TagList />
                  </div>
                </div>
              </div>
            </div>
            <div className={`mb-6 flex justify-between ${ID && 'w-1/2 md:justify-center'}`}>
              <div>
                <div className={'w-1/8 pt-1.5'} />
                <div className={'w-full flex justify-between justify-center items-center text-black text-base'}>
                  {digitalLibrary.cost ? <div className={'flex flex-row items-center'}><img src={ticketIcon}
                    className={'h-10.5 pr-4'}
                    alt={''} />{digitalLibrary.cost}</div> :
                    <div className={'border rounded p-2'}>FREE</div>}
                </div>
              </div>
              {!ID && <div className={'flex items-center'}>
                <div className={'w-6'}>
                  <img src={digitalLibrary.video_id ? playIcon : downloadIcon} className={'w-10 h-auto'} alt={''} />
                </div>
                <div className={'ml-4'}>
                  <div className={'flex self-center'}>{digitalLibrary.video_id
                    ? `${resourceDetails.duration} min(s)`
                    : `${digitalLibrary.pdf_size}`}</div>
                </div>
              </div>}
            </div>
          </div>
          <div className={`flex flex-col md:flex-row
            ${digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating ? 'mb-6' :
              ID ? 'mb-6' : 'mb-13.5'}`}>
            <div className={`${ID && 'md:w-1/2 w-full'} mb-5 md:mb-0`}>
              <div className={`flex ${!digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating &&
                'hidden'}`}>
                <Rate allowHalf
                  value={digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating
                    ? forceHalfRating(digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating.toFixed(1))
                    : 0}
                  className={'text-primary'}
                  disabled />
                <p className={'flex self-center pl-4 text-base text-black font-semibold'}>
                  {digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating
                    ? digitalLibrary.digital_library_reviews_aggregate.aggregate.avg.rating.toFixed(1)
                    : 0}
                </p>
                <p className={'hidden flex self-center pl-1 text-base text-gray-500'}>
                  ({digitalLibrary.digital_library_reviews_aggregate.aggregate.count})
                </p>
              </div> 
            </div>
            {ID && <div className={'flex md:w-1/2 pt-1 w-full items-center'}>
              <div className={'md:w-8 w-6'}>
                <img src={digitalLibrary.video_id ? playIcon : downloadIcon} className={'md:w-7 h-auto'} alt={''} />
              </div>
              <div className={'ml-2 w-full'}>
                {digitalLibrary.video_id ? (
                  <div className={'flex flex-row w-full'}>
                    <div className={'flex w-full self-center mr-5'}>{`${resourceDetails.duration} min(s)`}</div>
                    {pdfLink && (
                      <div className={'flex flex-row w-full items-center'}>
                        <div className={'md:w-8 w-6 mr-2'}>
                          <img src={downloadIcon}
                            className={'md:w-7 h-auto'}
                            alt={''} />
                        </div>
                        {pdfLink ? (
                          <a
                            target={'_blank'}
                            download={pdfLink}
                            className={'flex self-center w-full text-primary hover:text-primary focus:text-primary font-bold'}
                            href={pdfLink}>{`Download (${digitalLibrary.pdf_size})`}</a>
                        ) : (
                          <div className={'flex w-full self-center'}>{digitalLibrary.pdf_size}</div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  pdfLink ? (
                    <a
                      target={'_blank'}
                      download={pdfLink}
                      className={'flex self-center text-primary hover:text-primary focus:text-primary font-bold'}
                      href={pdfLink}>{`Click here to download (${digitalLibrary.pdf_size})`}</a>
                  ) : (
                    <div className={'flex self-center'}>{digitalLibrary.pdf_size}</div>
                  )
                )}
              </div>
            </div>}
          </div>
        </div>
        <DigitalLibraryFooter />
      </Card>
    </div>
  );
};

export default compose(withRouter, withApollo)(DigitalLibraryCard);
