import classname from 'classnames';
import React, { useContext, useMemo } from 'react';
import { Subscription } from 'react-apollo';
import { S_PERK_CATEGORIES } from '../../graphql/perks';
import { PageContext } from '../../helpers/context';

const PerkCategorySubscription = () => {
  const { filter, handleFilterChange } = useContext(PageContext);
  
  return useMemo(() => {
    return <Subscription subscription={S_PERK_CATEGORIES}>
      {({ loading, error, data }) => {
        if (loading) return <></>;
        
        return data.perk_categories.map(value => {
          let active = classname(
            {
              'bg-black text-white': value.id === filter.perk_category_id,
              'bg-light-gray-header text-black': value.id !== filter.perk_category_id
            }
          );
          
          return (
            <button type={'button'}
              onClick={handleFilterChange(value.id)}
              className={`${active} h-7 md:h-10 mr-6 rounded px-4 flex items-center focus:outline-none cursor-pointer mb-4 md:mb-0`}>
              {value.name}
            </button>
          );
        });
      }}
    </Subscription>;
  }, [filter]);
};

export default PerkCategorySubscription;
