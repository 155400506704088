export const AUTH = () => {
  let data = JSON.parse(localStorage.getItem(process.env.REACT_APP_TOKEN));
  let base64Url = data.token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return {
    hasura: JSON.parse(window.atob(base64)),
    email: data.username,
    name: data.name,
    id: JSON.parse(window.atob(base64))['https://hasura.io/jwt/claims']['x-hasura-user-id']
  };
};

export const USER_TRIAL = 'TRIAL';
export const USER_BASIC = 'ACCELERATE';
export const USER_PREMIUM = 'PREMIUM';
export const USER_DEACTIVE = 'DEACTIVE';

export const subscriptionImages = {
  SIGN_UP: 'https://files.elfsightcdn.com/51fe1d97-fab9-4f66-80bc-d21de92a4531/d71f7c1d-bbec-4d3e-bcdb-332d95677920/Elfsight-pricing-table-new-membership.png',
  COMMUNITY: 'https://files.elfsightcdn.com/51fe1d97-fab9-4f66-80bc-d21de92a4531/3fa4828f-de9c-4b27-9cca-8a6826b95aac/Elfsight-pricing-table-new-membership--2-.png',
  STANDARD: 'https://files.elfsightcdn.com/51fe1d97-fab9-4f66-80bc-d21de92a4531/4ad3b5b9-89a1-4b78-aecf-325df6699a42/Elfsight-pricing-table-new-membership--4-.png',
  PREMIUM: 'https://files.elfsightcdn.com/51fe1d97-fab9-4f66-80bc-d21de92a4531/5403f9bd-1781-47a8-8ad4-c3c2b57a361b/Elfsight-pricing-table-new-membership--1-.png'
}

export const subscriptionProperties = [
  'Member listing',
  'Meet ups',
  'Be MPowered Conference',
  'Advertising',
  'Personalised Action Plan',
  'Digital Lessons & Resources',
  'Expert Group Session',
  'Expert 1:1 Session',
  'Select Partner Offers',
  'Earn Tokens',
  'Pay as you go'
]

export const subscriptionPropertyMap = {
  'Member listing': 'member_listing',
  'Meet ups': 'meet_ups',
  'Be MPowered Conference': 'special_events',
  'Advertising': 'podcast_member_feature',
  'Personalised Action Plan': 'personalized_plan_and_chat',
  'Digital Lessons & Resources': 'digital_library',
  'Expert Group Session': 'group_expert_sessions',
  'Expert 1:1 Session': 'single_expert_sessions',
  'Select Partner Offers': 'select_partner_offers',
  'Earn Tokens': 'earn_tokens',
  'Pay as you go': 'pay_as_you_go'
}

export const inclusionSummary = {
  sign_up: {
    title: 'Sign Up',
    desc: 'Subscribe to our newsletters, and pay as you go for member benefits',
  },
  community_yearly: {
    title: 'Community Subscription',
    desc: 'Join for online access to limited member benefits',
  },
  standard_quarterly: {
    title: 'Standard Subscription',
    desc: 'Join for everything included in our Community Subscription, plus additional member benefits',
  },
  standard_yearly: {
    title: 'Standard Subscription',
    desc: 'Join for everything included in our Community Subscription, plus additional member benefits',
  },
  premium_yearly: {
    title: 'Premium Subscription',
    desc: 'Join for everything included in our Standard Subscription, plus additional member benefits and advertising for your business.',
  }
}

export const subscriptionInclusions = [
  { label: 'Member listing', dataKey: 'member_listing' },
  { label: 'Member Meet Ups', dataKey: 'meet_ups' },
  { label: 'Signature special events', dataKey: 'special_events' },
  { label: 'Mumbition The Podcast', dataKey: 'podcast_member_feature' },
  { label: 'Personalised Action Plan', dataKey: 'personalized_plan_and_chat' },
  { label: 'Digital library resources', dataKey: 'digital_library' },
  { label: 'Expert Group Sessions', dataKey: 'group_expert_sessions' },
  { label: '1:1 Expert Sessions', dataKey: 'single_expert_sessions' },
  { label: 'Pay As You Go', dataKey: 'pay_as_you_go' },
]