import React, { useState, useEffect } from 'react';
import { Subscription } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PerkDescriptionCard from '../../components/cards/perk-description-card';
import PerkDetailCard from '../../components/cards/perk-detail-card';
import withApollo from '../../config/with-apollo';
import { S_PERK } from '../../graphql/perks';
import { PageContext } from '../../helpers/context';

const PerksDetails = (props) => {
  const ID = props.match.params.id;
  const [heightOfCards, setHeightCards] = useState({
    details: 0
  });
  
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  
  const handleBackToList = () => {
    props.history.push('/offers');
  };
  
  return <div className={'min-h-screen bg-light-gray'}>
    <Subscription subscription={S_PERK} variables={{ id: ID }}>
      {({ loading, error, data }) => {
        if (loading) return <></>;
        
        if (data.perks_by_pk === null) {
          props.history.goBack();
          return;
        }
        
        const perks = data.perks_by_pk;
        
        return <PageContext.Provider value={{ perks, heightOfCards, setHeightCards }}>
          <div className={'px-5 md:px-16 pt-10 container mx-auto'}>
          <span className={'text-base md:text-lg text-primary'}><a className={'hover:text-primary'}
            onClick={handleBackToList}>Offers</a>&nbsp; > &nbsp;</span>
            <span className={'text-base md:text-lg text-black'}>{perks.name}</span>
          </div>
          <div className={'p-6 container mx-auto md:px-16 md:flex md:flex-row'}>
            <div className={'mb-8 md:mb-0 md:w-3/4 md:pr-12 h-full'}>
              <PerkDetailCard/>
            </div>
            <div className={'md:w-4/12 html-div h-full'}>
              <PerkDescriptionCard/>
            </div>
          </div>
        </PageContext.Provider>;
      }}
    </Subscription>
  </div>;
};

export default withRouter(withApollo(PerksDetails));
