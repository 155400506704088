import axios from 'axios';

/**
 * Set base axios
 * @type {AxiosInstance}
 */
let instance = axios.create({
  baseURL: process.env.REACT_APP_HASURA_AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 *
 * @param key
 * @param value
 */
const setHeaders = (key, value) => {
  instance.defaults.headers.common[key] = value;
};

const baseAxios = (method, url, data = {}, params = {}, options = {}) => {
  return axios({
    method,
    url,
    data,
    params,
    ...options
  });
};

/**
 *
 * @param url
 * @returns {Promise<AxiosResponse<T>>}
 */
const get = (url) => {
  return instance.get(url);
};

/**
 *
 * @param url
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
const post = (url, payload) => {
  return instance.post(url, payload);
};

/**
 *
 * @param url
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
const put = (url, payload) => {
  return instance.put(url, payload);
};

/**
 *
 * @param url
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
const patch = (url, payload) => {
  return instance.patch(url, payload);
};

/**
 *
 * @param url
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
const destroy = (url, payload) => {
  return instance.delete(url, payload);
};

export {
  get,
  post,
  patch,
  put,
  destroy,
  setHeaders,
  baseAxios,
};
