import pluralize from 'pluralize';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import tokenIcon from '../../assets/icons/tickets.png';
import withApollo from '../../config/with-apollo';
import { AppContext } from '../../helpers/context';
import { post } from '../../utils/api-handler';
import { USER_BASIC, USER_TRIAL } from '../../utils/common';
import { RESEND_VERIFICATION_URL } from '../../utils/url-handler';

const HeaderToken = (props) => {
  const { user_plan, user_tokens, user_status, user_is_expired, user_email, setShowTitle } = useContext(AppContext);

  const showGetMoreButton = () => {
    if (user_plan === USER_TRIAL && user_is_expired) {
      return false;
    }

    if (user_plan === USER_TRIAL && !user_is_expired || user_plan === USER_BASIC) {
      return true;
    }
  };
  const resendVerification = async () => {
    try {
      const response = await post(RESEND_VERIFICATION_URL, { username: user_email })
      if (response.status === 200) {
        setShowTitle(true);
      }
    } catch (err) {
      console.log(err.message)
    }
  }
  const handleGetMore = () => {
    return props.history.push('/top-up');
  };

  return (
    <div className={'bg-white md:mt-20 mt-26 w-full'}>
      <div className={'flex items-center justify-between md:justify-end px-4 container mx-auto md:px-16 md:h-16 h-16'}>
        <div className={'flex items-center w-full justify-between md:justify-end md:flex-none'}>
          <div className={'md:text-base text-xs w-full flex justify-between md:justify-end md:flex-none'}>
            <div className={'flex items-center w-full justify-between md:justify-end'}>
              {user_status !== 'PENDING' ? (
                <>
                  <img className={'md:w-12 md:mr-4 w-10 h-auto text-right'} src={tokenIcon} alt={''} />
                  <div className={'flex'}>
                    <>
                      <span className={'mr-1'}>Currently you have {' '}</span>
                      <span className={'font-bold text-black md:mr-10'}>
                        {pluralize('token', user_tokens, true)}
                      </span>
                    </>
                  </div>
                  {showGetMoreButton() && (
                    <button onClick={handleGetMore}
                      className={'text-white bg-event-going rounded py-1 px-4 md:py-2 md:px-8 md:text-base focus:outline-none'}>
                      Get More
                    </button>
                  )}
                </>
              ) : (
                <>
                  {user_status === 'PENDING' && (<>
                    <div className={'md:flex flex md:flex-row items-center flex-col w-full md:w-auto md:h-auto'}>
                      <div className={'flex direction-row w-full items-center justify-around md:w-auto'}>
                        <img className={'md:w-12 md:mr-4 w-10 h-auto md:h-8 text-right '} src={tokenIcon} alt={''} />

                        <p className={'font-medium text-black'}>
                          Check your email to verify account</p>
                      </div>
                      <button onClick={resendVerification}
                        className={'text-white bg-event-going rounded px-2 md:py-2 md:px-8 md:mx-2 md:text-base md:w-auto w-1/2                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     h-7 md:h-auto focus:outline-none md:mt-auto mt-1'}>
                        Resend Verification
                      </button>
                    </div>
                  </>
                  )}
                </>
              )}
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withApollo(HeaderToken));
