import classname from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderCoverBanner1 from '../../components/header/header-cover-banner-1';
import HeaderCoverBanner1Mobile from '../../components/header/header-cover-banner-mobile';
import withApollo from '../../config/with-apollo';
import { AppContext, PageContext } from '../../helpers/context';
import { USER_TRIAL } from '../../utils/common';
import PerkCategorySubscription from './perk-category-subscription';
import PerkSubscription from './perk-subscription';

const PerksPage = (props) => {
  const { user_plan } = useContext(AppContext);
  const [filter, setFilter] = useState({
    perk_category_id: null,
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  let allFilter = classname(
    {
      'bg-black text-white': !filter.perk_category_id,
      'bg-light-gray-header text-black': !!filter.perk_category_id
    }
  );
  
  const handleFilterChange = (id) => () => {
    setFilter(oldState => ({ ...oldState, perk_category_id: id }));
  };
  
  return (
    <PageContext.Provider value={{ allFilter, handleFilterChange, filter, user_plan }}>
      <div className={'min-h-screen bg-light-gray'}>
        <HeaderCoverBanner1 title={'Exclusive Member Only Offers curated for you'}
          subtitle={`
Leverage from our network to access exclusive offers from our partners, great discounts on products and services
`}/>
        <HeaderCoverBanner1Mobile title={'Exclusive Member Only Offers curated for you'}
          subtitle={`
Leverage from our network to access exclusive offers from our partners, great discounts on products and services
`}/>
        <div className={'p-6 container mx-auto md:px-16'}>
          <div className={`my-6 flex flex-row flex-wrap ${user_plan !== USER_TRIAL && 'mb-6 md:mb-12'}`}>
            <div onClick={handleFilterChange(null)}
              className={`${allFilter} h-7 md:h-10 mr-6 rounded px-4 flex items-center cursor-pointer mb-4 md:mb-0`}>
              All
            </div>
            <PerkCategorySubscription/>
          </div>
          {user_plan === USER_TRIAL && (
            <div className={'bg-light-gray-header p-5 mb-6 border rounded-lg md:mb-12'}>
              <p className={'text-lg font-grumpy text-red-500'}>Limited offers available on Free Trial. </p>
              <p className={'text-black'}>
                <a onClick={() => {props.history.push('/upgrade-account');}}
                  className={'text-primary focus:text-primary hover:text-primary'}>Upgrade</a> to a
                paid plan to get
                access to
                premium corporate discounts and savings.
              </p>
            </div>
          )}
          <div className={'flex flex-col md:flex-row  md:flex-wrap'}>
            <PerkSubscription/>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default withRouter(withApollo(PerksPage));
