import { Icon } from 'antd';
import 'antd/es/notification/style/css';
import 'antd/es/radio/style/css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
// import { CardCvcElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import withApollo from '../../../config/with-apollo';
import { AppContext, PageContext } from '../../../helpers/context';
import { get, post } from '../../../utils/api-handler';
import { AUTH } from '../../../utils/common';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// const notify = (type, message, title = 'Payment') => {
//   notification[type]({
//     message: title,
//     description:
//       message,
//   });
// };

const PaymentPage = compose(withRouter, withApollo)((props) => {
  const {
    setShowSuccessModal,
    // showSuccessModal,
    // showErrorModal,
    setShowErrorModal,
    topUp,
    handleSetDiscountVisible,
    handleSetDiscountCode,
    handleSetErrorDiscountVisible
  } = useContext(PageContext);
  const AMOUNT = topUp.price;
  const TOKEN = topUp.token;
  const USER = AUTH();
  const [freeMode, setFreeMode] = useState(false);
  const { stripe_id } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [couponInput, setCouponInput] = useState(null);
  // const [paymentSubscription, setPaymentSubscription] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [defaultCard, setDefaultCard] = useState({});
  const [nameOnCard, setNameOnCard] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  /**
   * Get user card details
   */
  useEffect(() => {
    if (stripe_id) {
      (async () => {
        try {
          const { data: { sources } } = await get(`/customers/${stripe_id}`);

          if (sources.data.length) {
            setDefaultCard(sources.data[0]);
          }

        } catch (e) {

        }
      })();
    }
  }, [stripe_id]);

  const handleCheckCoupon = async (e) => {
    e.preventDefault();
    const { data } = await get(`coupons/${couponInput}`);

    if (data.statusCode !== 404) {
      handleSetDiscountVisible();
      if (data.percent_off === 100) {
        setFreeMode(true);
      }
      setCoupon(data);
    } else {
      setCoupon({});
      handleSetErrorDiscountVisible();
    }
  };

  const handleOnChange = (e) => {
    setCouponInput(e.target.value);
    handleSetDiscountCode(e);
  };

  const handleSetNameOnCard = (e) => {
    setNameOnCard(e.target.value);
  };

  const handleSubmitFormTopUp = async () => {
    setSubmitting(true);

    if (freeMode) {
      await post(`/free/${couponInput}?sku=${topUp.stripe_sku === 'lack_mode' ? topUp.stripe_sku + '&token=' + topUp.token : topUp.stripe_sku}`, {
        userId: USER.id
      });

      setShowSuccessModal(true);
      setSubmitting(false);
    }

    if (!stripe || !elements) {
      return;
    }

    try {
      const card = elements.getElement(CardNumberElement);

      let discount = '';
      if (coupon) {
        discount = coupon.id;
      }

      const { data: { client_secret } } = await post(`payment-intent`, {
        priceId: topUp.stripe_sku,
        userId: USER.id,
        amount: handleCheckCouponAmount(),
        discount,
        token: TOKEN,
        extraPay: topUp.stripe_sku === 'lack_mode' ? true : false
      });

      const confirmCardPayment = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card,
          billing_details: {
            name: nameOnCard
          }
        }
      });

      if (confirmCardPayment.paymentIntent.status === 'succeeded') {
        setShowSuccessModal(true);
      } else {
        setShowErrorModal(true);
      }

      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setShowErrorModal(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCheckCouponAmount = () => {
    if (Object.keys(coupon).length) {
      if (coupon.amount_off) {
        return AMOUNT - (coupon.amount_off / 100);
      }

      if (coupon.percent_off) {
        return AMOUNT - (AMOUNT * (coupon.percent_off / 100));
      }
    }

    return AMOUNT;
  };

  const handleCardDetails = (key) => ({ complete, empty }) => {
    setCardDetails(oldState => {
      return {
        ...oldState,
        [key]: { complete, empty }
      };
    });
  };

  const canMakePayment = useMemo(() => {
    if (topUp && !!Object.keys(defaultCard).length) {
      return true;
    }

    if (cardDetails['card-number'] && cardDetails['card-expiry'] && cardDetails['card-cvc']) {

      if (!cardDetails['card-number'].empty && !!cardDetails['card-number'].complete &&
        !cardDetails['card-expiry'].empty && !!cardDetails['card-expiry'].complete &&
        !cardDetails['card-cvc'].empty && !!cardDetails['card-cvc'].complete) {
        return true;
      }

      return false;
    }

    return false;
  }, [cardDetails, topUp, defaultCard]);

  return <div className={'md:bg-white md:rounded-lg w-full md:p-10 md:shadow-md'}>
    <div className={'mb-10 flex md:flex-row flex-col'}>
      <div className={'md:w-1/2 w-full mr-8 mb-4 md:mb-0'}>
        <p className={'text-sm text-black mb-2'}>Card Number</p>
        <CardNumberElement placeholder={''}
          onChange={handleCardDetails('card-number')}
          className={'h-10.5 border bg-white rounded border-light-gray-header p-3'}
          style={{
            base: {
              fontSize: '16px',
              color: 'black',
              height: '2.625rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }} />
      </div>
      <div className={'md:w-1/2 w-full'}>
        <p className={'text-sm text-black mb-2'}>Name on Card</p>
        <input
          onChange={handleSetNameOnCard}
          className={'h-10.5 border rounded border-light-gray-header w-full focus:outline-none text-black font-normal font-sans p-3'}
          style={{ fontSize: '16px' }} />
      </div>
    </div>
    <div className={'flex flex-row mb-5'}>
      <div className={'md:w-35 w-full mr-8'}>
        <p className={'text-sm text-black mb-2'}>Expiry Date</p>
        <CardExpiryElement placeholder={'mm / yy'}
          onChange={handleCardDetails('card-expiry')}
          className={'h-10.5 border rounded bg-white border-light-gray-header p-3'}
          style={{ base: { fontSize: '16px', color: 'black', height: '2.625rem' } }} />
      </div>
      <div className={'md:w-30 w-full'}>
        <p className={'text-sm text-black mb-2'}>CVC</p>
        <CardCvcElement onChange={handleCardDetails('card-cvc')}
          placeholder={''}
          className={'h-10.5 border rounded bg-white border-light-gray-header p-3'}
          style={{ base: { fontSize: '16px', color: 'black', height: '2.625rem' } }} />
      </div>
    </div>

    <div className={'mb-5'}>
      <span className={'text-xs '}>By clicking on pay now , you agree to our <a target={'_blank'}
        href={'/terms-and-conditions'}
        className={'text-primary'}>Terms of services</a></span>
    </div>
    <div className={'flex md:justify-between items-start md:flex-row flex-col'}>
      <div className={'md:w-1/4 flex flex-row mb-4 md:mb-0'}>
        <div>
          <p className={'text-sm text-black mb-2'}>Promo / Voucher code</p>
          <input
            onChange={handleOnChange}
            value={couponInput}
            className={'h-10.5 border rounded border-light-gray-header w-full focus:outline-none text-black font-normal font-sans p-3'}
            style={{ fontSize: '16px' }} />
        </div>
        <a onClick={handleCheckCoupon} href="#"
          className={'flex self-center pl-3 pt-5 text-sm text-primary focus:text-primary'}>Apply</a>
      </div>
      <div className={'flex md:self-center'}>
        <button
          disabled={(submitting || !canMakePayment) && !freeMode}
          onClick={handleSubmitFormTopUp}
          className={`${(submitting || !canMakePayment) && !freeMode
            ? 'bg-disabled'
            : 'bg-primary'} px-3 rounded pb-2 pt-2 text-white font-medium flex items-center`}>
          {submitting && <Icon type={'loading'} className={'mr-2'} />} Pay
          now <span className={'italic font-light'}>(${handleCheckCouponAmount()})</span>
        </button>
      </div>
    </div>
  </div>;
});

const PaymentPageWrapper = () => {
  return <Elements stripe={stripePromise}>
    <PaymentPage />
  </Elements>
}

export default PaymentPageWrapper;
