import React from 'react';
import logo from '../../assets/icons/mums_and_co_logo.png';

const Terms = () => {
  return (
	  <div className={'min-h-screen bg-light-gray'}>
      <div className={'w-full flex justify-center mb-10 bg-primary py-5'}>
        <img src={logo} alt="" />
      </div>
      <div className={'container mx-auto pb-10 px-16 text-black text-sm terms'}>
        <div className="w-richtext">
          <h3 className={'text-4xl font-grumpy font-bold'}>Membership terms and conditions</h3>
          <p className='font-bold'>Effective as at 29 March 2024.</p>
          <p className='font-bold'>These Terms and Conditions include terms which limit and exclude our liability for certain types of loss or damage. This means that in the event of a claim by you against us, your recourse may be limited. </p>
          <p>‍These Terms and Conditions govern the registration for, operation and membership of Mums & Co, a business which is part of the IAG Group. Some member benefits and membership activities offered to you from time to time may be subject to separate or additional terms and conditions which will be notified to you in advance.</p>
          In these Terms and Conditions:<br />
&zwj;<br />
(a) “Mums & Co”, “we”, “our” or “us” means Insurance Australia Limited ABN 11 000 016 722 trading as “Mums & Co” or “Mums & Company”.
<br/>(b) “member" or “you” means a person that has been accepted as a member of Mums & Co by us in accordance with these Terms and Conditions;
<br/>(c) “IAG Group” means Insurance Australia Group Limited ABN 60 090 739 923 and its related bodies corporate (which has the meaning given in the Corporations Act 2001 (Cth));
<br/>(d) “our website” or the “Mums & Co website” means all web pages located on the www.mumsandco.com.au domain name, including the Mums & Co member platform (https://app.mumsandco.com.au);
<br/>(e) “AcceptedPayment Method” means one of the credit or debit cards set out on our website from time to time which a member can use to make a payment; and
<br/>(f) “AustralianConsumer Law” means Schedule 2 to the Competition and Consumer Act 2010 (Cth)and regulations under that Act insofar as they apply to that Schedule 2.<br />
<br />By applying to become a member of Mums &amp; Co, you are taken to have:<br />
&zwj;<br />
(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;agreed to these Terms and Conditions;<br />
(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;agreed to our&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/website-terms-of-use">Website Terms of Use</a>; and<br />
(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;read and understood our&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/privacy-and-security">Privacy Policy and Security Statement</a>.<br />
<br />
It is your responsibility to ensure you have read and understood the above terms before applying for membership.<br />
<br />
We will use reasonable endeavours to notify you of changes to these Terms and Conditions, including the&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/website-terms-of-use">Website Terms of Use</a>, other than trivial or insignificant changes, if the changes are:<br />
<br />
(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;likely to benefit you or have neutral impact on you; or<br />
(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;required by law or necessary for security reasons, to prevent fraud or for technical reasons.<br />
<br />
Those changes will take effect from the date on which the relevant terms are changed on our website. &nbsp;<br />
<br />
For any other changes we make to these Terms and Conditions, including the&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/website-terms-of-use">Website Terms of Use,&nbsp;</a>we will provide you with no less than 14 days&rsquo; notice by email. Such changes may include changes to:<br />
&zwj;<br />
(a)&nbsp;&nbsp; membership tier requirements and benefits, including the continued availability of, or the imposition of any restrictions and conditions on, such benefits included within each membership tier;<br />
(b)&nbsp;&nbsp; any changes to fees and payment terms;<br />
(c)&nbsp;&nbsp; how tokens may be earned or redeemed; and<br />
(d)&nbsp;&nbsp; the expiry of accrued tokens.<br />
<br />
If you do not accept the changes, you may terminate your membership. Continuing to be a member of Mums &amp; Co after any terms have been modified will show you accept the revised terms.<br />
&zwj;<br />
&zwj;<strong>MEMBERSHIP<br />
&zwj;<br />
&zwj;<em>Eligibility and applying for membership<br />
&zwj;</em></strong>You are eligible to become a member of Mums &amp; Co if you reside in Australia. Membership is only open to individuals.<br />
<br />
You become a member of Mums &amp; Co once you have registered for membership on the Mums &amp; Co website and paid any applicable subscription fees.<br />
<br />
Eligibility for membership is at our ultimate discretion and is conditional upon you agreeing to be bound by the limitations and obligations in these Terms and Conditions, as well as you agreeing to our&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/website-terms-of-use">Website Terms of Use</a>&nbsp;and our&nbsp;<a href="https://mumsandco.com.au/privacy-and-security">Privacy Policy and Security Statement</a>.<br />
&zwj;<br />
&zwj;<strong><em>Access<br />
&zwj;</em></strong>Members may access their member benefits by visiting the Mums &amp; Co website using their registered email address and password.<br />
&zwj;<br />
&zwj;<strong><em>Membership, tokens and benefits are not transferable<br />
&zwj;</em></strong>Membership, tokens and any member benefits are personal and not transferable.<br />
&zwj;<br />
&zwj;<strong><em>Use of information provided in application<br />
&zwj;</em></strong>You agree that information collected by us in your application for membership or other information provided by you may be used by us to offer you other products and services in connection with your membership. We may also contact you to request that you take part in customer experience surveys. You may opt out of receiving marketing messages from us at any time by clicking the &quot;unsubscribe&rdquo; button at the bottom of the relevant communication.<br />
<strong><em>&zwj;<br />
&zwj;</em>MEMBER BENEFITS<br />
&zwj;<br />
&zwj;</strong>‍Member benefits will be available to you depending on the membership tier you choose and depending on the benefits available on our website from time to time. The current membership tiers are Sign Up, Community Subscription, Standard Subscription and Premium Subscription. Current benefits for each membership tier are set out on the “Join” section of our website. Additional member benefits, incentives and rewards may be available to you through the Mums & Co member platform from time to time based on your membership tier. Details of any such benefits, incentive and rewards and any additional terms and conditions applicable will be made available on our website. You may access benefits offered outside your membership tier at any time by upgrading your membership tier or by purchasing and redeeming tokens as set out under the section “Member Tokens” below.<br />
<br/>If you upgrade your membership tier to a higher membership tier before the end of the subscription period for your current membership tier, we will provide a pro rated credit for the remainder of the subscription period which will be used to offset the cost of the subscription fees of the higher membership tier. The amount of the credit will be proportionate to the length of time remaining until your subscription period ends.<br />
<br/>We do not act as your agent in any circumstances in connection with your membership, including for member benefits offered to you. We do not act as the agent of any third parties that may offer you member benefits unless otherwise stated
‍&zwj;<br /><br/>
&zwj;<strong><em>Use of member benefits<br />
&zwj;</em></strong>Member benefits must only be used for the benefit of you or your business(es).<br />
<br />

If you have claimed a particular benefit that is included in your membership tier and we have to cancel it, we will credit your user account with that particular benefit so that you can claim it again in the future (for example, if your membership includes a set number of events and we have to cancel an event you have already registered for, we will not include that event as one of the set number of events you are entitled to attend).
<br /><br />
If you register for an event you may cancel by providing more than 48 hours’ notice (or such other period stated for that particular benefit on our website). Once cancelled, we will re-instate any benefits that have been deducted from your account (for example, if your membership includes a set number of events and you cancel your registration for an event with more than 48 hours’ notice (or such other period stated for that particular benefit on our website), we will not include that event as one of the set number of events you are entitled to attend).
<br /><br />
If you register for an event and cancel with less than 48 hours’ notice, your entitlement to that benefit will be forfeited but we will use reasonable endeavours where possible to send you a recording of the event for you to access within 72 hours after the event.
‍&zwj;<br /><br/>
&zwj;<strong><em>Member benefits offered by third parties<br />
&zwj;</em></strong>‍Member benefits may be offered by third parties and those benefits may be subject to additional terms and conditions which will be notified to you on the relevant section of the Mums & Co website, on linked third party websites or in any communication we send to members (such as an email). We accept no responsibility in relation to any member benefit offered by a third party or any act or omission by a third party, including if a third party fails to comply with its terms and conditions.<br/><br />
We may receive a referral fee from the relevant third party where you take advantage of a third party member benefit.<br />
&zwj;<br />
&zwj;<strong><em>Business Introductions<br />
&zwj;</em></strong>
‍All membership tiers will get access to a business introduction feature. This feature allows you to make and receive business introductions with other users on the Mums & Co website. You must opt in to use the business introduction feature and can choose to opt out at any time via your user account.<br/><br />

&zwj;<strong><em>Standard benefits<br />
&zwj;</em></strong>
The Mums & Co Standard membership tier offers all the benefits available in the Sign Up and Community membership tiers, as well as a range of additional features, including the ability to download digital library resources and access to multiple 1:1 expert sessions and expert group sessions. The full list of benefits available to this tier are set out on the member platform.
<br/><br/>‍&zwj;<strong><em>Premium benefits<br />
&zwj;</em></strong>The Mums & Co Premium membership tier offers all the benefits available in the lower tiers, as well as a number of premium features, including more 1:1 expert sessions and expert group sessions. This tier also exclusively offers advertising benefits for your business(es). All advertisements must comply with all relevant laws and regulations, including the Australian Consumer Law, any State or Territory consumer protection legislation and any laws, regulations or codes of conduct applicable to the particular industry in which your business operates (for example, laws relating to advertising to children). The full list of benefits available to this tier are set out on the member platform.<br />
<br/>For Premium members, if you cancel:<br />
<br />
<p>a)&nbsp;&nbsp; a 1:1 expert session, your entitlement can be reinstated if you cancel within the cancellation period stated for that session on our website; or</p>
<p>b)&nbsp;&nbsp; For Premium members, if you cancel your podcast advertisement slot, your entitlement can be reinstated if you cancel within two weeks (subject to timeslot availability).</p>
<br />
These offers for Premium or Standard members will not apply where you voluntarily cancel your booking outside of the cancellation period.<br />
<br/>Mums & Co reserves the right to not advertise, or to remove, any advertisement that Mums & Co reasonably considers, including but not limited to where the advertisement:<br />
<br/>
<p>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; does not comply with relevant laws, regulations or codes of conduct;</p>
<p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; does not comply with the Website Terms of Use;</p>
<p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; may be false, misleading or deceptive;</p>
<p>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; is contrary to prevailing community standards; or</p>
<p>e)&nbsp;&nbsp; may negatively impact the reputation of Mums & Co or the IAG Group by association with the relevant advertisement or the business being advertised.</p>
</div>
<br/>
<p>All advertisements offered as part of the Mums &amp; Co Premium membership tier must be approved by Mums &amp; Co prior to publication. By submitting an advertisement to Mums &amp; Co, you warrant that you have authority to publish the advertisement and that it complies with all relevant laws and regulations.<br />
<br />
Notwithstanding the approval of the advertisement by Mums &amp; Co, you indemnify Mums &amp; Co for any loss suffered as a result of a claim that the advertisement:<br />
<br />
a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;breaches applicable laws; or<br />
&zwj;b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;infringes the right (including the intellectualproperty rights) of any third party.</p>
<br/><p><strong>1:1 Expert Session</strong><br/>
From time to time, member benefits may include the provision of guidance to members by experts in a particular field. However, this is not intended to constitute medical, financial, investment, legal, tax accounting or other professional advice. We recommend that members seek advice from a professional regarding their specific circumstances.
</p><p><strong>MEMBER TOKENS<br />
Purchasing Tokens</strong><br />
Members can purchase tokens on the Mums & Co website using an Accepted Payment Method and these will be credited to your user account. The tokens can be used to redeem certain benefits outside the benefits of your membership tier. The benefits available to be redeemed for tokens will be specified on our website.<br/><br />
Each token will be subject to the following conditions:<br />
<br />
<p>a)&nbsp;&nbsp; it may only be credited to the purchasing member’s user account;</p>
<p>b)&nbsp;&nbsp; it may only be redeemed directly through Mums &amp; Co in exchange for benefits identified to be available for redemption by tokens on the website, and not through any third party offering benefits on our website;</p>
<p>c)&nbsp;&nbsp; it cannot be sold, transferred or exchanged and no interest can be granted in them to any person;</p>
<p>d)&nbsp;&nbsp; it cannot be exchanged for cash;</p>
<p>e)&nbsp;&nbsp; it does not expire until your membership is terminated in accordance with these Terms and Conditions;</p>
<p>f)&nbsp;&nbsp; once purchased, a token cannot be refunded unless otherwise agreed with us; and</p>
<p>g)&nbsp;&nbsp; no benefit accrues or is paid on the balance of any tokens held in a user account.</p>

<br />
You can view your token balance at any time on your user account.<br />
<br />
We reserve the right to cancel any tokens credited to a member incorrectly, or not in accordance with, or in breach of, these Terms and Conditions at any time.<br />
<br />
<strong>Token usage and the nature of tokens</strong><br />
The only purpose of a token is its use, via the exercise of your rights, to acquire services from Mums & Co. Tokens do not have any other rights, use, purpose, value, attributes, functionalities or features whether express or implied. Neither Mums & Co nor any other person is obliged to purchase any tokens from you nor anyone else at any time.
<br/><br />
Tokens are not (and in no case may be understood, deemed, interpreted or construed to be or to be representative of) any kind of:<br />
&zwj;<br />
a) currency, legal tender, money or deposit, whether fiat or otherwise, nor any substitute for such currency, legal tender, money or deposit;<br />
b) investment (whether secured or unsecured), equity interest, proprietary interest, economic right (including any kind of right to payment, income, dividend, return, profit, or other return, or any sums to be paid, or likely to be paid, out of such), share or similar interest in or claim against any person, asset, entity, organisation, scheme, venture or project (including but not limited to Mums & Co and our website);<br />
c) financial product or analogous instrument whatsoever, including any equity, debt or hybrid instrument, security, managed investment scheme, collective investment vehicle, managed fund, derivative, futures contract, deposit, commercial paper, negotiable instrument, investment contract, note, bond, warrant, certificate or instrument entitling the holder to interest, dividends or any kind of return;<br />
d) right, title, interest or benefit whatsoever in whole or in part, in Mums & Co; or<br />
e) any commodity that any person is obliged to redeem or purchase.<br /><br />
The purchase of tokens does not guarantee the availability a particular benefit. If you change your membership tier, any unused tokens will remain in your user account. If you purchase a particular benefit with tokens and subsequently upgrade your membership to a membership tier that provides access to that benefit:
‍<br/>&zwj;<br />
a) that particular benefit will be made available to you in addition to the standard benefits of that tier (i.e., if you are entitled to 2 member meet ups per quarter under that membership tier, a member meet up purchased previously with tokens will be in addition to the 2 member meet ups per quarter); and
<br/>b) any cancellations in relation to that benefit will be dealt with in accordance with these Terms and Conditions.
<br /><br/>
You acknowledge that Mums &amp; Co does not hold an Australian financial services licence, Australian credit licence nor the equivalent of either in any jurisdiction.<br />
<br />
You acknowledge that no invitation to acquire or offer of any financial product (as defined under the Corporations Act 2001 (Cth)) has been or will be made, no financial product will be issued or arranged to be issued, and no personal advice has or will be given and no recommendations to acquire a financial product has or will be made, which would require the provision of a prospectus under Chapter 6D.2 of the Corporations Act 2001 (Cth), a product disclosure statement under Division 2 of Part 7.9 of the Corporations Act 2001 (Cth) or a financial services guide or a statement of advice under Division 2 or 3 of Part 7.7 of the Corporations Act 2001 (Cth). You acknowledge that no document (including these Terms and Conditions) has been, will be or needs to be lodged with the Australian Securities and Investments Commission and that these Terms and Conditions have been prepared without taking into account your or any customers objectives, financial situation or needs.
<br/><br />
You acknowledge that no token is to be offered outside of Australia.<br />
<br />
You are responsible for your own costs and other arrangements relating in any way to the exercise of your rights and performance of obligations under these Terms and Conditions regarding any tax in relation to tokens.<br />
<br />
<strong>Redeeming tokens for benefits</strong><br />
The number of tokens that must be redeemed in relation to a particular benefit and the amount required as payment for those tokens will be published on the Mums & Co website from time to time and may be changed at any time without notice.
<br/><br/>
Members must have at least the number of tokens required to redeem their chosen benefit in their user account at the time the redemption is processed.
<br/><br/>
The version of these Terms and Conditions applicable to a particular redemption will be those in force at the time the tokens are redeemed for that reward.
<br/><br/>
If you purchase a particular benefit using tokens which cannot be fulfilled after you have booked it, we will credit your user account with the number of tokens you redeemed for that particular event.
‍<br/><br/>
If you voluntarily cancel a booking for a benefit purchased using tokens more than 48 hours before the event (unless stated otherwise for that particular benefit on our website), we will credit your user account with the number of tokens you redeemed for that particular event. This will not apply where you voluntarily cancel your booking within 48 hours of the event or such other period stated for that particular benefit on our website. However where you voluntarily cancel your booking and your tokens are not credited to your user account, we will use reasonable endeavours where possible to send you a recording of the event for you to access within 72 hours after the event.<br/>
<br/><strong>Token Refunds</strong><br />
You agree that any token refunds under these Terms and Conditions are at our sole discretion and will be made using the same Accepted Payment Method that was used to purchase the token.</p>

<p><strong>MEMBER OBLIGATIONS<br />
&zwj;<em>General<br />
&zwj;</em></strong>‍Members must act fairly and reasonably in all matters connected with their registration for, and their membership of, Mums & Co. This means you will:
<br/>a)  &nbsp;&nbsp;      not give us any incorrect or incomplete information in connection with your application for membership and will not impersonate any other person or entity;
<br/>b)&nbsp;&nbsp;        not in any way sell, barter, attempt to transferor otherwise deal with any member benefits connected with your membership, including any tokens you acquire, except as permitted by these Terms and Conditions, and will not abuse or misuse any member benefits;
<br/>c)&nbsp;&nbsp;        comply with the Website Terms of Use;
<br/>d)&nbsp;&nbsp;        give us information we reasonably request to verify eligibility for any member benefits, membership or issue or use of tokens;
<br/>e)&nbsp;&nbsp;        keep your password secure and confidential and not to disclose it to any person; and
<br/>f)&nbsp;&nbsp;         not allow any other person to access the Mums & Co website using your login credentials or use your member benefits or tokens.<br /><br />
&zwj;<strong>OUR OBLIGATIONS<br />
&zwj;</strong>We will use reasonable endeavours to:<br />
&zwj;<br />
a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;receive and process membership applications;<br />
b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;negotiate benefits for members with third parties or access to some of the IAG Group&rsquo;s benefits; and<br />
c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;make the Mums &amp; Co website available to members. Our website may not be available when we are conducting maintenance or implementing improvements.<br />
<br />
We are not responsible for, and do not make any representations or warranties in relation to, any:<br />
a)   &nbsp;&nbsp;&nbsp;     goods or services (including any applicable guarantees under Australian Consumer Law) accessed through or supplied by third parties; or
<br/>b)   &nbsp;&nbsp;&nbsp;     content created, owned, posted or published by members or third parties on the Mums & Co website.<br /><br/>
&zwj;<strong>FORUMS<br />
&zwj;</strong>As a member of Mums &amp; Co, you will have access to Mums &amp; Co forums, hosted on the Mums &amp; Co website. All members must comply with the following rules when using or posting on the forums: &nbsp;<br />
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Be kind and courteous</strong>. We&#39;re all in this together to create a welcoming environment. Let&#39;s treat everyone with respect. Healthy debates are natural, but kindness is required.<br />
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>No hate speech or bullying</strong>. Make sure everyone feels safe. Bullying of any kind is not allowed, and degrading comments about topics such as race, religion, culture, sexual orientation, gender or identity will not be tolerated.<br />
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>No spam</strong>. Give more to this group than you take. Hard-selling, spam and irrelevant links are not allowed.<br />
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Respect everyone&#39;s privacy</strong>. Being part of this group requires mutual trust. Authentic, expressive discussions make groups great, but may also be sensitive and private. What is shared in the group should stay in the group. &nbsp;<br />
<br />
We reserve the right to delete posts that we consider breach these Terms and Conditions. We also reserve the right to block or remove you from the forums if you breach these Terms and Conditions. Views expressed by individuals and third parties on the Mums &amp; Co forums are the views of the relevant poster and not the views of Mums &amp; Co. If you breach the above rules, you agree to indemnify us for any claim made against us relating to the relevant post made by you in breach of the forum rules.<br />
<br />
&zwj;<strong><em>HelpAsk</em></strong>&zwj;<br />
<p>All membership tiers will get access to a 24/7 social networking feature (<strong>HelpAsk</strong>). This feature allows you to post a business-related query in HelpAsk and seek a response from other members or answer another member’s query. If you post a query or respond to a query on HelpAsk, you agree that:</p>
<ul>
    <li>-&nbsp;&nbsp;&nbsp;you will not defame, harass, threaten, menace or offend any person;</li>
    <li>-&nbsp;&nbsp;&nbsp;you will not post anything that would constitute a breach of an individual’s privacy;</li>
    <li>-&nbsp;&nbsp;&nbsp;you will limit your posts to topics that are relevant to the Mums &amp; Co website;</li>
    <li>-&nbsp;&nbsp;&nbsp;you will avoid any duplication of posts;</li>
    <li>-&nbsp;&nbsp;&nbsp;you will provide constructive and helpful responses to other users; and</li>
    <li>-&nbsp;&nbsp;&nbsp;you will report any inappropriate behavior to us immediately.</li>
</ul><br/>
<p>We do not endorse or approve, and are not responsible for, anything you post, or any other member posts on HelpAsk. All information shared on HelpAsk is for informational purposes only and is not intended to constitute financial, investment, legal, tax, accounting or other professional advice.</p>

&zwj;<strong>PAYMENT<br />
&zwj;<em>Subscription Fees<br />
&zwj;</em></strong>Mums &amp; Co offers various subscription-based plans featuring different member benefits. The amount and frequency by which fees are charged may vary depending on the membership tier chosen. Current benefits and membership fees for each membership tier and any promotions or offers are published on the Mums &amp; Co website from time to time and may be changed at any time without notice. However, once a member has subscribed to a Mums &amp; Co membership tier we will provide you with at least 14 days&rsquo; notice prior to any change to the benefits included with each membership tier, amount of fees or payment terms.<br />
<br />
&zwj;<strong><em>Auto renewal<br />
&zwj;</em></strong>Your Mums &amp; Co membership will automatically renew for the subscription period associated with the membership tier you have chosen. For all members other than Sign Up members, Mums &amp; Co will notify you 30 days prior to the expiry of your current subscription period to advise you the date on which you will be charged for the auto renewal of your membership, as well as the applicable fees and any terms that apply to the renewal. If you do not terminate your membership prior to the date specified, you will be deemed to have consented to the renewal of your membership on the terms notified.<br />
<br />
Mums &amp; Co will charge the applicable subscription fee for the auto renewal of your membership to the credit card provided by you when you registered for Mums &amp; Co. If your credit card is declined, Mums &amp; Co may contact you, including by telephone, post or email, to obtain or validate payment details for the auto renewal of your membership. Mums &amp; Co may suspend your membership until payment for the next subscription period is made.<br />
<br />
&zwj;<strong>CANCELLATION OF SUBSCRIPTIONS<br />
&zwj;</strong>You may cancel your subscription at any time by logging into your account profile, clicking on &ldquo;view/upgrade subscription&rdquo;, selecting &quot;cancel plan&rdquo; and following the prompts. While your subscription will be cancelled, you will continue to have access to your membership benefits until the date on which your next subscription fee would have been due. At the end of your subscription period, you will be downgraded to the Sign Up membership tier.<br />
<br />
&zwj;<strong>SUSPENSION AND TERMINATION OF MEMBERSHIP<br />
&zwj;<em>Termination of membership by member<br />
&zwj;</em></strong>You may terminate your membership at any time by logging into your account settings, clicking on &ldquo;Delete account&rdquo; and following the prompts.<br />
<br />
If you terminate your membership because we notify you of a change to these Terms and Conditions, our&nbsp;<a className='text-blue-600 underline' href="https://mumsandco.com.au/website-terms-of-use">Website Terms of Use</a>, or the fees or benefits associated with your membership and you do not agree to the change, we will refund a pro rata amount of the subscription fees paid by you. The amount refunded will be proportionate to the length of time remaining until your subscription period ends.<br />
<br />
Subject to any rights you may have under applicable laws (including the Australian Consumer Law), if you terminate your membership for any other reason we may retain any subscription fees already paid prior to termination.<br />
<br />
&zwj;<strong><em>Suspension or termination of membership by us<br />
&zwj;</em></strong>We may, acting reasonably, terminate or suspend your membership and retain any subscription fees paid by you at any time if you breach these Terms and Conditions.<br />
<br />
We will give you written notice of our intention to terminate your membership and give you at least 14 days to respond to this notice by rectifying the breach (where possible) and providing any reason why we should not terminate or suspend your membership. We will review any reasons given and advise you of our decision.<br />
<br />
However, we may immediately suspend or terminate your membership without notice to you if you have breached these Terms and Conditions by:<br />
&zwj;<br />
a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selling, bartering, attempting to transfer or otherwise dealing with any member benefits other than in accordance with these Terms and Conditions;<br />
b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;supplying materially incorrect or incomplete information; or<br />
c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;engaging in unlawful or inappropriate conduct while using the Mums &amp; Co website, including any forums, use of tokens or in accessing any member benefits.<br />
<br />
&zwj;<strong><em>Termination on death of member<br />
&zwj;</em></strong>Member benefits are personal to the member and will be forfeited automatically on the death of the member. All tokens purchased but not yet redeemed prior to the member&rsquo;s death will be cancelled with effect from the date of death. &nbsp;<br />
<br />
&zwj;<strong><em>Effect of termination of membership on member benefits and tokens<br />
&zwj;</em></strong>Your access to Mums &amp; Co and our website will be revoked and all member benefits associated with Mums &amp; Co will cease once your membership is terminated. Any tokens remaining in your account will immediately expire and not be refunded.<strong>&nbsp;</strong>Accordingly, we recommend members carefully plan all cancellations to avoid unintentional loss of accumulated tokens.<br />
<br />
&zwj;<strong>TERMINATION OF OR CHANGES TO MUMS &amp; CO WEBSITE<br />
&zwj;</strong>We do not guarantee that Mums &amp; Co will continue to be available to members, or that any features of Mums &amp; Co or the Mums &amp; Co website (including member benefits) will continue indefinitely. We may terminate all or part of Mums &amp; Co, including the Mums &amp; Co website, member benefits and these Terms and Conditions at any time by providing you with reasonable notice. In the event we terminate Mums &amp; Co, we will refund a pro rata amount of the subscription fees paid by you. The amount refunded will be proportionate to the length of time remaining until your subscription period ends. Any refund may be suspended or not paid where Mums &amp; Co reasonably considers that there has been a breach of these Terms and Conditions or the redemption is in conflict with any law, regulation or Mums &amp; Co policy.<br />
<br />
&zwj;<strong>LIABILITY<br />
&zwj;<em>Limitation of liability<br />
&zwj;</em>TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANDSUBJECT TO THE SECTION ENTITLED &ldquo;AUSTRALIAN CONSUMER LAW&rdquo; BELOW, THE IAG GROUPAND THEIR OFFICERS, EMPLOYEES, DIRECTORS AND AGENTS:<br />
<p className='ml-3'>- LIMIT THEIR AGGREGATE LIABILITY (OTHER THAN FOR NEGLIGENCE OR WILFUL MISCONDUCT) FOR ANY LOSS, DAMAGE, CLAIM OR EXPENSE THAT ARISES DIRECTLY OR INDIRECTLY OUT OF YOUR MEMBERSHIP, THESE TERMS AND CONDITIONS OR MEMBER BENEFITS TO AN AMOUNT EQUAL TO THE MEMBERSHIP FEES PAID BY YOU; AND
</p><p className='ml-3'>- EXCLUDE ALL LIABILITY (INCLUDING FOR NEGLIGENCE) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL LOSS, ECONOMIC LOSS, LOSS OF DATA, LOSS OF USE, LOSS OF PROFITS, LOSS OFGOODWILL OR REPUTATION OR LOSS OF OPPORTUNITY THAT ARISES DIRECTLY OR INDIRECTLY OUT OF YOUR MEMBERSHIP, THESE TERMS AND CONDITIONS OR MEMBER BENEFITS (AND FOR THE AVOIDANCE OF DOUBT THIS APPLIES EVEN IF WE HAVE BEEN INFORMED THAT THE LIABILITY, LOSS, DAMAGE OR EXPENSE WILL OR MAY RESULT).TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANDSUBJECT TO THE SECTION ENTITLED “AUSTRALIAN CONSUMER LAW” BELOW, THE IAG GROUP AND THEIR OFFICERS, EMPLOYEES, DIRECTORS AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH YOUR MEMBERSHIP AND MEMBER BENEFITS.&zwj;<br />
</p>&zwj;<em>Australian Consumer Law<br />
&zwj;</em></strong>The goods and services provided to you under these Terms and Conditions are subject to the Australian Consumer Law. You may have certain rights under the Australian Consumer Law. Nothing in these Terms and Conditions operates to limit or purports to limit any rights or remedies you have under the Australian Consumer Law which cannot be excluded, restricted or modified by agreement (including any State or Territory consumer protection legislation).To learn more about your rights under the Australian Consumer Law, including consumer guarantees, please visit the ACCC&rsquo;s information page at:&nbsp;<a className='text-blue-600 underline' href="https://www.accc.gov.au/consumers/buying-products-and-services/consumer-rights-and-guarantees">https://www.accc.gov.au/consumers/buying-products-and-services/consumer-rights-and-guarantees</a>, which may be updated from time to time.<br />
&zwj;<br />
&zwj;<strong>CONTACT BETWEEN US AND MEMBERS<br />
&zwj;</strong>We can notify you by sending you a communication to any address (including email) you provide to us during the course of your membership. You are solely responsible for updating your addresses for communication.<br />
<br />
Any electronic communication sent will be taken to be received on the following working day.<br />
<br />
&zwj;<strong>MISCELLANEOUS<br />
&zwj;</strong>These Terms and Conditions are governed by the laws of New South Wales, Australia.<br />
<br />
If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of law, any invalidity or unenforceability will not affect the remainder of these Terms and Conditions, which will continue in full force and effect.</p>
</div>
      </div>
  );
};

export default Terms;
