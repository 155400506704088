import gql from 'graphql-tag';

const F_USER_DETAILS = (name, model, additionalFields = []) => {
  let gqlTag = `
        fragment ${name} on ${model}{
            user{
                id
                name
                avatar
                ${[...additionalFields]}
                 businesses(order_by: {order: asc}){
                    id
                    name
                }
            }
        }
   `;

  return gql`
    ${gqlTag}
  `;
};

export const S_EXPERTS = gql`
  subscription experts($where: expert_listings_bool_exp) {
    expert_listings(where: $where, order_by: { updated_at: desc }) {
      id
      slug
      company
      cost
      image_cover
      logo
      name
      position
      video_url
      is_featured
      subscription_type
      events(
        order_by: [{ date_to: desc }, { date_from: desc }]
        where: { event_type: { type: { _eq: "1:1 Expert Sessions" } }, date_from: { _gte: "now()" } }
      ) {
        id
        date
        name
        date_to
        date_from
        time_to
        time_from
        number_of_spots
        cost
        location_notes
        location
        event_type_id
        image
        online
        video_id
        attendees(where: { status: { _eq: 1 } }) {
          id
          event_id
          user_id
        }
        attendees_count: attendees(where: { status: { _eq: 1 } }) {
          id
          user_id
        }
      }
      expert_listing_tags(order_by: { created_at: desc }) {
        id
        tag
      }
      expert_listing_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
          count
        }
      }
      expert_listing_business_goals_tags {
        business_goals_tag_id
        categories_id
        expert_listing_id
        pillars_id
        business_goals_tag {
          id
          name
        }
        pillar {
          color
          id
          name
        }
      }
    }
  }
`;

export const S_EXPERT = gql`
  subscription expert($where: expert_listings_bool_exp!, $fromNow: date) {
    expert_listings(where: $where) {
      id
      company
      cost
      image_cover
      logo
      name
      position
      details
      video_url
      is_featured
      subscription_type
      events(
        order_by: { date: asc }
        limit: 3
        where: { event_type: { type: { _eq: "1:1 Expert Sessions" } }, date_from: { _gte: $fromNow } }
      ) {
        id
        date
        name
        date_to
        date_from
        time_to
        time_from
        number_of_spots
        cost
        subscription_type
        event_type {
          type
        }
        attendees(order_by: { created_at: desc }) {
          id
          user_id
          status
        }
      }
      expert_listing_tags {
        id
        tag
      }
      expert_listing_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
          count
        }
      }
      expert_listing_reviews {
        id
      }
      expert_listing_business_goals_tags {
        business_goals_tag_id
        categories_id
        expert_listing_id
        pillars_id
        business_goals_tag {
          id
          name
        }
        pillar {
          color
          id
          name
        }
      }
    }
  }
`;

export const M_EXPERT_LISTING_REVIEW = gql`
  mutation expert_listing_review($objects: [expert_listing_reviews_insert_input!]!) {
    insert_expert_listing_reviews(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const S_EXPERT_LISTING_REVIEWS = gql`
  ${F_USER_DETAILS('reviewer_user_details', 'expert_listing_reviews')}
  subscription expert_listing_reviews_details($expertListingId: uuid!) {
    expert_listing_reviews(where: { expert_listing_id: { _eq: $expertListingId } }, order_by: { created_at: desc }) {
      id
      rating
      comment
      user_id
      created_at
      ...reviewer_user_details
    }
  }
`;

export const S_EXPERT_LISTING_CATEGORIES = gql`
  subscription expert_listing_categories {
    expert_listing_categories(order_by: { created_at: desc }) {
      id
      category
    }
  }
`;

export const S_EXPERT_LISTING_TAGS = gql`
  subscription expert_listing_tags {
    expert_listing_tags(order_by: { tag: asc }) {
      id
      tag
    }
  }
`;

export const M_EXPERT_LISTING_SESSION = gql`
  mutation insert_expert_listing_session($objects: [expert_listing_session_insert_input!]!) {
    insert_expert_listing_session(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
