import bigCloseIcon from '../../assets/icons/big-close-icon.png';
import React, { useContext } from 'react';
import { Modal } from 'antd';
import { PageContext } from '../../helpers/context';

const ModalErrorDiscount = () => {
  const { errorDiscountVisible, handleSetErrorDiscountVisible } = useContext(PageContext);
  
  return (
    <Modal visible={errorDiscountVisible}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={'flex flex-col'}>
        <img src={bigCloseIcon} alt={''} className={'self-center w-24 mb-6'}/>
        <p className={'self-center text-center text-3xl text-black'}>Error!</p>
        <p className={'self-center text-center text-lg mb-5'}>
          Promo code is incorrect or invalid. Please check the promo code and apply again
        </p>
        <button
          onClick={handleSetErrorDiscountVisible}
          className={'self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none '}>
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default ModalErrorDiscount;