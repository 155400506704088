import gql from 'graphql-tag';

export const Q_DASHBOARD_DIGITAL_LIBRARY = gql`
  query digitalLibraryCount {
    digital_library_aggregate(where: { resource_end_date: { _gte: "now()" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const Q_DASHBOARD_EVENT = gql`
  query eventCount($where: events_bool_exp) {
    events(where: $where) {
      date_to
      time_to
      event_type {
        display
        type
      }
    }
  }
`;

export const Q_DASHBOARD_PERKS = gql`
  query perksCount {
    perks(where: { active: { _eq: true }, start_date: { _is_null: false }, end_date: { _is_null: false } }) {
      id
      start_date
      end_date
    }
  }
`;

export const S_DASHBOARD_BUSINESS_BOOST = gql`
  subscription businessBoosts {
    business_boost(order_by: { month: asc }) {
      id
      month
      icon
      reward
      sub_title
      url
    }
  }
`;

export const S_DASHBOARD_WHATS_NEW = gql`
  subscription businessBoosts {
    whats_new(order_by: { created_at: desc }) {
      id
      icon
      title
      sub_title
      url
    }
  }
`;

export const Q_TOP_HIGHLIGHTS = gql`
  query topHightlights {
    dashboard_highlights(where: { active: { _eq: true } }, order_by: { order: asc }) {
      id
      image
      title
      cta
      description
      show_modal
    }
  }
`;

export const Q_TODAY_HIGHLIGHTS = gql`
  query topHightlights {
    dashboard_video(where: { active: { _eq: true } }, order_by: { created_at: desc }, limit: 1) {
      id
      title
      cta
      description
      video_id
    }
  }
`;

export const Q_WELCOME_VIDEO = gql`
  query welcomeVideo {
    dashboard_video(where: { title: { _eq: "Welcome to Mums & Co" } }) {
      id
      title
      cta
      description
      video_id
    }
  }
`;

export const Q_WELCOME_VIDEO_ACTIVE = gql`
  query welcomeVideo {
    dashboard_video(where: { active: { _eq: true} }) {
      id
      title
      cta
      description
      video_id
    }
  }
`;

export const Q_REGISTER_VIDEO = gql`
  query registerVideo {
    dashboard_video(where: { title: { _eq: "The First Register Event" } }) {
      id
      title
      cta
      description
      video_id
    }
  }
`;
export const Q_UPDATE_VIDEO = gql`
  query upgradeVideo {
    dashboard_video(where: { title: { _eq: "Upgrade Event" } }) {
      id
      title
      cta
      description
      video_id
    }
  }
`;

export const Q_GET_VIEWS_COUNT = gql`
  query member_directory_tracker_count($where: member_directory_tracker_bool_exp) {
    member_directory_tracker_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const Q_GET_ADVICE_COUNT = gql`
  query get_advice_count {
    expert_listings_aggregate(where: { is_active: { _eq: true } }) {
      aggregate {
        count
      }
    }

    expert_listing_categories_aggregate {
      aggregate {
        count
      }
    }
  }
`;
