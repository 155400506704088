import { Modal } from 'antd';
import React, { useContext } from 'react';
import bigCheckIcon from '../../assets/icons/big-check-icon.png';
import { AppContext, PageContext } from '../../helpers/context';

const ModalSuccess = (props) => {
  const { showSuccessModal, setShowSuccessModal, setQueries, setBookNow, setRequest } = useContext(props.appContext ? AppContext : PageContext);
  
  const handleClose = () => {
    if (props.redirect) {
      props.redirect();
    } else {
      setShowSuccessModal(!showSuccessModal);
      setBookNow(false);
      setRequest(false);
    }
    if (setQueries) {
      setQueries({modal: undefined });
    }
  };

  const buttonText = () => {
    if (props.buttonText) {
      return props.buttonText;
    }
    return props.redirect ? 'Back to previous page' : 'Close';
  };
  
  return <Modal visible={showSuccessModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    closable={false}
  >
    <div className={'flex flex-col'}>
      <img src={bigCheckIcon} alt={''} className={'self-center w-24 mb-6'}/>
      <p className={'self-center text-center text-3xl text-black'}>{props.title || ''}</p>
      <p className={'self-center text-center text-lg mb-5'}>
        {props.subtitle}
      </p>
      <button
        onClick={handleClose}
        className={'self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none '}>
        {buttonText()}
      </button>
    </div>
  </Modal>;
};

export default ModalSuccess;
