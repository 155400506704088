import PropTypes from 'prop-types';
import React from 'react';

const HelpCard = (props) => {
  const { icon, label, description, card, onClick } = props;
  
  return <div onClick={onClick} className={`flex bg-white min-h-28 p-4 ${card ? card.style : null}`}>
    <div className="w-7">
      <img alt={''} src={icon} className={`h-7 w-auto max-w-auto`}/>
    </div>
    <div className="w-full pl-2 font-sans">
      <p className={'text-lg text-black'}>{label}</p>
      <p className={'text-sm'} style={{ color: 'rgba(0,0,0,0.6)' }}>{description}</p>
    </div>
  </div>;
};

HelpCard.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  card: PropTypes.object,
  image: PropTypes.object
};

export default HelpCard;
